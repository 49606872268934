.anketaAnswerForm {
  position: relative;
  display: grid;
  width: 100%;
  background-color: #fff;
  padding: 0 30rem 30rem;
  row-gap: 30rem;

  .answerSavingError {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    background-color: #ef193a;

    .wrapper {
      display: flex;
      align-items: center;
      width: 1440rem;
      margin: 0 auto;
      padding: 30rem;

      span {
        font-size: 14rem;
        line-height: 22rem;
        color: #fff;
      }

      img {
        width: 24rem;
        height: 24rem;
        margin-right: 30rem;
      }
    }
  }

  form {
    position: relative;
  }

  .answerSubTitle {
    font-size: 14rem;
    line-height: 16rem;
    color: rgba(0, 0, 0, 0.5);
  }

  .inputError.success {
    position: absolute;
    bottom: 60rem;
    left: 0;
    margin-top: 0;
    margin-bottom: 0;
  }

  .submitButton {
    margin-top: 10rem;
    justify-self: flex-end;
  }
}
