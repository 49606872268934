.adminClientTribesReports {
  display: grid;
  grid-template-columns: auto 300rem;

  .title {
    grid-column: 1;
    margin-bottom: 25rem;
    padding-left: 30rem;
  }

  .downloadAllCSVButton {
    grid-column: span 1;
    margin-right: 60rem;
  }

  .tribesTable {
    grid-column: span 2;
    border-top: 1px solid rgba(0, 0, 0, 0.1);

    .tribeRow {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 74rem;
      padding-left: 30rem;
      padding-right: 30rem;
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);

      .tribeName {
        font-size: 16rem;
        font-weight: 600;
        line-height: 19rem;
        color: rgba(37, 36, 36, 1);
      }
    }
  }
}
