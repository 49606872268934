@import '../../components/Button/Button.scss';

.profileAnketas {
  display: grid;
  grid-template-columns: 460rem 460rem 230rem auto;
  column-gap: 60rem;
  padding: 85rem 30rem;

  .title {
    grid-column: span 4;
    margin-bottom: 50rem;
  }

  .newAnketa {
    grid-column: span 4;
  }
}
