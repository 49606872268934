@import '../P_5.10.1_Admin_MemberReportHTML/AdminMemberReportHTML.scss';

.adminClientReportPDF {
  .header {
    left: 0;
  }
}

@media print {
  .adminClientReportPDF {
    .competentionsCirclesByRole {
      .circleResult:nth-of-type(2) {
        page-break-inside: avoid;
        margin-top: -60rem;
      }
      .circleResult {
        page-break-inside: avoid;
        padding-top: 70rem;
      }
      // .circleResult:nth-of-type(3) {
      //   padding-top: 120rem;
      // }
      // .circleResult:nth-of-type(4) {
      //   padding-top: 630rem;
      //   margin-bottom: 15rem;
      // }

      // .circleResult:nth-of-type(4) {
      //   page-break-before: always;
      // }
    }

    .competentionsLevels {
      page-break-inside: avoid;
    }

    .tribeResults {
      padding-top: 180rem;

      .tribeStats {
        page-break-inside: avoid;
        // * new way
        // page-break-after: always;

        // &:nth-child(1) {
        //   padding-top: 0;
        // }
        padding-top: 180rem;
        // * new way end
        // padding-top: 0;
        padding-bottom: 0;
      }
    }
  }
}
