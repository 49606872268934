.expertAnketaAnswerText {
  display: grid;
  width: 100%;
  background-color: #fff;
  padding: 0 30rem 30rem;
  row-gap: 30rem;

  .answerSubTitle {
    font-size: 14rem;
    line-height: 16rem;
    color: rgba(0, 0, 0, 0.5);
  }

  .submitButton {
    margin-top: 10rem;
    justify-self: flex-end;
  }
}
