.anketaExpertReview {
    display: grid;
    grid-template-columns: auto 410rem;

    .fields {
        grid-column: 1;
        display: grid;
        grid-template-columns: auto 160rem;
        width: 100%;
        padding: 35rem 30rem 55rem;
        background-color: #fff;

        .title {
            grid-column: 1;
            margin-bottom: 15rem;
        }

        .review {
            grid-column: span 2;
            max-width: 540rem;
            
            margin-top: 0;
            margin-bottom: 30rem;
    
            font-size: 24rem;
            font-weight: 400;
            line-height: 30rem;

            ol {
                padding-left: 55rem;
                margin-bottom: 30rem;
                width: 900rem;

                li {
                    position: relative;
                    padding-bottom: 17rem;
                    margin-bottom: 11rem;

                    font-size: 18rem;
                    line-height: 30rem;

                    &:after {
                        content: '';
                        position: absolute;
                        width: 900rem;
                        height: 1px;
                        background-color: rgba(0, 0, 0, 0.1);
                        left: -60rem;
                        bottom: 0;
                    }

                    >div {
                        grid-column: 2;
                        display: grid;
                        grid-template-columns: auto 30px;
                        width: 100%;
                        padding: 10px 10px 10px 10px;
                        background-color: #fff;
                    }
                }
            }

            .question {
                margin-top: 10px;
            }

            .points {
                grid-column: 2;
                display: grid;
                grid-template-columns: auto auto;
                width: 100px;
            }

            .linkQuestion {
                cursor: pointer;
                text-decoration: none;
                color: var(--link-normal);
                width: auto;
            
                cursor: pointer;
            
                &:hover {
                    color: var(--link-hover);
                }
            }

            .progressionPoint {
                position: relative;
                grid-column: span 1;
                display: flex;
                justify-content: center;
                align-items: center;
                width: 45rem;
                height: 45rem;
                background-color: var(--button-blue-normal);
                border-radius: 50%;
                font-size: 20rem;
                font-weight: 600;
                padding-left: 2px;
                line-height: 14rem;
                color: rgba(255, 255, 255, 1);
                text-decoration: none;
                user-select: none;

                &.rated-0 {
                    background-color:  #E57373;
                }

                &.rated-1 {
                    background-color: #F4A261;
                }

                &.rated-2 {
                    background-color:  #E1B12C;
                }
                
                &.rated-3 {
                    background-color:  #81C784;
                }
                
                &.hoverPossible {
                    cursor: pointer;

                    &:hover {
                        background-color: var(--button-blue-hover);
                        color: #fff;
                    }

                    &.active {
                        &:hover {
                            cursor: default;
                            background-color: var(--button-black-normal);
                        }
                    }
                }
            }
        }

        .reactSelect {
            grid-column: span 2;
            max-width: 540rem;
        }

        .showCaseDescriptionButton {
            align-self: flex-start;
            margin-top: 13rem;
            height: auto;
            grid-column: 2;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-bottom: 30rem;
            border: 0;
            background: none;
            height: 20rem;
            font-size: 14rem;
            line-height: 16rem;
            text-transform: uppercase;
            color: var(--button-blue-normal);
            cursor: pointer;

            &:hover {
                color: var(--button-blue-hover);
            }
        }

        .fieldTitle {
            grid-column: span 2;
            margin-top: 45rem;
            margin-bottom: 30rem;

            font-size: 24rem;
            line-height: 38rem;
            color: rgba(0, 0, 0, 1);
        }

        .quillEditor {
            grid-column: span 2;
            display: grid;

            .ql-editor {
                padding: 15rem;

                >* {
                    &:last-child {
                        margin-bottom: 0;

                        li:last-child {
                            margin-bottom: 0;
                        }
                    }
                }

                p {
                    font-size: 18rem;
                    line-height: 30rem;
                    color: #000000;
                    margin: 15rem 0;

                    &:first-of-type {
                        margin-top: 0;
                    }
                }

                ul {
                    padding-left: 0;

                    li {
                        position: relative;
                        margin: 15rem 0;
                        padding-left: 30rem;
                        list-style: none;
                        font-size: 18rem;
                        line-height: 30rem;
                        color: rgba(0, 0, 0, 1);

                        &:before {
                            content: '';
                            position: absolute;
                            left: 30rem;
                            top: 11rem;
                            width: 8rem;
                            height: 8rem;
                            background-color: var(--button-blue-normal);
                        }
                    }
                }

                ol {
                    padding-left: 0;

                    li {
                        position: relative;
                        margin: 15rem 0;
                        padding-left: 30rem;
                        list-style: none;
                        font-size: 18rem;
                        line-height: 30rem;
                        color: rgba(0, 0, 0, 1);

                        &:before {
                            margin-left: -34rem;
                            margin-right: 12rem;
                        }
                    }
                }
            }
        }

        .hint {
            grid-column: span 2;
            margin-top: 10rem;

            font-size: 14rem;
            line-height: 18rem;
            color: rgba(0, 0, 0, 0.5);
        }

        .inputError {
            grid-column: span 2;

            &.withMarginTop {
                margin-top: 8rem;
            }
        }
    }

    .submit {
        grid-column: 2;
        display: grid;
        grid-template-rows: max-content max-content max-content max-content auto;
        padding: 50rem 30rem;
        border-left: 1px solid rgba(0, 0, 0, 0.1);

        .inputError {
            &.success {
                color: var(--link-normal);
            }
        }

        .hint {
            margin-top: 10rem;
            font-size: 14rem;
            line-height: 18rem;
            color: rgba(0, 0, 0, 0.5);
        }
    }
}

.anketaExpertComment.is_admin {
    grid-column: 1;
    grid-template-columns: 1fr;
    padding-bottom: 120rem;
    border-right: 1px solid rgba(0, 0, 0, 0.1);

    .fields {
        padding-bottom: 0;

        .fieldTitle {
            margin-top: 0;
            margin-bottom: 30rem;

            font-size: 24rem;
            font-weight: 400;
            line-height: 30rem;
        }

        .hint {
            margin-bottom: 60rem;
        }

        .cheatFieldLabel {
            grid-column: span 2;

            font-size: 14rem;
            line-height: 16rem;
            color: rgba(0, 0, 0, 0.5);
        }

        .cheatFieldValue {
            grid-column: span 2;
            margin-bottom: 50rem;

            font-size: 24rem;
            line-height: 38rem;
            color: rgba(0, 0, 0, 1);
        }
    }

    .submit {
        grid-column: 1;
        display: grid;
        grid-template-columns: 330rem auto 330rem;
        padding: 25rem 30rem 0;

        .button {
            &:nth-of-type(1) {
                grid-column: 1;
                grid-row: 1;
            }

            &:nth-of-type(2) {
                grid-column: 3;
                grid-row: 1;
            }
        }

        .onReturnError {
            grid-column: 1;
            grid-row: 2;
            text-align: left;
        }

        .onSubmitError {
            grid-column: 3;
            grid-row: 2;
            text-align: left;
        }
    }
}