.memberInterview {
  display: grid;
  padding-top: 85rem;
  padding-bottom: 70rem;

  .title {
    padding-left: 30rem;
    margin-bottom: 70rem;
  }

  .subTitle {
    padding-left: 32rem;
    margin-bottom: 20rem;

    font-size: 14rem;
    font-weight: 600;
    line-height: 30rem;
    text-transform: uppercase;
    color: rgba(0, 0, 0, 1);

    &.callTypeSubTitle {
      margin-bottom: 30rem;
    }
  }

  .callInfo {
    margin-top: -10rem;
    padding-bottom: 60rem;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    margin-bottom: 50em;

    .callLink {
      position: relative;
      padding-left: 65rem;
      font-size: 18rem;
      font-weight: 400;
      line-height: 21rem;

      &.skype:before {
        content: '';
        position: absolute;
        left: 30rem;
        top: -2rem;
        width: 24rem;
        height: 24rem;
        background: url(../../assets/images/icon_skype.svg) center no-repeat;
        background-size: contain;
      }
      &.zoom:before {
        content: '';
        position: absolute;
        left: 30rem;
        top: -2rem;
        width: 24rem;
        height: 24rem;
        background: url(../../assets/images/icon_zoom.svg) center no-repeat;
        background-size: contain;
      }

      a {
        text-decoration: none;
        color: var(--link-normal);
        cursor: pointer;

        &:hover {
          color: var(--link-hover);
        }
      }
    }
  }

  > .button {
    margin-top: 30rem;
    justify-self: center;
  }
}
