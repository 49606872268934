.anketaQuestionsList {
  display: grid;
  padding: 55rem 300rem 115rem 240rem;

  .title {
    padding-left: 30rem;
    margin-bottom: 38rem;
  }

  ol {
    padding-left: 55rem;
    margin-bottom: 30rem;

    li {
      position: relative;
      padding-bottom: 17rem;
      margin-bottom: 11rem;

      font-size: 18rem;
      line-height: 30rem;

      &:after {
        content: '';
        position: absolute;
        width: 900rem;
        height: 1px;
        background-color: rgba(0, 0, 0, 0.1);
        left: -60rem;
        bottom: 0;
      }

      > div {
        text-decoration: none;
        color: var(--link-normal);

        cursor: pointer;

        &:hover {
          color: var(--link-hover);
        }
      }
    }
  }
}

.anketaQuestionsList.is_admin {
  height: 100%;
  align-content: flex-start;
  align-self: flex-start;

  .title {
    padding-left: 0;

    font-size: 14rem;
    font-weight: 400;
    line-height: 16rem;
    text-transform: uppercase;
  }

  ul {
    display: grid;
    row-gap: 50rem;
    padding: 0;
    margin: 0;
  }

  li,
  div.goUpButton {
    position: relative;
    display: grid;
    grid-template-columns: 30rem auto;
    column-gap: 18rem;
    align-items: flex-start;
    list-style: none;

    font-size: 14rem;
    line-height: 24rem;

    cursor: pointer;

    &:hover {
      .questionTitle {
        color: var(--link-hover);
      }
    }

    &:after {
      content: '';
      position: absolute;
      left: 14rem;
      bottom: -40rem;
      width: 1rem;
      height: 30rem;
      background-color: #f2f2f2;
    }

    &:last-of-type {
      &:after {
        display: none;
      }
    }

    .questionNubmer {
      grid-column: 1;
      display: flex;
      width: 30rem;
      height: 30rem;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      background-color: var(--button-blue-normal);

      color: rgba(255, 255, 255, 1);

      .dot {
        width: 10rem;
        height: 10rem;
        background-color: #fff;
        border-radius: 50%;
      }

      .down {
        width: 30rem;
        height: 15rem;
        background: url(../../assets/images/icon_arrowDown.svg) center no-repeat;
        background-size: contain;
      }
    }

    .questionTitle {
      grid-column: 2;
      color: rgba(0, 0, 0, 1);
      padding-top: 3rem;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }

    &.goUpButton {
      position: sticky;
      margin-top: 100vh;
      top: 95vh;

      .questionNubmer {
        .up {
          width: 30rem;
          height: 15rem;
          background: url(../../assets/images/icon_arrowDown.svg) center no-repeat;
          background-size: contain;
          transform: rotate(180deg);
        }
      }
    }
  }
}
