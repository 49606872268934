@import '../Button/Button.scss';

.supportModal {
  display: grid;
  width: 730rem !important;
  padding: 55rem 110rem 30rem 60rem !important;

  .title {
    margin-bottom: 35rem;
  }

  .subTitle {
    margin-bottom: 50rem;

    font-size: 18rem;
    line-height: 30rem;
    color: rgba(0, 0, 0, 1);
  }

  input[type='text'] {
    width: 100%;
    margin-bottom: 0;
  }

  .inputError {
    &.titleFieldError {
      margin-bottom: 35rem;
    }

    &.submit {
      justify-self: center;
    }
  }

  .fileFieldContainer {
    grid-column: span 1;
    display: flex;
    align-items: center;
    margin-bottom: 30rem;

    .icon {
      width: 10rem;
      height: 10rem;
      margin-right: 10rem;
      cursor: pointer;
    }

    .fileFieldInfo {
      max-width: 295rem;
      overflow-x: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;

      font-size: 14rem;
      line-height: 18rem;
      color: rgba($color: #000000, $alpha: 0.5);
    }

    input[type='file'] {
      display: none;
    }

    button {
      margin-left: auto;

      label,
      .labelReplacement {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
        cursor: pointer;
      }
    }
  }
}
