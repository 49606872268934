.templateQuestionsTable {
  display: grid;
  width: 100%;
  padding: 5rem 0 0;

  table {
    tr {
      position: relative;
      display: grid;
      grid-auto-columns: max-content;
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);

      &.missedCall {
        background-color: rgba(222, 161, 180, 0.1);
      }

      th,
      td {
        grid-column: span 1;
        grid-row: 1;
        display: flex;
        align-items: center;
        height: 75rem;
        text-align: left;

        // * Название вопроса
        &:nth-of-type(1) {
          width: 335rem;
        }

        // * Компетенции
        &:nth-of-type(2) {
          width: 400rem;
        }

        // * Индикаторы
        &:nth-of-type(3) {
          width: 480rem;
        }

        // * Кнопки
        &:nth-of-type(4) {
          width: 170rem;
        }
      }

      th {
        position: relative;
        font-size: 12rem;
        line-height: 14rem;
        font-weight: 600;
        text-transform: uppercase;
        user-select: none;

        .sortUpIcon,
        .sortDownIcon {
          width: 10rem;
          height: 4rem;
          margin-left: 10rem;
          margin-bottom: 2rem;

          &.sortDownIcon {
            transform: rotate(180deg);
          }
        }

        .searchIcon {
          width: 20rem;
          height: 20rem;
          padding: 4rem;
          margin-left: 8rem;
          cursor: pointer;
        }

        .resetFilterIcon {
          width: 20rem;
          height: 20rem;
          margin-left: 4rem;
          cursor: pointer;
        }

        .searchPopup {
          position: absolute;
          z-index: 20;
          top: 74rem;
          display: none;
          height: 130rem;
          align-items: flex-end;
          padding: 0 30rem 60rem;
          border: 1px solid #dedede;
          box-shadow: 1px 3px 7px rgba(0, 0, 0, 0.2);
          background-color: rgba(255, 255, 255, 1);

          &.visible {
            display: flex;
          }

          &.titleSearch,
          &.competentionsSearch {
            width: 470rem;
            left: -30rem;
          }

          .close {
            position: absolute;
            right: 10rem;
            top: 10rem;
            width: 10rem;
            height: 10rem;
            padding: 0;
            border: 0;
            background: url(../../../assets/images/icon_closeGray.svg) center no-repeat;
            background-color: rgba(0, 0, 0, 0);
            background-size: contain;
            cursor: pointer;
          }

          > input[type='text'] {
            width: 100%;
            height: 39rem;
            padding-bottom: 8rem;
            border: none;
            border-bottom: 1rem solid var(--input-border-normal);
            background: rgba(255, 255, 255, 0);

            font-size: 24rem !important;
            font-weight: 400 !important;
            line-height: 28rem !important;
            color: rgba(0, 0, 0, 1);
            text-transform: none !important;

            &::placeholder {
              padding-top: 12rem;

              font-size: 14rem !important;
              line-height: 16rem !important;
              color: rgba(0, 0, 0, 0.5);
            }

            &:-webkit-autofill,
            &:-webkit-autofill:hover,
            &:-webkit-autofill:focus,
            &:-webkit-autofill:active {
              -webkit-box-shadow: 0 0 0 30rem white inset !important;
            }
          }
        }

        &:nth-of-type(4) {
          font-size: 0;
        }
      }

      td {
        position: relative;
        min-height: 75rem;
        height: auto;
        overflow-x: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;

        font-size: 14rem;
        line-height: 16rem;
        color: rgba(0, 0, 0, 1);

        // * Название вопроса
        &:nth-of-type(1) {
          padding-right: 20rem;
          white-space: unset;
        }

        // * Компетенции
        &:nth-of-type(2) {
          padding-right: 20rem;
        }

        // * Компетенции
        // * Индикаторы
        &:nth-of-type(2),
        &:nth-of-type(3) {
          display: grid;
          row-gap: 11rem;
          padding-top: 30rem;
          padding-bottom: 30rem;

          color: rgba(0, 0, 0, 0.5);

          > * {
            width: 100%;
            overflow-x: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
        }

        // * Кнопки
        &:nth-of-type(4) {
          display: grid;
          grid-template-columns: max-content max-content max-content;
          padding-left: 50rem;

          > img {
            cursor: pointer;

            &:nth-child(1) {
              width: 16rem;
              height: 16rem;
            }

            &:nth-child(2) {
              width: 20rem;
              height: 13rem;
              margin-left: 33rem;
              margin-right: 28rem;
            }

            &:nth-child(3) {
              width: 24rem;
              height: 24rem;
            }
          }
        }
      }
    }
  }
}
