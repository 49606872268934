.notFound404 {
  .formContainer {
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;

    .text {
      margin-top: 40rem;
      font-size: 18rem;
      line-height: 30rem;
    }

    a {
      color: var(--link-normal);
      text-decoration: none;

      &:hover {
        color: var(--link-hover);
      }
    }
  }
}
