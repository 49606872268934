.inputSelect {
  position: relative;
  width: 100%;
  padding-top: 20rem;
  z-index: 50;

  .placeholder {
    position: absolute;
    z-index: 5;
    top: 31rem;
    width: 100%;

    font-size: 14rem;
    line-height: 16rem;
    color: rgba(0, 0, 0, 0.5);
  }

  .inputError {
    margin-bottom: 0 !important;
  }

  &.small {
    .placeholder {
      top: 24rem;
    }
  }

  svg {
    width: 20rem;
    height: 20rem;
  }
}
