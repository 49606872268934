.tribeResults {
  padding: 100rem 0rem 0;

  .tribeStats {
    padding-top: 75rem;

    &.withoutHeader {
      .statsSection {
        padding-top: 45rem;
      }
    }

    .tribeHeader {
      display: flex;
      align-items: center;
      width: 100%;
      height: 70rem;
      padding-left: 40rem;
      padding-right: 40rem;
      margin-bottom: 35rem;
      background-color: #1364b4;
      font-size: 24rem !important;
      font-weight: 400 !important;
      line-height: 48rem;
      text-align: left;
      color: #fff;
    }

    .statsSection {
      display: grid;
      grid-template-columns: 205rem 205rem 475rem 475rem;
      padding-left: 40rem;
      padding-right: 40rem;
      align-items: flex-end;
      font-size: 0;
      padding-bottom: 55rem;

      &.noHeader {
        margin-top: 90rem;
      }

      &.withID {
        grid-template-columns: 90rem 60rem 365rem 365rem;

        .h2Container {
          grid-column: span 4;
        }
      }

      .statsHeader {
        grid-column: span 4;
        display: grid;
        grid-template-columns: auto max-content;
        margin-bottom: 20px;

        h2 {
          grid-column: 1;
          color: #000;
          font-size: 24rem;
          line-height: 28rem;
        }

        .statsExplanation {
          display: flex;

          .statExplanation {
            position: relative;
            font-size: 10rem;
            line-height: 24rem;
            margin-left: 80rem;

            .line {
              position: absolute;
              left: -40rem;
              top: 11rem;
              width: 30rem;
              height: 1px;
              border-bottom: 1px dashed #000;
            }
          }
        }

        .points {
          grid-column: 2;
          display: grid;
          grid-template-columns: max-content max-content;
          justify-content: space-between;
          align-content: center;
          align-self: flex-end;
          row-gap: 5rem;
          width: 430rem;
          height: 95rem;
          padding: 0 30rem;
          margin-right: -40rem;

          .pointsHeader {
            grid-column: span 1;

            font-size: 10rem;
            line-height: 12rem;
            color: rgba($color: #ffffff, $alpha: 1);

            &:nth-child(1) {
              justify-self: flex-start;
            }

            &:nth-child(2) {
              justify-self: flex-end;
            }
          }

          .pointsValue {
            grid-column: span 1;
            grid-row: 2;
            font-size: 12rem;
            line-height: 28rem;
            color: rgba($color: #ffffff, $alpha: 1);

            .value {
              font-size: 24rem;
            }

            &:nth-child(4) {
              justify-self: flex-end;

              .value {
                font-weight: 600;
              }
            }
          }
        }

        .tribeResultExplanation {
          grid-column: 1;
          grid-row: 2;
          margin-top: -10rem;

          .explanationContainer {
            display: grid;
            grid-template-columns: max-content max-content max-content;
            align-items: center;
            column-gap: 10rem;

            .color {
              grid-column: span 1;
              width: 10rem;
              height: 10rem;

              &:first-child {
                background-color: rgba($color: #000000, $alpha: 0.1);
              }
            }

            .text {
              grid-column: span 1;

              font-size: 10rem;
              line-height: 24rem;
              color: rgba($color: #191919, $alpha: 1);
            }
          }
        }
      }

      .resultsHeader {
        grid-column: span 4;

        .title {
          margin-left: -5rem;
        }

        .subTitle {
          color: var(--link-normal);
          margin-bottom: 45rem;
        }

        .resultColorsExpanation {
          margin-top: 45rem;
          margin-bottom: 45rem;
          display: grid;
          grid-template-columns: max-content max-content;

          .before,
          .after {
            display: flex;
            align-items: center;
            margin-right: 30rem;

            .square {
              width: 15rem;
              height: 15rem;
              margin-right: 10rem;
            }

            .text {
              font-size: 10rem;
              line-height: 24rem;
              color: rgba(0, 0, 0, 0.5);
            }
          }

          .norm {
            grid-column: span 2;
            display: flex;
            align-items: center;
            margin-top: 30rem;

            .triangle {
              position: relative;
              width: 0;
              height: 0;
              border-style: solid;
              border-width: 0 7.5rem 15rem 7.5rem;
              border-color: transparent transparent gray transparent;
              margin-right: 10rem;

              &:before {
                content: '';
                position: absolute;
                height: 38rem;
                border-left: 1rem dotted rgba(0, 0, 0, 0.3);
                top: -38rem;
                left: -0.5rem;
              }
            }

            .text {
              font-size: 10rem;
              line-height: 24rem;
              color: rgba(0, 0, 0, 0.5);
            }
          }
        }
      }

      .names,
      .roles,
      .numbers {
        grid-column: span 1;

        .title {
          margin-bottom: 30rem;

          font-size: 18rem !important;
          font-weight: 400;
          line-height: 20rem !important;
          color: rgba($color: #000000, $alpha: 0.5);
          text-align: left;

          b {
            color: rgba($color: #000000, $alpha: 1);
          }

          .small {
            font-size: 12rem;
          }
        }

        .namesWrapper,
        .numbersWrapper,
        .rolesWrapper {
          padding: 10px 0;

          &.numbersWrapper {
            position: relative;
            border-left: 1px solid #000;

            .guideLinesWrapper {
              position: absolute;
              padding-right: 45rem;
              width: 99%;
              height: 100%;

              .guideLines {
                position: relative;
                width: 100%;
                height: 100%;

                .guideLine {
                  position: absolute;
                  top: 0;
                  width: 2px;
                  height: 100%;
                  border-left: 1px dashed #c0c0c0;

                  &.blue {
                    border-color: #006290;

                    .guideLineDescription {
                      color: #006290;
                    }

                    .guideLineTriangle {
                      border-bottom-color: #006290;
                    }
                  }

                  &.gray {
                    border-color: #68747a;

                    .guideLineDescription {
                      color: #68747a;
                    }

                    .guideLineTriangle {
                      border-bottom-color: #68747a;
                    }
                  }

                  .guideLineTriangle {
                    position: absolute;
                    bottom: -10.5px;
                    margin-left: -6px;
                    width: 0;
                    height: 0;
                    border-left: 5.5px solid transparent;
                    border-right: 5.5px solid transparent;
                    border-bottom: 9px solid black;
                  }

                  .guideLineDescription {
                    position: absolute;
                    width: 50px;
                    margin-left: -25px;
                    font-weight: 500;
                    bottom: -25px;
                    font-size: 12px;
                    text-align: center;
                  }
                }
              }
            }
          }
        }
      }

      .names,
      .roles {
        .name,
        .id,
        .role {
          font-size: 14rem;
          font-weight: 600;
          line-height: 30rem;
          color: #000000;
          width: 100%;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }

      .numbers {
        .number {
          .lineContainer {
            display: inline-block;
            vertical-align: middle;
            width: 425rem;
            height: 10rem;
            background-color: #f8f5f5;

            &.blue {
              background-color: rgba($color: #1364b4, $alpha: 0.1);
            }

            .line {
              height: 10rem;

              &.blue {
                background-color: #1364b4;
              }
              &.gray {
                background: rgba(0, 0, 0, 0.05);
              }
            }
          }
          .value {
            display: inline-block;
            vertical-align: middle;
            margin-left: 5rem;

            font-size: 14rem;
            font-weight: 600;
            line-height: 30rem;
            color: #000;
          }
        }
      }

      &.twoStreams {
        .names,
        .roles {
          align-self: flex-start;

          .namesWrapper,
          .rolesWrapper {
            display: grid;
            row-gap: 25rem;
            padding-top: 32rem;
          }
          .name,
          .id,
          .role {
            height: 30rem;
          }
        }

        .numbers {
          .numbersWrapper {
            display: grid;
            row-gap: 20rem;

            .number {
              display: grid;
              grid-template-columns: 425rem auto;
              align-items: center;
              align-content: center;
              row-gap: 5rem;

              .lineContainer {
                grid-column: 1;
              }
              .value {
                grid-column: 2;
                line-height: 15rem !important;

                &:nth-child(4) {
                  color: rgba($color: #000000, $alpha: 0.5);
                }
              }
            }
          }
        }
      }
    }
  }
}
