.scheduleTabs {
  display: grid;
  grid-template-columns: 250rem 250rem 250rem 250rem 440rem;
  margin-bottom: 30rem;

  .scheduleTab {
    display: flex;
    align-items: flex-end;
    height: 60rem;
    padding: 0 30rem 8rem;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    border-right: 1px solid rgba(0, 0, 0, 0.1);

    font-size: 14rem;
    font-weight: 600;
    line-height: 16rem;
    text-transform: uppercase;
    color: rgba(0, 0, 0, 1);

    cursor: pointer;

    &:last-of-type {
      border-right: 0;
    }

    &.active,
    &:hover {
      border-top: 6rem solid var(--button-blue-normal);
    }

    &.selectDiffrent {
      position: relative;
      padding-left: 75rem;

      color: var(--button-blue-normal);

      &:before {
        content: '';
        position: absolute;
        left: 32rem;
        bottom: 4rem;
        width: 24rem;
        height: 24rem;
        background: url(../../assets/images/icon_calendar.svg) center no-repeat;
        background-size: contain;
      }
    }
  }

  &.scheduleTabsMember {
    grid-template-columns: 250rem 250rem 940rem;

    .scheduleTab.emptyScheduleTab {
      cursor: default;

      &:hover {
        border-top: 1px solid rgba(0, 0, 0, 0.1);
      }
    }
  }
}
