.respondentsDasboard {
  margin-top: 20rem;

  .row {
    display: grid;
    border-bottom: 1px solid rgba(222, 222, 222, 1);

    &.titleRow {
      > * {
        grid-column: span 1;
        grid-row: 1;
        display: flex;
        align-items: center;
        height: 46rem;
        padding-top: 20rem;
        padding-bottom: 15rem;

        font-size: 12rem !important;
        line-height: 14rem !important;
        color: rgba(0, 0, 0, 0.5);
        text-transform: uppercase;
      }

      > .zoomVideoLink {
        background: unset !important;
      }
    }

    &.disabled {
      background-color: rgba(249, 249, 249, 0.5);

      .time {
        color: rgba(0, 0, 0, 0.3);
      }
    }

    > * {
      grid-column: span 1;
      grid-row: 1;
      display: flex;
      align-items: center;
      height: 73rem;
      font-size: 14rem;
      line-height: 16rem;
    }

    .time {
      width: 110rem;

      padding-left: 30rem;
      padding-right: 35rem;
    }

    .fio {
      width: 210rem;
    }

    .client {
      width: 180rem;

      img {
        width: 24rem;
        height: 24rem;
        margin-left: 5rem;
        border-radius: 50%;
      }
    }

    .id {
      width: 125rem;
    }

    .phone {
      width: 155rem;
      padding-right: 20rem;
    }

    .role {
      width: 135rem;
    }

    .case {
      width: 115rem;
    }

    .callInfo {
      position: relative;
      width: 190rem;
      padding-right: 30rem;
      color: rgba(0, 0, 0, 0.5);

      &.skype {
        padding-left: 40rem;

        &:before {
          content: '';
          position: absolute;
          left: 2rem;
          bottom: 24rem;
          background: url(../../assets/images/icon_skype.svg) center no-repeat;
          background-size: contain;
          width: 24rem;
          height: 24rem;
        }
      }

      &.zoom {
        padding-left: 38rem;

        &:before {
          content: '';
          position: absolute;
          left: 0;
          bottom: 24rem;
          background: url(../../assets/images/icon_zoom.svg) center no-repeat;
          background-size: contain;
          width: 24rem;
          height: 24rem;
        }
      }
    }

    .anketLink {
      width: 85rem;
      color: var(--link-normal);
      span {
        cursor: no-drop;
      }

      a {
        cursor: pointer;
      }
    }

    .anketStatus {
      width: 125rem;
      padding-right: 30rem;
      color: rgba(0, 0, 0, 0.5);
    }
  }

  &.status {
    .row,
    .titleRow {
      display: grid;
      justify-content: flex-start;
      column-gap: 0;

      span {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      .time {
        width: 145rem;

        padding-left: 30rem;
        padding-right: 35rem;
      }

      .inteviewStatus {
        width: 155rem;
      }

      .fio {
        width: 280rem;
      }

      .client {
        width: 180rem;

        img {
          width: 24rem;
          height: 24rem;
          margin-left: 5rem;
          border-radius: 50%;
        }
      }

      .id {
        width: 125rem;
      }

      .phone {
        width: 155rem;
        padding-right: 25rem;
      }

      .role {
        width: 135rem;
      }

      .case {
        width: 115rem;
      }

      .anketLink {
        width: 90rem;
      }

      .zoomVideoLink {
        align-self: center;
        width: 55rem;
        padding-right: 35rem;
        background: url(../../assets/images/icon_zoomVideo.svg) center no-repeat;
        background-size: 16rem 16rem;
        cursor: pointer;

        &.empty {
          background-image: none;
        }
      }
    }
  }

  .withoutInterviewTitleRowDummy {
    display: grid;
    width: 100%;
    height: 70rem;
    justify-content: flex-start;
    align-items: center;
    padding-left: 30rem;
    background-color: #000;

    font-size: 24rem;
    line-height: 28rem;
    font-weight: 600;
    text-transform: uppercase;
    color: #fff;
  }
}
