.respondentsTabs {
  display: grid;
  grid-template-columns: 305rem 275rem 275rem 275rem auto;
  margin-bottom: 25rem;

  .respondentTab {
    display: flex;
    align-items: flex-end;
    height: 60rem;
    padding: 0 30rem 8rem;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    border-right: 1px solid rgba(0, 0, 0, 0.1);

    font-size: 14rem;
    font-weight: 600;
    line-height: 16rem;
    text-transform: uppercase;
    color: rgba(0, 0, 0, 1);
    text-decoration: none;

    cursor: pointer;

    &:last-of-type {
      border-right: 0;
    }

    &.active,
    &:hover {
      border-top: 6rem solid var(--button-blue-normal);
    }
  }
}
