.deleteQuestionModal {
  width: 1030rem !important;
  padding-bottom: 20rem !important;

  .title {
    width: 100%;
    margin-bottom: 60rem;

    font-size: 36rem;
    line-height: 36rem;
    text-align: left;
  }

  .text {
    margin-bottom: 75rem;

    font-size: 24rem;
    font-weight: 600;
    line-height: 38rem;
  }

  button {
    margin: 0 auto 5rem;
  }
}
