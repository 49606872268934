.competentionsLevels {
  padding: 100rem 0rem 0;

  &.double {
    display: grid;
    grid-template-columns: calc(335rem + 30rem) 520rem calc(520rem + 35rem);

    .position {
      .positionNumbers {
        grid-template-columns: 380rem auto;
        column-gap: 35rem;
      }
    }
  }

  &.triple {
    display: grid;
    grid-template-columns: calc(335rem + 30rem) 345rem 345rem calc(345rem + 35rem);

    .position {
      .positionNumbers {
        grid-template-columns: 210rem auto;
        column-gap: 35rem;
      }
    }
  }

  &.quadra {
    display: grid;
    grid-template-columns: calc(335rem + 30rem) 260rem 260rem 260rem 260rem;

    .position {
      .positionNumbers {
        grid-template-columns: 150rem auto;
        column-gap: 20rem;
      }
    }
  }

  .results {
    grid-column: 1;

    .resultsTitle {
      padding-left: calc(145rem + 30rem);
      margin-bottom: 10rem;

      font-size: 12rem;
      line-height: 14rem;
    }

    .result {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      width: auto;
      height: 100rem;
      padding-top: 10rem;
      padding-bottom: 10rem;
      padding-left: 30rem;
      border-bottom: 1px solid #e5e5e5;

      &:last-of-type {
        border-bottom: none;
      }

      .level {
        display: flex;
        align-items: center;
        width: 150rem;
        height: 80rem;
        padding-left: 30rem;
        margin-right: 10rem;
        border-right: 3px solid rgba($color: #000000, $alpha: 1);

        font-size: 18rem;
        line-height: 28rem;
        font-weight: 600;
        color: rgba($color: #000000, $alpha: 1);
      }

      .value {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        width: 130rem;
        height: 80rem;
        padding-left: 17.5rem;
        margin-right: 50rem;
        background-color: rgba($color: #000000, $alpha: 0.05);

        font-size: 18rem;
        line-height: 22rem;
        font-weight: 600;
      }
    }
  }

  .position {
    grid-column: span 1;
    width: 100%;

    .positionTitle {
      font-size: 12rem;
      line-height: 14rem;
      margin-bottom: 10rem;
      padding-right: 12rem;
    }

    .positionNumbers {
      position: relative;
      display: grid;
      grid-template-columns: 300rem auto;
      align-content: center;
      align-items: center;
      row-gap: 5rem;
      height: 100rem;
      padding: 10rem 0;
      border-bottom: 1px solid #e5e5e5;

      &::before {
        content: '';
        position: absolute;
        left: 0;
        top: 0rem;
        width: 1px;
        height: 100rem;
        background-color: rgba(0, 0, 0, 0.5);
      }

      &:nth-child(2) {
        &::before {
          top: 10rem;
          height: 90rem;
        }
      }

      &:nth-child(4) {
        border-bottom: none;

        &::before {
          top: 0;
          height: 90rem;
        }
      }

      .positionLineWrapper {
        grid-column: span 1;
        position: relative;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        width: 100%;
        height: 40rem;
        background-color: rgba($color: #1364b4, $alpha: 0.1);

        .positionLine {
          position: absolute;
          height: 100%;
          background-color: #1364b4;
        }

        .positionLinePercent {
          z-index: 10;
          padding-left: 20rem;

          font-size: 12rem;
          line-height: 14rem;
          color: #fff;
          text-align: center;

          &.black {
            color: #000;
          }
        }
      }

      .positionNumber {
        grid-column: span 1;

        font-size: 12rem;
        line-height: 14rem;
        font-weight: 600;
      }

      &.twoStreams {
        .positionLineWrapper {
          height: 20rem;
        }
      }
    }
  }
}
