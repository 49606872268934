.anketaCopyModal {
  width: 800rem !important;
  padding-bottom: 60rem;

  .title {
    margin-bottom: 60rem;
  }

  .reactSelect {
    width: 100%;
    margin-bottom: 30rem;
  }

  .inputSelect:nth-child(1) {
    position: relative;
    z-index: 60;
  }

  .inputSelect:nth-child(2) {
    position: relative;
    z-index: 50;
  }

  .inputSelect:nth-child(3) {
    position: relative;
    z-index: 40;
  }

  .inputError.center {
    text-align: center;
  }

  button {
    margin: 0 auto 10rem;
  }
}
