.stagesIndicator {
  display: grid;
  grid-template-columns: max-content max-content max-content max-content max-content;
  column-gap: 45rem;
  padding-bottom: 30rem;
  margin-bottom: 30rem;
  border-bottom: 1rem solid rgba(0, 0, 0, 0.1);

  .link {
  }

  // .stage {
  //   display: flex;
  //   justify-content: center;
  //   align-items: center;
  //   width: 30rem;
  //   height: 30rem;
  //   border-radius: 50%;
  //   background-color: var(--button-blue-normal);

  //   font-size: 14rem;
  //   font-weight: 600;
  //   line-height: 14rem;
  //   color: #fff;

  //   &.disabled {
  //     background-color: #dedede;
  //   }

  //   &.active {
  //     background-color: var(--button-black-normal);
  //   }
  // }
}
