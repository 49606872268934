.templateStage5Form {
  .title {
    margin-bottom: 50rem;
  }

  form {
    display: grid;
    row-gap: 75rem;

    .subTitle {
      margin-bottom: -35rem;
    }
  }
}
