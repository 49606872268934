.profileAnketasTable {
  display: grid;
  padding: 30rem 0 0;

  .buttonsContainer {
    position: relative;
    display: flex;

    button:first-child {
      margin-right: 20rem;
    }

    .dropdownButton {
      display: flex;
      justify-content: center;
      align-items: center;
      text-transform: none !important;

      img {
        width: 10rem;
        margin-left: 7rem;
        margin-right: 0;
      }
    }

    .dropdownMenu {
      position: absolute;
      z-index: 50;
      left: 0;
      top: 50rem;
      width: 390rem;
      padding: 17rem 20rem;
      background-color: #fff;
      box-shadow: 1px 3px 7px rgba(0, 0, 0, 0.2);

      button {
        justify-content: flex-start;
        padding-left: 20rem;
      }
    }

    button {
      text-transform: none !important;
      margin-right: 20rem;
    }
  }

  .errorMessage {
    font-size: 14rem;
    font-weight: 600;
    line-height: 16rem;
    color: #ef193a;
  }

  table {
    tr {
      display: grid;
      grid-auto-columns: max-content;

      th,
      td {
        grid-column: span 1;
        grid-row: 1;
        display: flex;
        align-items: center;
        height: 75rem;
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
        text-align: left;
        padding-right: 20rem;

        // * name
        &:nth-of-type(1) {
          width: 530rem;
        }

        // * progress
        &:nth-of-type(2) {
          width: 180rem;
        }

        // * writing deadline
        &:nth-of-type(3) {
          width: 215rem;
        }

        // * status
        &:nth-of-type(4) {
          width: 165rem;
        }

        // * interview
        &:nth-of-type(5) {
          width: 125rem;
        }

        // * interview deadline
        &:nth-of-type(6) {
          width: 165rem;
        }
      }

      th {
        position: relative;
        font-size: 12rem;
        line-height: 14rem;
        font-weight: 400;
        text-transform: uppercase;
        user-select: none;

        .sortUpIcon,
        .sortDownIcon {
          width: 10rem;
          height: 4rem;
          margin-left: 10rem;
          margin-bottom: 2rem;

          &.sortDownIcon {
            transform: rotate(180deg);
          }
        }

        &:nth-of-type(4) {
          font-weight: 600;
        }
      }

      td {
        height: 75rem;
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);

        font-size: 14rem;
        line-height: 16rem;
        color: rgba(0, 0, 0, 1);
        overflow-x: hidden;
        text-overflow: ellipsis;
        white-space: wrap;

        .progressContainer {
          position: relative;
          width: 160rem;
          height: 40rem;
          display: flex;
          justify-content: flex-start;
          align-items: center;

          .progressText {
            position: relative;
            z-index: 20;
            font-weight: bold;
          }

          .progress {
            position: absolute;
            z-index: 10;
            left: 0;
            top: 0;
            height: 100%;
          }

          &.red {
            background-color: rgba(239, 0, 74, 0.2);
            color: #fff;
            padding-left: 5rem;

            .progress {
              background-color: rgba(239, 0, 74, 1);
            }
          }

          &.green {
            background-color: rgba(17, 195, 19, 0.2);
            color: #fff;
            padding-left: 5rem;

            .progress {
              background-color: rgba(17, 195, 19, 1);
            }
          }

          &.blue {
            background-color: rgba(0, 174, 239, 0.2);
            color: #fff;
            padding-left: 5rem;

            .progress {
              background-color: rgba(0, 174, 239, 1);
            }
          }
        }

        // * date, memberID, memberName
        &:nth-of-type(2),
        &:nth-of-type(4),
        &:nth-of-type(5) {
          color: rgba(0, 0, 0, 0.7);
        }

        &:nth-of-type(4) {
          &.noMemberIDTable {
            font-size: 0;
            user-select: none;
          }
        }
      }
    }
  }
}
