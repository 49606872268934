.adminClientAnketas {
  display: grid;
  grid-template-columns: 250rem 1190rem;
  padding-top: 80rem;
  padding-bottom: 50rem;

  > .title {
    position: relative;
    grid-column: 1;
    padding-left: 30rem;
    padding-bottom: 25rem;

    &:after {
      content: '';
      position: absolute;
      left: 0;
      bottom: -6rem;
      width: 100%;
      height: 6rem;
      background-color: var(--button-blue-normal);
    }
  }

  .subTitleLink {
    grid-column: 2;
    justify-self: flex-end;
    padding-right: 30rem;
    padding-top: 10rem;

    font-size: 20rem;
    line-height: 36rem;
    color: var(--link-normal);
    text-decoration: none;
    text-align: right;

    &:hover {
      color: var(--link-hover);
    }
  }

  .anketaTable {
    grid-column: span 2;
    display: grid;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    padding: 30rem 30rem 0;
  }
}
