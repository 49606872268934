.anketaAnswerDraggable {
  form {
    display: grid;
    padding: 0 10rem;
    row-gap: 40rem;

    > button {
      margin-top: 20rem;
      margin-bottom: 45rem;
      justify-self: flex-end;
    }
  }

  .draggableVariant {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 18rem 20rem;

    .draggableVariantImage {
      width: 10rem;
      height: 16rem;
      margin-right: 15rem;

      background: url('../../../assets/images/icon_draggable--inactive.svg') center no-repeat;
      background-size: contain;
    }

    .draggableVariantText {
      font-size: 18rem;
      line-height: 21rem;
    }

    &.draggableVariant--Dragging {
      background-color: rgba(0, 174, 239, 0.05);

      .draggableVariantImage {
        background: url('../../../assets/images/icon_draggable--active.svg') center no-repeat;
        background-size: contain;
      }
    }
  }
}
