.noAvailableTimeModal,
.interviewAssignedModal,
.noInterviewModal,
.noTimeLeftModal {
  display: grid;

  .title {
    margin-bottom: 40rem;
    padding-left: 0;
  }

  .text {
    margin-bottom: 90rem;

    font-size: 18rem;
    line-height: 30rem;
    color: rgba(0, 0, 0, 1);
  }
}
