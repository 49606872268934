.emailConfirmModal {
  display: grid;
  width: 500rem !important;
  padding: 90rem 55rem 65rem 55rem !important;

  &.noText {
    width: 570rem !important;
    padding-bottom: 105rem !important;
  }

  .close {
    display: none;
  }

  .title {
    margin-bottom: 60rem;
  }

  .text {
    font-size: 18rem;
    line-height: 30rem;
    color: rgba(0, 0, 0, 1);
  }

  .inputError {
    height: 28rem;
    margin-bottom: 22rem;
    text-align: left;
  }

  .emailConfirmedButton {
    justify-self: center;
  }
}
