.memberAnketaDescription,
.expertAnketaDescription {
  display: grid;

  .memberAnketaHeader,
  .expertAnketaHeader {
    margin-bottom: 55rem;
  }

  > .title {
    margin-bottom: 40rem;
    padding: 0 270rem;
  }

  .containerWYSIWYG {
    margin-bottom: 55rem;
    padding: 0 270rem;
  }

  > a {
    margin-bottom: 20rem;
  }

  .hint {
    margin-bottom: 75rem;
    padding: 0 250rem 0 425rem;

    font-size: 14rem;
    line-height: 18rem;
    color: rgba(0, 0, 0, 0.5);
  }
}
