.competentionsCircles {
  display: grid;
  grid-template-columns: auto max-content;
  padding-top: 50rem;

  h1 {
    grid-column: 1;
    min-width: 1010rem;
    padding-left: 40rem;
    margin: 0;

    font-size: 36rem;
    line-height: 47.5rem;
    font-weight: 600;
    white-space: pre-line;
  }

  .membersCount {
    grid-column: 1;
    padding-left: 40rem;
    font-size: 14rem;
    line-height: 20rem;
    margin-top: -30rem;
  }

  .points {
    grid-column: 2;
    grid-row-start: 1;
    display: grid;
    grid-template-columns: max-content max-content;
    justify-content: space-between;
    align-content: center;
    align-self: flex-end;
    justify-self: flex-end;
    row-gap: 5rem;
    width: 430rem;
    height: 95rem;
    padding: 0 30rem;

    &.secondStream {
      grid-row-start: 2;
    }

    .pointsHeader {
      grid-column: span 1;

      font-size: 10rem;
      line-height: 12rem;
      color: rgba($color: #ffffff, $alpha: 1);

      &:nth-child(1) {
        justify-self: flex-start;
      }

      &:nth-child(2) {
        justify-self: flex-end;
      }
    }

    .pointsValue {
      grid-column: span 1;
      grid-row: 2;
      font-size: 12rem;
      line-height: 28rem;
      color: rgba($color: #ffffff, $alpha: 1);

      .value {
        font-size: 24rem;
      }

      &:nth-child(4) {
        justify-self: flex-end;

        .value {
          font-weight: 600;
        }
      }
    }
  }

  .circleResult {
    grid-column: span 2;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    row-gap: 45rem;
    column-gap: 95rem;
    padding: 65rem 40rem 0;

    .circleRate {
      grid-column: span 1;
      position: relative;
      width: 202rem;
      padding-top: 202rem;
      height: auto;

      .circleContainer {
        position: absolute;

        &:nth-child(1) {
          left: 0;
          top: 0;
          width: 202rem;
          height: 202rem;
        }

        &:nth-child(2) {
          left: 25rem;
          top: 25rem;
          width: 152rem;
          height: 152rem;
        }
      }

      .circleTextOutside {
        margin-top: 13rem;

        font-size: 14rem;
        line-height: 16rem;
        font-weight: 600;
        color: rgba($color: #000000, $alpha: 1);
        text-align: center;
      }
    }
  }

  &.oneStream {
    .circleResult {
      .circleRate {
        .circleTextInside {
          .firstStream {
            position: absolute;
            width: 100%;
            text-align: center;

            .value {
              display: block;
              font-size: 24rem;
              line-height: 28rem;
              font-weight: 400;
            }

            .suffix {
              font-size: 12rem;
              line-height: 16rem;
              font-weight: 400;
            }
          }

          &.pointsText .firstStream {
            top: 77.5rem;
          }

          &.percentText .firstStream {
            top: 85rem;
          }
        }
      }
    }
  }

  &.twoStreams,
  &.threeStreams {
    .circleResultExplanation {
      padding-left: 45rem;
      margin-top: 10rem;
      &.-mb-60 {
        margin-top: -60rem;
      }

      .circleResultTitle {
        grid-column: span 3;
        font-size: 18rem;
        font-weight: bold;
        margin-bottom: 10rem;
      }

      .explanationContainer {
        display: grid;
        grid-template-columns: max-content max-content max-content;
        align-items: center;
        column-gap: 10rem;

        .color {
          grid-column: span 1;
          width: 10rem;
          height: 10rem;

          &:first-child {
            background-color: rgba($color: #000000, $alpha: 0.1);
          }
        }

        .text {
          grid-column: span 1;

          font-size: 10rem;
          line-height: 24rem;
          color: rgba($color: #191919, $alpha: 1);
        }
      }
    }

    .circleResult {
      .circleRate {
        .circleTextInside {
          .secondStream {
            position: absolute;
            width: 100%;
            text-align: center;

            .value {
              display: block;
              font-size: 24rem;
              line-height: 28rem;
              font-weight: 400;
            }

            .suffix {
              font-size: 12rem;
              line-height: 16rem;
              font-weight: 400;
            }
          }

          .firstStream {
            position: absolute;
            width: 100%;
            text-align: center;

            .value,
            .suffix {
              font-size: 12rem;
              line-height: 16rem;
              font-weight: 400;
              color: rgba($color: #000000, $alpha: 0.5);
            }
          }

          &.pointsText .secondStream {
            top: 70rem;
          }
          &.pointsText .firstStream {
            top: 115rem;
          }

          &.percentText .secondStream {
            top: 80rem;
          }
          &.percentText .firstStream {
            top: 110rem;
          }
        }
      }
    }
  }

  &.threeStreams {
    .circleResultExplanation {
      margin-top: -72rem;
    }
    .circleResult {
      .circleRate {
        width: 252rem;
        padding-top: 252rem;

        .circleContainer {
          &:nth-child(1) {
            left: 0;
            top: 0;
            width: 252rem;
            height: 252rem;
          }

          &:nth-child(2) {
            left: 26rem;
            top: 26rem;
            width: 202rem;
            height: 202rem;
          }

          &:nth-child(3) {
            left: 52rem;
            top: 52rem;
            width: 152rem;
            height: 152rem;
          }
        }

        .circleTextInside {
          .thirdStream {
            position: absolute;
            width: 100%;
            text-align: center;

            .value {
              display: block;
              font-size: 24rem;
              line-height: 28rem;
              font-weight: 400;
            }

            .suffix {
              font-size: 12rem;
              line-height: 16rem;
              font-weight: 400;
            }
          }

          .firstStream,
          .secondStream {
            position: absolute;
            width: 100%;
            text-align: center;

            .value,
            .suffix {
              display: inline;
              font-size: 12rem;
              line-height: 16rem;
              font-weight: 400;
              color: rgba($color: #000000, $alpha: 0.5);
            }
          }

          .secondStream {
            top: 130rem;
          }

          &.pointsText {
            .thirdStream {
              top: 85rem;
            }

            .secondStream {
              top: 130rem;
            }

            .firstStream {
              top: 145rem;
            }
          }

          &.percentText {
            .thirdStream {
              top: 95rem;
            }

            .secondStream {
              top: 127.5rem;
            }

            .firstStream {
              top: 145rem;
            }
          }
        }
      }
    }
  }
}
