@import '../AddClientForExpertModal/styles';

.removeClientForExpertModal {
  width: 700rem !important;

  .subTitle {
    margin-bottom: 35rem;
  }

  .clientsList {
    margin-bottom: 0rem;

    .client {
      position: relative;
      padding-left: 35rem;
      cursor: pointer;

      .clientCheckbox {
        position: absolute;
        left: 0;
        top: 0rem;
        width: 16rem;
        height: 16rem;
        border: 1px solid #b6b6b6;
      }

      &.checked {
        .clientCheckbox {
          &::before {
            content: '';
            position: absolute;
            left: 2rem;
            bottom: 2rem;
            width: 14.5rem;
            height: 17rem;
            background: url(../../../../assets/images/icon_checkedLong.svg) center no-repeat;
            background-size: contain;
          }
        }
      }
    }
  }
}
