.adminExpertSchedule {
  position: relative;
  margin-top: -2rem;
  padding-top: 10rem;
  padding-bottom: 40rem;
  border-bottom: 1px solid #dedede;
  background-color: #fff;

  .title {
    width: 250rem;
    font-size: 18rem;
    line-height: 36rem;
    text-align: center;
    padding-bottom: 15rem;
  }

  .scheduleTabs {
    grid-template-columns: 250rem 250rem 250rem 250rem 370rem;
  }

  .scheduleTable {
    padding: 0 30rem;

    .timeTable {
      .time {
        cursor: default;

        &:hover {
          background-color: #fff;
          color: rgba(0, 0, 0, 0.8);
        }

        &.disabled {
          background-color: #f9f9f9 !important;
          color: rgba(0, 0, 0, 0.4) !important;
        }

        &.busy {
          background-color: #252424 !important;
          color: rgba(255, 255, 255, 1) !important;
        }
      }
    }
  }
}
