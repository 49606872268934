.expertAnketaQuestionAutoRate {
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-content: flex-start;
  padding: 50rem 30rem;
  border-left: 1px solid rgba(0, 0, 0, 0.1);

  .rateTitle {
    grid-column: span 1;
    margin-bottom: 45rem;

    font-size: 14rem;
    line-height: 16rem;
    color: rgba(0, 0, 0, 1);
    text-transform: uppercase;
  }

  .rateExplaantion {
    position: relative;
    grid-column: span 1;
    align-self: center;
    justify-self: flex-end;
    padding-left: 20rem;
    margin-bottom: 45rem;

    font-size: 10rem;
    line-height: 12rem;
    color: rgba($color: #000000, $alpha: 0.5);

    &::before {
      content: '';
      position: absolute;
      left: 0;
      top: 1rem;
      width: 10rem;
      height: 10rem;
      background-color: #000;
    }
  }

  .rate {
    grid-column: span 2;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50rem;

    font-size: 18rem;
    line-height: 21rem;
    font-weight: 600;

    &.active {
      background-color: #000;
      color: #fff;
    }
  }

  > a {
    // margin-top: 110rem;
    grid-column: span 2;
  }
}
