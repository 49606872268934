.memberAnketaNoInterviewComplete {
  display: grid;
  padding: 80rem 30rem 50rem;
  background: rgba(255, 255, 255, 1);
  align-items: center;
  justify-content: center;

  .subTitle {
    font-size: 24rem;
    line-height: 48rem;
    color: rgba(0, 0, 0, 1);

    small {
      display: block;
      margin-top: 30rem;
      font-size: 18rem;
      line-height: 30rem;
    }
  }
}
