.templateStage1Form {
  .title {
    margin-bottom: 50rem;
  }

  form {
    display: grid;
    grid-template-columns: 400rem 400rem 400rem;
    column-gap: 80rem;
    row-gap: 40rem; // * 60rem - 20rem ( высота скрытого div валидации )

    .subTitle {
      grid-column: span 1;

      font-size: 24rem;
      font-weight: 600;
      line-height: 36rem;
    }

    .checkboxes-container {
      grid-column: span 2;
      display: flex;
    }

    .checkbox {
      grid-column: span 1;
      justify-self: flex-end;
      margin-left: 10rem;

      .checkboxContainer {
        position: relative;
        width: 16rem;
        height: 16rem;
        border: 1px solid rgba($color: #fff, $alpha: 0.5);
        margin-right: 10rem;

        > img {
          position: absolute;
          left: 4.5rem;
          bottom: 4.5rem;
          width: 14.5rem;
          height: 17rem;
        }
      }
    }

    > *:nth-child(3),
    > *:nth-child(4),
    > *:nth-child(5),
    > *:nth-child(6),
    > *:nth-child(7) {
      grid-column: span 1;
    }

    > *:nth-child(3) {
      grid-column: span 3;
      max-width: 880rem;
    }

    > *:nth-child(8),
    > *:nth-child(9),
    > *:nth-child(10) {
      grid-column: span 3;
    }
  }
}
