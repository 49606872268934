.anketaAnswerCheckboxes {
  form {
    display: grid;
    padding: 0 30rem;
    row-gap: 40rem;

    > button {
      margin-top: 20rem;
      margin-bottom: 45rem;
      justify-self: flex-end;
    }
  }
}
