.inquirerFieldsWrapper {
  display: grid;

  .inquirer {
    display: grid;
    grid-template-columns: 1250rem max-content;
    column-gap: 15rem;
    align-items: flex-end;

    .inquirerFieldWrapper {
      width: 1250rem;
    }

    .addRemoveButtons {
      grid-column: span 1;
      display: grid;
      grid-template-columns: max-content max-content;
      column-gap: 15rem;
      margin-bottom: 21rem;

      .whiteButton {
        border: 1px solid var(--button-blue-normal);
      }

      img {
        margin: 0;

        &.iconRemove {
          width: 25rem;
          height: 25rem;
        }

        &.iconAdd {
          width: 15rem;
          height: 15rem;
        }
      }
    }
  }

  .inquirerQuestionType {
    display: flex;
    flex-direction: row;
    margin-top: 15rem;
    margin-bottom: 60rem;
  }

  .inquirerVariantsWrapper {
    display: grid;
    grid-template-columns: 700rem max-content;
    column-gap: 15rem;
    align-items: flex-end;
    margin-bottom: 45rem;

    .addRemoveButtons {
      grid-column: span 1;
      display: grid;
      grid-template-columns: max-content max-content;
      column-gap: 15rem;
      margin-bottom: 21rem;

      .whiteButton {
        border: 1px solid var(--button-blue-normal);
      }

      img {
        margin: 0;

        &.iconRemove {
          width: 25rem;
          height: 25rem;
        }

        &.iconAdd {
          width: 15rem;
          height: 15rem;
        }
      }
    }
  }
}
