.checkbox {
  input[type='checkbox'] {
    display: none;
  }

  .checkboxVisibleLabel {
    display: grid;
    align-items: flex-start;
    grid-template-columns: 16rem auto;
    column-gap: 10rem;
    user-select: none;
    cursor: pointer;

    .checkboxIcon {
      position: relative;
      width: 16rem;
      height: 16rem;
      margin-top: 2rem;
      border: 1px solid rgba($color: #b6b6b6, $alpha: 1);

      &.checked {
        &::before {
          content: '';
          position: absolute;
          bottom: 3rem;
          left: 3rem;
          background: url(../../assets/images/icon_checkedLong.svg) center no-repeat;
          width: 17rem;
          height: 21rem;
        }
      }
    }

    .checkboxText {
      font-size: 18rem;
      line-height: 21rem;
    }
  }
}
