.adminClients {
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding-top: 60rem;
  padding-bottom: 40rem;

  .title {
    position: relative;
    grid-column: span 2;
    padding: 0 30rem 25rem;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    margin-bottom: 30rem;

    &::after {
      content: '';
      position: absolute;
      left: 0;
      top: 100%;
      width: 630rem;
      height: 6rem;
      background-color: #00aeef;
    }
  }

  .adminClientMetric {
    grid-column: span 1;
  }

  &.threeClients {
    .adminClientMetric {
      &:nth-child(3) {
        border-bottom: 1px solid rgba(0, 0, 0, 0.1) !important;
      }
    }
  }
}
