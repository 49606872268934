.clientRegistrationForm {
  .inputSelect {
    padding-top: 6rem;
    margin-bottom: 12rem;
  }

  .roleInputsFieldContainer,
  .segmentInputsFieldContainer {
    display: grid;
    column-gap: 15rem;
    margin-top: 8rem;

    .title {
      grid-column: span 3;
      margin-bottom: 20rem;

      font-size: 14rem;
      font-weight: 400;
      line-height: 16rem;
      color: rgba(0, 0, 0, 0.5);
    }

    .radiobutton {
      grid-column: span 1;
    }

    .inputError {
      grid-column: span 3;
    }
  }
  .doubleLineSubmitError {
    height: 28rem;
  }
}
