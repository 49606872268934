.expertAnketaQuestionsEvaluation {
  display: grid;
  grid-template-columns: 1030rem 410rem;

  .expertAnketaHeader,
  .anketaProgression,
  .expertAnketaSubquestionsProgression {
    grid-column: span 2;

    // &.anketaHeader {
    //   grid-row: 1;
    // }

    // &.anketaProgression {
    //   grid-row: 2;
    // }
  }

  .anketaQuestion {
    grid-column: 1;
    grid-row: 4;
  }

  .expertAnketaQuestionRate,
  .expertAnketaQuestionAutoRate {
    grid-column: 2;
    grid-row: 4/7;
    height: 100%;
  }

  .expertAnketaAnswerText {
    grid-column: 1;
    grid-row: 5;
  }
}
