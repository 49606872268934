.inputError {
  height: 14rem;
  margin-top: 8rem;
  margin-bottom: 12rem;

  font-size: 12rem;
  line-height: 14rem;
  color: #EF004A;  

  &.success {
    color: var(--button-blue-normal);
  }
}