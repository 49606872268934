.disabledTimeSlotAlertModal {
  width: auto !important;
  padding: 37.5rem !important;

  .title {
    width: 100%;
    margin-bottom: 35rem;

    font-size: 36rem;
    line-height: 36rem;
    text-align: left;
  }

  .text {
    margin-bottom: 35rem;

    font-size: 18rem;
    line-height: 30rem;
  }

  button {
    margin: 0 auto 5rem;
  }
}
