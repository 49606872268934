.recomendationsGeneral {
  padding-top: 100rem;

  &:nth-child(1) {
    padding-top: 0;
  }

  .comment,
  .courseRecomendation {
    padding-top: 90rem;
    padding-left: 40rem;
    padding-right: 40rem;
    padding-bottom: 40rem;
    border-bottom: 1px solid rgba($color: #000000, $alpha: 0.1);

    .title {
      margin-bottom: 30rem;
      line-height: 44rem;
    }

    &.courseRecomendation {
      .containerWYSIWYG {
        p {
          color: inherit;

          b,
          strong {
            color: rgba($color: #000000, $alpha: 1) !important;
          }

          // a {
          //   color: inherit;

          //   &:hover {
          //     color: inherit;
          //   }
          // }
        }
      }
    }
  }
}
