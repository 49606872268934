// @import '../../../AnketaProgression/AnketaProgression.scss';

.questionsList {
  display: grid;
  grid-auto-flow: row;
  grid-template-columns: 150rem auto;
  border-bottom: 1rem solid rgba(0, 0, 0, 0.1);
  padding: 0 0 30rem;
  margin-bottom: 40rem;

  .questionsListTitle {
    grid-column: span 1;
    align-self: center;

    font-size: 14rem;
    line-height: 16rem;
    color: rgba($color: #000000, $alpha: 0.5);
  }

  .grid {
    display: grid;
    grid-template-columns: repeat(16, max-content);
    column-gap: 50rem;
    row-gap: 20rem;
  }

  .progressionPoint {
    position: relative;
    grid-column: span 1;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30rem;
    height: 30rem;
    background-color: var(--button-blue-normal);
    border-radius: 50%;

    font-size: 14rem;
    font-weight: 600;
    line-height: 14rem;
    color: rgba(255, 255, 255, 1);
    user-select: none;

    &:before {
      content: '';
      position: absolute;
      width: 30rem;
      height: 1rem;
      background-color: rgba(242, 242, 242, 1);
      right: calc(100% + 10rem);
      top: 15rem;
    }

    &:nth-of-type(1):before,
    &:nth-of-type(17):before,
    &:nth-of-type(33):before,
    &:nth-of-type(49):before {
      display: none;
    }

    &.active {
      background-color: rgba(229, 225, 225, 1);
    }

    &.hoverPossible {
      cursor: pointer;

      &:hover {
        background-color: var(--button-blue-hover);
      }

      &.active {
        &:hover {
          cursor: default;
          background-color: var(--button-black-normal);
        }
      }
    }
  }
}
