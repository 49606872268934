.containerWYSIWYG {
  overflow: auto;

  > * {
    &:last-child {
      margin-bottom: 0;

      li:last-child {
        margin-bottom: 0;
      }
    }
  }

  &.large {
    p {
      font-size: 24rem;
      line-height: 38rem;
      color: #000000 !important;
      margin: 0;

      &:first-of-type {
        margin-top: 0;
      }
    }

    ul {
      padding: unset;

      li {
        position: relative;
        margin: 5rem 0;
        padding-left: 32.5rem;
        list-style: none;

        font-size: 24rem;
        line-height: 30rem;
        color: rgba(0, 0, 0, 1) !important;

        &:before {
          content: '';
          position: absolute;
          left: 0;
          top: 11rem;
          width: 10rem;
          height: 10rem;
          background-color: var(--button-blue-normal);
        }
      }
    }

    ol {
      padding: unset;

      li {
        position: relative;
        margin: 5rem 0;
        padding: 0;
        list-style: none;

        font-size: 24rem;
        line-height: 30rem;
        color: rgba(0, 0, 0, 1) !important;
        counter-increment: list-0;

        &:before {
          content: counter(list-0, decimal) '.  ';
          width: auto;
          margin: 0;
          white-space: pre;
        }
      }
    }

    a {
      color: var(--link-normal);
      text-decoration: none;

      &:hover {
        color: var(--link-hover);
      }
    }
  }

  &.default {
    font-size: 18rem;
    line-height: 24rem;
    white-space: pre-wrap;

    p {
      font-size: 18rem;
      line-height: 24rem;
      margin: 0;
      color: #000000;

      &:first-of-type {
        margin-top: 0;
      }
    }

    ul {
      padding-left: 0;

      li {
        position: relative;
        margin: 5rem 0;
        padding-left: 25rem;
        list-style: none;
        font-size: 18rem;
        line-height: 24rem;
        color: rgba(0, 0, 0, 1) !important;

        &:before {
          content: '';
          position: absolute;
          left: 0;
          top: 9rem;
          width: 8rem;
          height: 8rem;
          background-color: var(--button-blue-normal);
        }
      }
    }

    ol {
      padding-left: 0;

      li {
        position: relative;
        margin: 5rem 0;
        padding: 0;
        list-style: none;

        font-size: 18rem;
        line-height: 24rem;
        color: rgba(0, 0, 0, 1) !important;
        counter-increment: list-0;

        &:before {
          content: counter(list-0, decimal) '.  ';
          width: auto;
          margin: 0;
          white-space: pre;
        }
      }
    }

    a {
      color: var(--link-normal);
      text-decoration: none;

      &:hover {
        color: var(--link-hover);
      }
    }
  }
}
