.adminClientLink {
  display: grid;
  row-gap: 20rem;
  margin-bottom: 50rem;
  padding-left: 30rem;

  .linkContainer {
    display: grid;
    grid-template-columns: max-content max-content max-content max-content;
    row-gap: 10rem;
    column-gap: 10rem;
    align-items: center;

    .linkTitle {
      width: 260rem;
      text-align: left;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      font-size: 14rem;
      line-height: 16rem;
      color: rgba($color: #000000, $alpha: 0.5);

      &:nth-child(3),
      &:nth-child(5) {
        margin-top: 10rem;
      }
    }

    .toClipboardIcon {
      width: 14rem;
      height: 16rem;
      cursor: pointer;
    }

    .copiedToClipboard {
      font-size: 10rem;
      line-height: 16rem;
      color: var(--link-normal);
    }
  }
}
