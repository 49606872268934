.expertSchedule {
  display: grid;
  grid-template-columns: 1170rem 270rem;
  padding: 60rem 0 30rem;

  background-color: #fff;

  .title {
    grid-column: 1;
    padding: 0 30rem;
    margin-bottom: 25rem;
  }

  .subTitleLink {
    grid-column: 2;
    padding-right: 30rem;

    font-size: 20rem;
    line-height: 36rem;
    color: var(--link-normal);
    text-decoration: none;
    text-align: right;

    &:hover {
      color: var(--link-hover);
    }
  }

  .anketsDasboard,
  .scheduleTabs,
  .scheduleTable {
    grid-column: span 2;
  }

  .button {
    margin-top: 30rem;
  }
}
