.header {
  position: relative;
  z-index: 400;

  .logo {
    position: fixed;
    left: 30rem;
    top: 50rem;
    width: 100rem;
    height: 28rem;
    cursor: pointer;
  }

  &.userIsLogged {
    .userHeader {
      background-color: #252424;

      .contentContainer {
        display: grid;
        grid-template-columns: max-content auto max-content;
        column-gap: 40rem;
        align-items: flex-end;
        height: 100rem;
        padding: 0 30rem 20rem;

        .logo {
          position: relative;
          left: unset;
          top: unset;
          grid-column: 1;
          width: auto;
          height: 28rem;
        }

        nav {
          grid-column: 3;
          display: grid;
          column-gap: 40rem;

          .link {
            grid-row: 1;

            &.link-white-style {
              color: rgba(255, 255, 255, 1);

              &:hover {
                color: var(--link-hover);
              }
            }
          }

          .link:last-child,
          .link:first-child {
            background: none;
            border: none;
            padding: 0;
            cursor: pointer;
          }
        }
      }
    }

    .adminTopHeader,
    .adminBottomHeader,
    .userBottomHeader {
      .contentContainer {
        display: grid;
        grid-template-columns: max-content auto max-content;
        align-items: flex-end;

        nav {
          display: grid;

          &:nth-of-type(1) {
            grid-column: 1;
          }

          &:nth-of-type(2) {
            grid-column: 3;
          }

          .link-button {
            grid-row: 1;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 210rem;
            height: 69rem;
            padding-left: 20rem;
            padding-right: 20rem;

            font-size: 12rem;
            font-weight: 600;
            line-height: 14rem;
            text-transform: uppercase;
          }

          .link-pale-style {
            grid-row: 1;
            display: flex;
            justify-content: center;
            align-items: center;
            height: 69rem;

            text-transform: uppercase;
          }

          .addClientIcon {
            width: 15rem;
            height: 15rem;
          }

          .clientsExpandIcon {
            width: 12rem;
            height: 6rem;
          }
        }
      }

      &.adminTopHeader {
        border-top: 1px solid rgba(255, 255, 255, 0.2);
        background-color: var(--button-black-normal);

        .contentContainer {
          position: relative;
          display: flex;
          width: 100%;
          height: 70rem;

          nav {
            position: relative;
            display: grid;
            grid-template-columns: max-content max-content auto max-content;
            width: 100%;
            align-items: center;
            align-self: center;
            padding-right: 30rem;
            overflow: unset;

            .clientsExpandButton,
            .streamsExpandButton {
              grid-column: span 1;
              padding-left: 30rem;
              justify-content: flex-start;

              span {
                margin-right: 10rem;
                font-size: 12rem;
                line-height: 14rem;
                text-transform: uppercase;
              }

              > img {
                transform: rotate(180deg);
              }
            }

            .addClientButton {
              grid-column: 4;
            }

            .clients,
            .streams {
              position: absolute;
              z-index: 70;
              top: 55rem;

              &.clients {
                left: 0;
              }
              &.streams {
                left: 350rem;
              }

              display: none;
              max-height: 500rem;
              border-top: 1px solid var(--button-blue-hover);
              overflow: scroll;

              &.expanded {
                display: block;
              }

              .link-button {
                justify-content: flex-start;
                padding-left: 30rem;
                width: 340rem;
                height: 40rem;
              }
            }
          }

          .link-button {
            background-color: var(--button-black-normal);
            color: rgba(255, 255, 255, 1);

            &.active {
              cursor: default;
              background-color: var(--button-blue-normal);

              &:hover {
                background-color: var(--button-blue-normal);
              }
            }

            &:hover {
              background-color: var(--button-blue-hover);
            }
          }
        }
      }

      &.adminBottomHeader,
      &.userBottomHeader {
        background-color: rgba(255, 255, 255, 1);
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);

        .contentContainer {
          display: flex;
          width: 100%;
          justify-content: flex-end;
          height: 62rem;

          nav {
            .link-button {
              width: 120rem;
              height: 61rem;
              position: relative;
              color: rgba(37, 36, 36, 1);

              &:hover {
                color: var(--button-blue-hover);
              }

              &.active {
                cursor: default;

                &:hover {
                  color: rgba(37, 36, 36, 1);
                }

                &:after {
                  content: '';
                  position: absolute;
                  left: 0;
                  bottom: 0rem;
                  width: 100%;
                  height: 1rem;
                  background-color: var(--button-blue-normal);
                }
              }

              &.disabled {
                cursor: default;
                color: rgba(0, 0, 0, 0.5);

                &:hover {
                  color: rgba(0, 0, 0, 0.5);
                }

                &:after {
                  display: none;
                }
              }
            }
          }

          .addNewClientButton {
            grid-column: 3;
            align-self: center;
            position: relative;
            padding-left: 30rem;
            margin-right: 30rem;

            &:before {
              content: '';
              position: absolute;
              left: 35rem;
              top: 13rem;
              width: 15rem;
              height: 15rem;
              background: url(../../assets/images/icon_closeModal.svg) center no-repeat;
              transform: rotate(45deg);
            }
          }
        }
      }
    }

    .userBottomHeader {
      .contentContainer {
        nav {
          .link-button {
            &.active {
              &:after {
                bottom: 0rem;
                height: 6rem;
              }
            }
          }
        }
      }
    }
  }
}

.policy-alert {
  position: fixed;
  width: 100%;
  padding: 20rem 30rem;
  bottom: 0;
  border-top: 1px solid var(--button-blue-normal);
  background: #fff;
  font-size: 18rem;

  display: flex;
  justify-content: flex-start;

  a {
    font-size: 18rem;
  }

  button {
    cursor: pointer;
    background: none;
    font-size: 20rem;
    margin-left: 10rem;
  }
}
