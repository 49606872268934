.anketaTable {
  display: grid;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  padding: 30rem 30rem 0;

  .buttonsContainer {
    position: relative;
    display: flex;

    button:first-child {
      margin-right: 20rem;
    }

    .dropdownButton {
      display: flex;
      justify-content: center;
      align-items: center;
      text-transform: none !important;

      img {
        width: 10rem;
        margin-left: 7rem;
        margin-right: 0;
      }
    }

    .dropdownMenu {
      position: absolute;
      z-index: 50;
      left: 0;
      top: 50rem;
      width: 390rem;
      padding: 17rem 20rem;
      background-color: #fff;
      box-shadow: 1px 3px 7px rgba(0, 0, 0, 0.2);

      button {
        justify-content: flex-start;
        padding-left: 20rem;
      }
    }

    button {
      text-transform: none !important;
      margin-right: 20rem;
    }
  }

  .errorMessage {
    font-size: 14rem;
    font-weight: 600;
    line-height: 16rem;
    color: #ef193a;
  }

  table {
    tr {
      display: grid;
      grid-auto-columns: max-content;

      th,
      td {
        grid-column: span 1;
        grid-row: 1;
        display: flex;
        align-items: center;
        height: 75rem;
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
        text-align: left;

        // * checkbox
        &:nth-of-type(1) {
          width: 35rem;

          .checkbox {
            position: relative;
            display: block;
            width: 16rem;
            height: 16rem;
            border: 1px solid #b6b6b6;

            &.disabled {
              background: rgba(182, 182, 182, 0.2);
            }

            &.selected {
              &::before {
                content: '';
                position: absolute;
                left: 2rem;
                bottom: 2rem;
                width: 14.5rem;
                height: 17rem;
                background: url(../../../assets/images/icon_checkedLong.svg) center no-repeat;
                background-size: contain;
              }
            }

            input[type='checkbox'] {
              display: none;
            }
          }

          .sortUpIcon {
            display: none;
          }
        }

        // * date
        &:nth-of-type(2) {
          width: 120rem;
        }

        // * expertName
        &:nth-of-type(3) {
          width: 140rem;
        }

        // * memberID
        &:nth-of-type(4) {
          width: 70rem;
        }

        // * memberName
        &:nth-of-type(5) {
          width: 215rem;
        }

        // * tribe
        &:nth-of-type(6) {
          width: 225rem;
        }

        // * role
        &:nth-of-type(7) {
          width: 225rem;
        }

        // * case-segment
        &:nth-of-type(8) {
          width: 130rem;
        }

        // * status
        &:nth-of-type(9) {
          width: 170rem;
        }

        // * PDF
        &:nth-of-type(10) {
          width: 50rem;
        }
      }

      th {
        position: relative;
        font-size: 12rem;
        line-height: 14rem;
        font-weight: 600;
        text-transform: uppercase;
        user-select: none;

        &:nth-of-type(4) {
          &.noMemberIDTable {
            * {
              visibility: hidden;
            }
          }
        }

        .sortUpIcon,
        .sortDownIcon {
          width: 10rem;
          height: 4rem;
          margin-left: 10rem;
          margin-bottom: 2rem;

          &.sortDownIcon {
            transform: rotate(180deg);
          }
        }

        .searchIcon {
          width: 20rem;
          height: 20rem;
          padding: 4rem;
          margin-left: 8rem;
          cursor: pointer;
        }

        .resetFilterIcon {
          width: 20rem;
          height: 20rem;
          margin-bottom: 3rem;
          margin-left: 4rem;
          cursor: pointer;
        }

        .searchPopup {
          position: absolute;
          z-index: 20;
          top: 74rem;
          display: flex;
          height: 130rem;
          align-items: flex-end;
          padding: 0 30rem 60rem;
          border: 1px solid #dedede;
          box-shadow: 1px 3px 7px rgba(0, 0, 0, 0.2);
          background-color: rgba(255, 255, 255, 1);

          &.expertSearch,
          &.memberNameSearch,
          &.tribeSearch,
          &.statusSearch {
            width: 470rem;
            left: -178rem;
          }

          &.statusSearch {
            left: -305rem;
          }

          &.memberIDSearch {
            width: 340rem;
            left: -145rem;
          }

          .close {
            position: absolute;
            right: 10rem;
            top: 10rem;
            width: 10rem;
            height: 10rem;
            padding: 0;
            border: 0;
            background: url(../../../assets/images/icon_closeGray.svg) center no-repeat;
            background-color: rgba(0, 0, 0, 0);
            background-size: contain;
            cursor: pointer;
          }

          > input[type='text'] {
            width: 100%;
            height: 39rem;
            padding-bottom: 8rem;
            border: none;
            border-bottom: 1rem solid var(--input-border-normal);
            background: rgba(255, 255, 255, 0);

            font-size: 24rem !important;
            font-weight: 400 !important;
            line-height: 28rem !important;
            color: rgba(0, 0, 0, 1);
            text-transform: none !important;

            &::placeholder {
              padding-top: 12rem;

              font-size: 14rem !important;
              line-height: 16rem !important;
              color: rgba(0, 0, 0, 0.5);
            }

            &:-webkit-autofill,
            &:-webkit-autofill:hover,
            &:-webkit-autofill:focus,
            &:-webkit-autofill:active {
              -webkit-box-shadow: 0 0 0 30rem white inset !important;
            }
          }
        }
      }

      td {
        height: 75rem;
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);

        font-size: 14rem;
        line-height: 16rem;
        color: rgba(0, 0, 0, 1);
        overflow-x: hidden;
        text-overflow: ellipsis;
        white-space: wrap;

        // * date, memberID, memberName
        &:nth-of-type(2),
        &:nth-of-type(4),
        &:nth-of-type(5) {
          color: rgba(0, 0, 0, 0.7);
        }

        &:nth-of-type(4) {
          &.noMemberIDTable {
            font-size: 0;
            user-select: none;
          }
        }

        .zoomVideoIcon {
          width: 16rem;
          height: 16rem;
          margin-left: 13rem;
          cursor: pointer;
        }

        &:nth-of-type(10) {
          overflow: visible;

          .anketaActionsContainer {
            position: relative;
            display: flex;
            align-items: center;
            font-size: 0;

            .anketaActionsToggleButton {
              padding: 0;
              margin: 0;
              border: 0;
              width: 24rem;
              height: 24rem;
              background: url(../../../assets/images/icon_expertActions.svg) center no-repeat;
              background-size: 24rem 24rem;
              cursor: pointer;

              &.active {
                width: 24rem;
                height: 24rem;
                background: url(../../../assets/images/icon_expertActionsActive.svg) center no-repeat;
                background-size: 24rem 24rem;
              }
            }

            .anketaActions {
              position: absolute;
              z-index: 60;
              top: calc(100% + 5rem);
              right: 0;
              display: none;
              flex-direction: column;
              justify-content: flex-start;
              align-items: center;
              width: 210rem;
              padding: 7rem 10rem;
              background: #ffffff;
              border: 1px solid #dedede;
              box-shadow: 1px 3px 7px rgba(0, 0, 0, 0.2);

              &.visible {
                display: flex;
              }

              button {
                display: flex;
                justify-content: flex-start;
                align-items: center;
                width: 100%;
                height: 39rem;
                padding: 0;
                padding-left: 10rem;
                margin: 0;
                border: 0;
                border-bottom: 1px solid rgba(0, 0, 0, 0.1);
                background-color: rgba(255, 255, 255, 0);
                cursor: pointer;

                &:last-of-type {
                  border-bottom: none;
                }
              }
            }
          }
        }
      }
    }
  }
}
