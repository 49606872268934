.anketsDasboard {
  display: grid;
  grid-template-columns: 260rem 275rem 275rem 275rem 355rem;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  margin-bottom: 65rem;

  .anketType {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    height: 110rem;
    padding: 0 30rem 25rem;
    border-right: 1px solid rgba(0, 0, 0, 0.1);

    cursor: pointer;

    &:last-of-type {
      border-right: 0;
    }

    &:hover {
      .typeAmount {
        color: var(--button-blue-hover);
      }
    }

    .typeDesciption {
      font-size: 12rem;
      line-height: 14rem;
      text-transform: uppercase;
      text-decoration: none;
      color: rgba(0, 0, 0, 0.6);
    }

    .typeAmount {
      font-size: 48rem;
      line-height: 56rem;
      color: var(--button-blue-normal);
      text-decoration: none;
    }
  }
}
