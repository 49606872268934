.modalWrapper {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.8);
  z-index: 1000;

  .modal {
    position: absolute;
    width: 1030rem;
    max-height: 700rem;
    overflow-y: visible;
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
    margin: 0 auto;
    padding: 55rem 65rem;
    background-color: rgba(255, 255, 255, 1);
    border: 1px solid rgba(222, 222, 222, 1);
    box-shadow: 1px 3px 7px rgba(0, 0, 0, 0.2);

    .close {
      position: absolute;
      right: 30rem;
      top: 30rem;
      width: 50rem;
      height: 50rem;
      z-index: 1001;
      background: url(../../assets/images/icon_closeModal.svg) center no-repeat;
      background-size: 30rem 30rem;
      background-color: var(--button-blue-normal);
      cursor: pointer;

      &:hover {
        background-color: var(--button-blue-hover);
      }
    }
  }
}
