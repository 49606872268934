.textareaWYSIWYG {
  position: relative;
  display: grid;
  width: 100%;
  height: 400px;
  padding-top: 36rem;

  .ql-toolbar.ql-snow + .ql-container.ql-snow {
    border-top: 1px solid #00aeef;
  }

  &.noToolbar {
    padding-top: 20rem;

    .placeholder {
      top: 25rem;
    }

    .quillEditor {
      .ql-toolbar {
        display: none;
      }
    }
  }

  &.small {
    &.noToolbar {
      .placeholder {
        top: 22rem;
      }
    }

    .quillEditor {
      .ql-container {
        .ql-editor {
          padding: 0 0 8rem 0;

          p {
            font-size: 16rem;
            line-height: 18rem;
          }
        }
      }
    }
  }

  .placeholder {
    position: absolute;
    z-index: 5;
    top: 0;
    padding-left: 5px;
    padding-right: 5px;
    width: 100%;

    font-size: 14rem;
    line-height: 16rem;
    color: rgba(0, 0, 0, 0.5);

    &.disabled {
      color: rgba(0, 0, 0, 0.2);
      cursor: not-allowed;
    }
  }

  .quillEditor {
    position: relative;
    z-index: 10;
    display: grid;

    &.invalid {
      .ql-container {
        border-bottom: 1px solid rgba(239, 0, 74, 1) !important;
      }
    }

    &.disabled {
      opacity: 0.3;

      * {
        cursor: not-allowed;
      }
    }

    .ql-toolbar {
      position: relative;
      z-index: 20;
      border: 0;
      padding: 0;
      margin-bottom: 35rem !important;

      .ql-formats {
        margin-right: 20rem;

        button {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 30rem;
          height: 30rem;
          background-color: #00aeef;

          &.ql-bold {
            svg {
              width: 15rem;
              height: 20rem;
            }
          }

          &.ql-list,
          &.ql-header {
            margin-right: 20rem;

            &:last-child {
              margin-right: 0;
            }

            svg {
              width: 17.5rem;
              height: 20rem;
            }

            &.ql-header svg * {
              fill: #fff !important;
            }
          }

          * {
            stroke: #fff;
          }
        }
      }
    }

    .ql-container {
      position: relative;
      z-index: 25;
      border: 1px solid #00aeef;
      overflow: auto;

      .ql-show {
        border-top: 1px solid #00aeef;
      }

      .ql-editor {
        padding: 0 0 8rem 0;

        // * placeholder
        &.ql-blank:before {
          position: absolute;
          left: 0;
          bottom: 12rem;
          font-size: 14rem;
          font-style: normal;
          line-height: 16rem;
          color: rgba(0, 0, 0, 0.5);
        }

        > * {
          &:last-child {
            margin-bottom: 0;

            li:last-child {
              margin-bottom: 0;
            }
          }
        }

        p {
          font-size: 18rem;
          line-height: 24rem;
          color: #000000;
          margin: 0;

          &:first-of-type {
            margin-top: 0;
          }
        }

        ul {
          padding-left: 0;

          li {
            position: relative;
            margin: 5rem 0;
            padding-left: 25rem;
            list-style: none;
            font-size: 18rem;
            line-height: 24rem;
            color: rgba(0, 0, 0, 1);

            &:before {
              content: '';
              position: absolute;
              left: 28rem;
              top: 9rem;
              width: 8rem;
              height: 8rem;
              background-color: var(--button-blue-normal);
            }
          }
        }

        ol {
          padding-left: 0;

          li {
            position: relative;
            margin: 5rem 0;
            padding: 0;
            list-style: none;

            font-size: 18rem;
            line-height: 24rem;
            color: rgba(0, 0, 0, 1);

            &:before {
              content: counter(list-0, decimal) '.  ';
              width: auto;
              white-space: unset;
              margin: 0;
            }
          }
        }

        a {
          color: #00aeef;
          text-decoration: none;
        }
      }

      // * создать ссылку
      .ql-editing {
        z-index: 50;
        top: -42rem !important;
        left: calc(50% - 150rem) !important;

        display: grid;

        width: 500rem;
        align-items: flex-end;
        padding: 30rem;
        border: 1px solid #dedede;
        box-shadow: 1px 3px 7px rgba(0, 0, 0, 0.2);
        background-color: rgba(255, 255, 255, 1);

        &.ql-hidden {
          display: none;
        }

        input[type='text'] {
          width: 100%;
          height: 39rem;
          padding: 0 0 8rem 0;
          border: none;
          border-bottom: 1rem solid var(--input-border-normal);
          background: rgba(255, 255, 255, 0);
          font-size: 18rem;
          line-height: 24rem;
          color: rgba(0, 0, 0, 1);

          &::placeholder {
            padding-top: 12rem;
            font-size: 14rem;
            line-height: 16rem;
            color: rgba(0, 0, 0, 0.5);
          }

          &.invalid {
            border-color: var(--input-border-error);
          }

          &:-webkit-autofill,
          &:-webkit-autofill:hover,
          &:-webkit-autofill:focus,
          &:-webkit-autofill:active {
            -webkit-box-shadow: 0 0 0 30rem white inset !important;
          }
        }

        .ql-action {
          justify-self: center;
          display: flex;
          width: 220rem;
          height: 40rem;
          margin-top: 30rem;
          justify-content: center;
          align-items: center;
          border: none;
          background-color: var(--button-blue-normal);

          font-size: 14rem;
          font-weight: 600;
          line-height: 16rem;
          color: rgba(255, 255, 255, 1);
          text-decoration: none;
          cursor: pointer;

          &:hover {
            background-color: var(--button-blue-hover);
          }

          &:active {
            background-color: var(--button-blue-pressed);
          }

          &:disabled {
            background-color: var(--button-blue-disabled);
          }

          &::after {
            content: 'Сохранить';
          }
        }

        &::before {
          position: absolute;
          top: 10rem;
          left: 0;
          content: '';
        }
      }

      // * удалить ссылку
      .ql-tooltip {
        border: 1px solid #dedede;
        box-shadow: 1px 3px 7px rgba(0, 0, 0, 0.2);
        a {
          color: #00aeef;
        }
      }
    }
  }

  .inputError {
    margin-bottom: 0 !important;
  }
}
