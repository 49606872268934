.templateStage4Form {
  form {
    position: relative;
    display: grid;
    width: 100%;
    row-gap: 20rem;

    // * text & description
    > *:nth-child(2),
    > *:nth-child(3) {
      width: 1210rem;
      justify-self: flex-start;
    }

    // * question
    > *:nth-child(4) {
      width: 100%;
    }

    .questionTypeFieldWrapper {
      position: absolute;
      right: 0;
      top: -88rem;
      display: flex;
      justify-content: flex-start;
      width: 460rem;

      .quertionTypeButton {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 230rem;
        height: 40rem;
        font-size: 14rem;
        line-height: 16rem;
        background-color: #f9f9f9;
        cursor: pointer;

        &:hover,
        &.active {
          background-color: #252424;
          color: #fff;
        }

        &.active {
          cursor: default;
        }
      }
    }

    .indicatorsFieldsWrapper {
      display: grid;
      grid-template-columns: max-content max-content;
      row-gap: 45rem;
      column-gap: 45rem;
      align-items: flex-start;
      margin-bottom: 30rem;

      .subTitle {
        grid-column: span 2;
        margin-bottom: -20rem;
      }

      .indicator {
        position: relative;
        grid-column: span 1;
        display: grid;
        grid-template-columns: 500rem 50rem;
        column-gap: 15rem;
        width: 565rem;

        form,
        .indicatorWrapper {
          display: grid;
          row-gap: 12rem;

          .inputError.success {
            margin-top: 0;
          }
        }

        .addRemoveButtons {
          grid-column: span 1;
          align-content: flex-start;
          padding-top: 0;

          .whiteButton {
            border: 1px solid var(--button-blue-normal);
          }

          img {
            margin: 0;

            &.iconRemove {
              width: 25rem;
              height: 25rem;
            }

            &.iconAdd {
              width: 15rem;
              height: 15rem;
            }
          }

          > button:nth-of-type(2) {
            position: absolute;
            top: 0;
            right: -80rem;
          }
        }
      }
    }

    .helpFieldsContainer {
      position: relative;
      display: grid;
      grid-template-columns: 1fr 1fr;
      column-gap: 60rem;

      &:after {
        content: '';
        position: absolute;
        left: 690rem;
        top: -5rem;
        width: 1px;
        height: calc(100% + 20rem);
        background-color: rgba($color: #000000, $alpha: 0.1);
      }

      .right {
        display: grid;
        align-self: flex-start;
        justify-items: flex-start;
        row-gap: 35rem;
      }
    }

    .fileFieldContainer {
      position: relative;
      display: grid;
      margin-top: 25rem;
      padding: 35rem 0 30rem;
      border-bottom: 1px solid rgba($color: #000000, $alpha: 0.1);
      border-top: 1px solid rgba($color: #000000, $alpha: 0.1);

      .fileFieldInfo {
        position: absolute;
        top: 45rem;
        left: 0;
        max-width: 500rem;
        overflow-x: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;

        font-size: 14rem;
        line-height: 16rem;
        color: rgba($color: #000000, $alpha: 0.5);
      }

      input[type='file'] {
        display: none;
      }

      button {
        label {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 100%;
          height: 100%;
          cursor: pointer;
        }
      }
    }

    .inputError {
      justify-self: center;
      margin-top: 25rem;
      margin-bottom: -10rem;
    }

    .subquetionFieldsWrapper {
      position: relative;
      margin-bottom: 35rem;
      padding-bottom: 30rem;
      border-bottom: 1rem solid rgba(0, 0, 0, 0.1);
      justify-items: flex-start;
      justify-content: flex-start;

      &:nth-child(5) {
        margin-top: 30rem;
      }

      .textareaWYSIWYG {
        .placeholder {
          // color: #000;
          font-weight: bold;
        }
      }

      .subquestionTypeField {
        position: absolute;
        right: 0;
        top: 30rem;
        display: flex;
        justify-content: flex-start;
        width: 460rem;
        z-index: 20;

        .subquestionTypeButton {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 230rem;
          height: 40rem;
          font-size: 14rem;
          line-height: 16rem;
          background-color: #f9f9f9;
          cursor: pointer;

          &:hover,
          &.active {
            background-color: #252424;
            color: #fff;
          }

          &.active {
            cursor: default;
          }
        }
      }

      > *:first-child {
        margin-bottom: 55rem;
      }

      .variantWeightField {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        margin-bottom: 55rem;

        &.marginTop {
          margin-top: 32.5em;
        }

        // .variantWeightFieldLabel {
        //   grid-column: span 7;
        //   font-size: 14rem;
        //   line-height: 16rem;
        //   text-transform: uppercase;
        //   margin-bottom: 25rem;
        // }

        .variantWeightFieldButton {
          grid-column: span 1;
          display: flex;
          justify-content: center;
          align-items: center;
          height: 40rem;
          font-size: 14rem;
          line-height: 16rem;
          font-weight: 600;
          background-color: rgba(0, 174, 239, 0.1);
          cursor: pointer;

          &:hover,
          &.active {
            background-color: rgba(0, 174, 239, 1);
            color: #fff;
          }

          &.active {
            cursor: default;
          }
        }

        .addRemoveButtons {
          grid-column: span 7;
          margin-top: 10rem;
          display: flex;
          justify-content: flex-start;

          > button {
            margin-right: 30rem;
          }

          &.-mt-40 {
            margin-top: -40rem;
          }
        }
      }

      .addRemoveButtons {
        display: flex;
        justify-content: center;

        > button {
          margin-right: 10rem;
        }
      }
    }
  }

  .next {
    justify-self: center;
    margin-top: 20rem;
  }
}
