.memberIntro {
  display: grid;
  padding: 80rem 30rem 50rem;
  background: rgba(255, 255, 255, 1);

  .title {
    margin-bottom: 30rem;
  }

  .subTitle {
    font-size: 24rem;
    line-height: 48rem;
    color: rgba(0, 0, 0, 1);

    small {
      display: block;
      margin-top: 30rem;
      font-size: 18rem;
      line-height: 30rem;
    }
  }

  .introBlocksWrapper {
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin-top: 45rem;
    // margin-bottom: 90rem;
    margin-bottom: 60rem;

    &.introBlocksWrapper--customIntro {
      grid-template-columns: 1fr;
      max-width: 90%;
    }

    .introBlock {
      grid-column: span 1;

      &:nth-of-type(2n-1) {
        padding-right: 50rem;
        border-right: 1px solid rgba(0, 0, 0, 0.1);
      }

      &:nth-of-type(2n) {
        padding-left: 50rem;
        padding-right: 30rem;
      }

      &:nth-of-type(1),
      &:nth-of-type(2) {
        padding-top: 30rem;
        padding-bottom: 30rem;
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
      }

      &:nth-of-type(3),
      &:nth-of-type(4) {
        padding-top: 40rem;
        padding-bottom: 30rem;
      }

      .blockTitle {
        font-size: 24rem;
        font-weight: 600;
        line-height: 28rem;
        margin-bottom: 25rem;
      }

      .blockDescription {
        font-size: 18rem;
        line-height: 30rem;

        ol {
          display: grid;
          row-gap: 30rem;
          padding-left: 0;
          list-style: none;

          &.liDash {
            row-gap: 25rem;

            li {
              grid-template-columns: 50rem auto;
            }
          }

          li {
            display: grid;
            grid-template-columns: 40rem auto;

            .liNum {
              grid-column: 1;
              font-weight: 600;
            }
            .liText {
              grid-column: 2;
            }
          }
        }
      }
    }
  }

  // .introFooterText {
  //   margin-bottom: 60rem;
  //   font-size: 18rem;
  //   line-height: 30rem;
  //   color: rgba(0, 0, 0, 1);
  // }
}
