.adminAssignedInterviewModal {
  width: auto !important;
  padding: 37.5rem !important;

  .title {
    width: 100%;
    margin-bottom: 35rem;
    padding-right: 100rem;

    font-size: 36rem;
    line-height: 36rem;
    text-align: left;
  }

  .text {
    margin-bottom: 15rem;

    font-size: 18rem;
    line-height: 30rem;
  }

  .copyToClipboardContainer {
    display: grid;
    grid-template-columns: max-content max-content max-content max-content;
    row-gap: 10rem;
    column-gap: 10rem;
    align-items: center;

    .copyToClipboardLink,
    .copyToClipboardText {
      max-width: 500rem;
      text-align: left;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      font-size: 18rem;
      line-height: 30rem;
      color: #000;

      &.copyToClipboardLink {
        color: var(--link-normal);

        &:hover {
          color: var(--link-hover);
        }
      }
    }

    .toClipboardIcon {
      width: 14rem;
      height: 16rem;
      cursor: pointer;
    }

    .copiedToClipboard {
      font-size: 10rem;
      line-height: 16rem;
      color: var(--link-normal);
    }
  }

  button {
    margin: 0 auto 5rem;
  }
}
