.addClientForExpertModal,
.removeClientForExpertModal {
  width: 900rem !important;
  padding-bottom: 60rem;

  .title {
    margin-bottom: 40rem;
  }

  .subTitle {
    margin-bottom: 25rem;
    font-size: 18rem;
    line-height: 30rem;
    color: rgba($color: #000000, $alpha: 1);
  }

  .clientsList {
    display: flex;
    flex-direction: column;
    margin-bottom: 40rem;

    .client {
      margin-bottom: 10rem;
      font-size: 14rem;
      line-height: 16rem;
      color: rgba($color: #000000, $alpha: 1);
      user-select: none;
    }
  }

  .empty {
    font-size: 14rem;
    line-height: 16rem;
    color: rgba($color: #000000, $alpha: 0.7);

    &.withMarginBottom {
      margin-bottom: 40rem;
    }
  }

  .reactSelect {
    width: 100%;
  }

  button {
    margin: 40rem auto 0;
  }
}
