@font-face {
  font-display: fallback;
  font-family: 'Open Sans';
  src: local('Open Sans'), local('OpenSans'), url('opensans.woff2') format('woff2'), url('opensans.woff') format('woff');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-display: fallback;
  font-family: 'Open Sans';
  src: local('Open Sans Semibold'), local('OpenSans-Semibold'), url('opensanssemibold.woff2') format('woff2'),
    url('opensanssemibold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
}

/* @font-face {
  font-family: 'Open Sans';
  src: local('Open Sans Italic'), local('OpenSans-Italic'), url('opensansitalic.woff2') format('woff2'),
    url('opensansitalic.woff') format('woff'), url('opensansitalic.ttf') format('truetype');
  font-weight: 400;
  font-style: italic;
}
@font-face {
  font-family: 'Open Sans';
  src: local('Open Sans Semibold Italic'), local('OpenSans-SemiboldItalic'), url('opensanssemibolditalic.woff2') format('woff2'),
    url('opensanssemibolditalic.woff') format('woff'), url('opensanssemibolditalic.ttf') format('truetype');
  font-weight: 600;
  font-style: italic;
} */
/* @font-face {
  font-family: 'Open Sans';
  src: local('Open Sans Bold'), local('OpenSans-Bold'), url('opensansbold.woff2') format('woff2'), url('opensansbold.woff') format('woff'),
    url('opensansbold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: 'Open Sans';
  src: local('Open Sans Bold Italic'), local('OpenSans-BoldItalic'), url('opensansbolditalic.woff2') format('woff2'),
    url('opensansbolditalic.woff') format('woff'), url('opensansbolditalic.ttf') format('truetype');
  font-weight: 700;
  font-style: italic;
} */
