@import '../MemberAnketaHeader/MemberAnketaHeader.scss';

.expertAnketaHeader {
  position: relative;

  .link {
    position: absolute;
    left: 30rem;
    bottom: 90rem;
    text-transform: uppercase;
    z-index: 10;
  }

  .info {
    .keys,
    .values {
      grid-template-columns: 235rem 230rem 185rem 185rem !important;

      &.values {
        align-items: flex-start;
      }

      .value {
        display: flex;
        align-items: flex-start;

        img {
          width: 24rem;
          height: 24rem;
          border-radius: 50%;
          margin-right: 10rem;
          margin-top: -5rem;
        }
      }
    }
  }
}
