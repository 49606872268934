.scheduleTable {
  position: relative;
  padding: 0 30rem;

  .daysTable {
    display: grid;
    grid-template-columns: repeat(7, 197rem);

    .day {
      margin-bottom: 15rem;
      color: rgba(0, 0, 0, 1);

      &.disabled {
        color: rgba(0, 0, 0, 0.5);
      }

      .dayValue {
        font-size: 30rem;
        line-height: 35rem;
      }

      .dayName {
        font-size: 14rem;
        line-height: 16rem;
        text-transform: lowercase;
        color: rgba(0, 0, 0, 0.5);
      }
    }
  }

  .timeTable {
    display: grid;
    grid-template-columns: repeat(7, 197rem);
    grid-template-rows: repeat(13, 50rem);
    grid-auto-flow: column;

    &.adminExpertsTimeTable .time {
      position: relative;
      padding-left: 0;
      padding-right: 0;

      .clickWrapper {
        display: flex;
        width: 100%;
        height: 100%;
        justify-content: space-between;
        align-items: center;
        padding-left: 20rem;
        padding-right: 10rem;
        cursor: pointer;
      }

      .timeSlotActions {
        position: absolute;
        z-index: 60;
        top: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 250rem;
        padding: 7rem 10rem;
        background: #ffffff;
        border: 1px solid #dedede;
        box-shadow: 1px 3px 7px rgba(0, 0, 0, 0.2);

        .closeIcon {
          position: absolute;
          z-index: 70;
          top: 12rem;
          width: 30rem;
          height: 30rem;
          padding: 10rem;
          background: url(../../assets/images/icon_closeGray.svg) center no-repeat;
          cursor: pointer;
        }

        &.right {
          right: -1rem;

          .closeIcon {
            left: 5rem;
          }

          button {
            justify-content: flex-end;
          }
        }

        &.left {
          .closeIcon {
            left: unset;
            right: 5rem;
          }
        }

        button {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          width: 100%;
          height: 39rem;
          padding: 0;
          padding-left: 10rem;
          margin: 0;
          border: 0;
          border-bottom: 1px solid rgba(0, 0, 0, 0.1);
          background-color: rgba(255, 255, 255, 0);
          cursor: pointer;

          &:last-of-type {
            border-bottom: none;
          }
        }
      }

      &.busy {
        padding-left: 0;
        padding-right: 0;

        .clickWrapper {
          background-color: #252424;
          color: rgba(255, 255, 255, 1);

          .clientInfo {
            display: flex;
            align-items: center;

            .clientLogo {
              margin-left: 10rem;
              max-width: 24rem;
              max-height: 24rem;
            }
          }
        }

        .timeSlotActions {
          width: 370rem;

          .closeIcon {
            top: 7.5rem;
          }
        }
      }
    }

    .time {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      width: 197rem;
      height: 50rem;
      padding-left: 20rem;
      border: 1px solid #e5e1e1;
      border-right: 0;
      border-bottom: 0;

      font-size: 12rem;
      line-height: 14rem;
      color: rgba(0, 0, 0, 0.8);

      cursor: pointer;

      &:nth-of-type(91),
      &:nth-of-type(90),
      &:nth-of-type(89),
      &:nth-of-type(88),
      &:nth-of-type(87),
      &:nth-of-type(86),
      &:nth-of-type(85),
      &:nth-of-type(84),
      &:nth-of-type(83),
      &:nth-of-type(82),
      &:nth-of-type(81),
      &:nth-of-type(80),
      &:nth-of-type(79) {
        border-right: 1px solid #e5e1e1;
      }

      &:nth-of-type(13n) {
        border-bottom: 1px solid #e5e1e1;
      }
    }

    &.memberTimeTable {
      .time {
        &.open {
          cursor: pointer;

          &:hover {
            background-color: var(--button-blue-hover);
            color: rgba(255, 255, 255, 1);
          }
        }

        &.open.unselectable {
          cursor: default;

          &:hover {
            background-color: unset;
            color: unset;
          }
        }

        &.disabled {
          background-color: #f9f9f9;
          color: rgba(0, 0, 0, 0.4);

          cursor: not-allowed;
        }

        &.clientSelected,
        &.busy {
          background-color: rgba(37, 36, 36, 1);
          color: rgba(255, 255, 255, 0.8);

          cursor: default;
        }

        &.clientUnSelect {
          background-color: #ef004a;
          color: rgba(255, 255, 255, 0.8);

          cursor: default;
        }
      }
    }

    &.expertTimeTable {
      .time {
        justify-content: space-between;
        padding-right: 20rem;

        &.free {
          background-color: rgba(255, 255, 255, 0);
          color: rgba(0, 0, 0, 0.8);

          cursor: copy;

          &:hover {
            background-color: var(--button-black-hover);
            color: rgba(255, 255, 255, 0.8);
          }
        }

        &.open {
          background-color: var(--button-black-normal);
          color: rgba(255, 255, 255, 0.8);

          &:hover {
            background-color: var(--button-black-hover);
          }

          cursor: no-drop;
        }

        &.inteviewScheduled {
          background-color: var(--button-blue-normal);
          color: rgba(255, 255, 255, 1);
        }

        &.interviewMissed {
          background-color: rgba(239, 0, 74, 1);
          color: rgba(255, 255, 255, 0.8);
        }

        &.disabled {
          background-color: #f9f9f9;
          color: rgba(0, 0, 0, 0.4);

          cursor: not-allowed;
        }

        .titmeValue {
          padding-left: 5rem;
        }
      }
    }
  }

  .timeEventPopup {
    position: absolute;
    left: calc(50% - 185rem);
    top: 200rem;
    display: grid;
    grid-template-columns: 1fr 1fr;
    width: 370rem;
    padding: 25rem 35rem 35rem;
    border: 1px solid #dedede;
    box-shadow: 1px 3px 7px rgba(0, 0, 0, 0.2);
    background-color: #fff;

    .close {
      position: absolute;
      top: 10rem;
      right: 10rem;
      width: 10rem;
      height: 10rem;
      background: url(../../assets/images/icon_closeGray.svg) center no-repeat;
      background-size: contain;

      cursor: pointer;
    }

    .title {
      grid-column: span 2;
      margin: 0 0 17rem;
      padding: 0;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;

      font-size: 18rem;
      line-height: 21rem;
      color: rgba(0, 0, 0, 1);
    }

    .time,
    .callType {
      grid-column: span 1;
      margin-bottom: 14rem;

      font-size: 14rem;
      line-height: 16rem;
      color: rgba(0, 0, 0, 1);

      &.callType {
        text-align: right;
      }
    }

    .button {
      grid-column: span 2;
      margin: 0;
    }

    .anketaTypeInfo {
      grid-column: span 2;
      display: grid;
      grid-template-columns: 90rem auto;
      row-gap: 10rem;
      align-items: center;
      margin: 21rem 0;

      .fieldTitle {
        grid-column: span 1;

        font-size: 12rem;
        line-height: 24rem;
        color: rgba(0, 0, 0, 0.5);
      }

      .fieldValue {
        grid-column: span 1;
        display: flex;
        align-items: center;
        font-size: 14rem;
        line-height: 24rem;
        color: rgba(0, 0, 0, 1);

        .clientLogo {
          width: 24rem;
          height: 24rem;
          border-radius: 50%;
          margin-left: 10rem;
        }
      }
    }

    .callLink {
      grid-column: span 2;
      height: 24rem;
      margin-bottom: 24rem;
      padding: 0;
      background: unset;
      border: unset;
      width: auto;

      font-size: 14rem;
      line-height: 16rem;
      text-decoration: none;
      text-align: left;

      &.zoom {
        color: var(--link-normal);

        cursor: pointer;

        &:hover {
          color: var(--link-hover);
        }
      }

      &.skype {
        color: rgba(0, 0, 0, 1);
      }
    }
  }
}
