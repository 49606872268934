.assignInterviewModal {
  display: grid;
  grid-template-columns: 400rem 30rem 400rem;
  align-items: flex-end;
  column-gap: 35rem;
  row-gap: 70rem;

  .title {
    grid-column: span 3;
    margin-bottom: 0;
    padding-left: 0;
  }

  .skypeWrapper {
    input {
      width: 300px;
    }
  }

  .vcFieldWrapper {
    position: relative;
    grid-column: span 2;
    padding-left: 35rem;
  }

  .skypeFieldWrapper {
    position: relative;
    grid-column: span 2;
    padding-left: 35rem;

    &:before {
      content: '';
      position: absolute;
      left: 0;
      bottom: 24rem;
      width: 24rem;
      height: 24rem;
      background: url(../../../assets/images/icon_skype.svg) center no-repeat;
      background-size: contain;
    }
  }

  .separationText {
    grid-column: span 1;
    padding-bottom: 16rem;

    font-size: 14rem;
    line-height: 16rem;
    color: rgba(0, 0, 0, 0.5);
  }

  .zoomFieldWrapper {
    position: relative;
    padding-left: 35rem;
    padding-bottom: 25rem;

    &:before {
      content: '';
      position: absolute;
      left: 0;
      bottom: 21rem;
      width: 24rem;
      height: 24rem;
      background: url(../../../assets/images/icon_zoom.svg) center no-repeat;
      background-size: contain;
    }

    button {
      background: unset;
      border: unset;
      padding: unset;
      cursor: pointer;
    }
  }

  > button {
    grid-column: span 3;
    margin-top: 0 !important;
  }
}
