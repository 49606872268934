@import '../../components/Button/Button.scss';

.profile {
  display: grid;
  grid-template-columns: 460rem 460rem 230rem auto;
  column-gap: 60rem;
  padding: 85rem 30rem;

  .title {
    grid-column: span 4;
    margin-bottom: 60rem;
  }

  .fieldset {
    &.firstName {
      grid-column: 1;
      grid-row: 2;
      margin-bottom: 30rem;
    }

    &.lastName {
      grid-column: 2;
      grid-row: 2;
      margin-bottom: 30rem;
    }

    &.email {
      grid-column: 1;
      grid-row: 3;
      margin-bottom: 65rem;
    }

    display: flex;
    flex-direction: column;

    label {
      margin-bottom: 10rem;
      font-size: 14rem;
      line-height: 16rem;
      color: rgba(0, 0, 0, 0.5);
    }
  }

  input[type='text'],
  input[type='email'] {
    height: 39rem;
    padding-bottom: 8rem;
    margin-bottom: 8rem;
    border: none;
    border-bottom: 1rem solid var(--input-border-normal);
    background: rgba(255, 255, 255, 0);
    font-size: 24rem;
    line-height: 28rem;
    color: rgba(0, 0, 0, 1);

    &::placeholder {
      padding-top: 12rem;
      font-size: 14rem;
      line-height: 16rem;
      color: rgba(0, 0, 0, 0.5);
    }

    &.invalid {
      border-color: var(--input-border-error);
    }

    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus,
    &:-webkit-autofill:active {
      -webkit-box-shadow: 0 0 0 30rem white inset !important;
    }
  }

  .tgButton {
    position: relative;
    grid-column: 1;
    grid-row: 4;
    justify-self: flex-start;
    width: 390rem;

    font-size: 14rem;
    font-weight: 600;
    line-height: 16rem;

    &:before {
      content: '';
      position: absolute;
      left: 12rem;
      top: 16rem;
      width: 20rem;
      height: 19rem;
      background: url(../../assets/images/icon_tg.svg) center no-repeat;
      background-size: contain;
    }
  }

  .hint {
    grid-column: 1;
    grid-row: 5;
    margin-top: 10rem;

    font-size: 14rem;
    line-height: 16rem;
    color: rgba(37, 36, 36, 0.5);
  }

  .passwordUpdateForm {
    grid-column: span 4;

    form {
      display: grid;
      grid-template-columns: 460rem 460rem 230rem auto;
      column-gap: 60rem;

      .input {
        grid-column: span 1;
      }

      .button {
        margin-top: 8rem;
      }

      > .inputError {
        grid-column: span 2;
        text-align: left;
        grid-row: 2;
        grid-column-start: 3;
        grid-column-end: 5;
        margin-top: -12rem;
      }
    }
  }
}
