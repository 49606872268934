.adminAnketaEvaluation {
  display: grid;
  grid-template-columns: 1030rem 410rem;

  .expertAnketaHeader {
    grid-column: span 2;
    margin-bottom: 50rem;
  }

  .anketaQuestionAnswerWrapper {
    grid-column: 1;
    border-right: 1px solid rgba(0, 0, 0, 0.1);

    &:nth-of-type(1) {
      > .title {
        height: 90rem;
      }
    }

    > .title {
      display: flex;
      align-items: flex-end;
      height: 155rem;
      padding: 25rem 30rem;
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);
      color: rgba(0, 0, 0, 0.5);
    }

    .expertAnketaAnswerSelect {
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    }

    .anketaQuestion {
      .showCaseDescriptionButton {
        font-size: 0;
      }
    }
  }

  .anketaQuestionRateAdmin {
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    padding-top: 55rem;
    padding-left: 30rem;
    padding-right: 30rem;
    padding-bottom: 30rem;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);

    .subTitle {
      margin-bottom: 25rem;

      font-size: 14rem;
      line-height: 16rem;
      text-transform: uppercase;
      color: rgba(0, 0, 0, 0.5);
    }

    > .subTitle:nth-of-type(3) {
      margin-top: 40rem;
      margin-bottom: 17.5rem;
    }

    .rate {
      display: grid;
      grid-template-columns: repeat(4, 1fr);

      .rateValue {
        grid-column: span 1;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 40rem;
        background-color: rgba(0, 174, 239, 0.1);

        font-size: 18rem;
        line-height: 21rem;
        color: rgba(0, 0, 0, 1);

        &.active {
          background-color: rgba(0, 174, 239, 1);
          color: rgba(255, 255, 255, 1);
        }
      }
    }
  }

  .anketaQuestionsList {
    grid-column: 2;
    grid-row: 2/100;
    padding: 30rem;
  }

  .anketaExpertComment {
    margin-top: 60rem;
  }
}
