.error500 {
  .formContainer {
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding-right: 90rem;

    .text {
      margin-top: 40rem;
      font-size: 18rem;
      line-height: 30rem;
    }
  }
}
