.anketaTableOfContents {
  display: grid;
  padding: 0 300rem 0 240rem;

  .title {
    padding-left: 30rem;
    margin-bottom: 40rem;
  }

  ol {
    padding-left: 55rem;
    margin-bottom: 30rem;

    li {
      position: relative;
      padding-bottom: 17rem;
      margin-bottom: 11rem;

      font-size: 18rem;
      line-height: 30rem;

      &:after {
        content: '';
        position: absolute;
        width: 900rem;
        height: 1px;
        background-color: rgba(0, 0, 0, 0.1);
        left: -60rem;
        bottom: 0;
      }

      .link {
        font-size: 18rem;
        line-height: 30rem;
      }
    }
  }
}
