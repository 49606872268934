.radiobutton {
  input[type='radio'] {
    display: none;
  }

  .radiobuttonVisibleLabel {
    display: grid;
    align-items: flex-start;
    grid-template-columns: 16rem auto;
    column-gap: 10rem;
    user-select: none;
    cursor: pointer;

    .radiobuttonIcon {
      position: relative;
      width: 16rem;
      height: 16rem;
      margin-top: 2rem;
      border: 1px solid rgba($color: #b6b6b6, $alpha: 1);
      border-radius: 50%;

      &.checked {
        &::before {
          content: '';
          position: absolute;
          bottom: 2rem;
          left: 2rem;
          background-color: var(--button-blue-normal);
          width: 10rem;
          height: 10rem;
          border-radius: 50%;
        }
      }
    }

    .radiobuttonText {
      font-size: 18rem;
      line-height: 21rem;
    }
  }
}
