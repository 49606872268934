.adminClientTemplates {
  padding: 40rem 30rem 50rem;

  .buttonsContainer {
    display: flex;
    justify-content: flex-end;

    .addCopiedAnketaButton,
    .addNewAnketaButton {
      margin-bottom: 45rem;
      margin-left: 20rem;

      > img {
        width: 15rem;
        height: 15rem;
      }
    }
  }

  .anketaItem {
    margin-bottom: 35rem;

    .anketaItemHeader {
      display: grid;
      grid-template-columns: max-content auto max-content max-content max-content;
      padding-bottom: 20rem;
      border-bottom: 1px solid rgba($color: #000000, $alpha: 0.1);
      margin-bottom: 20rem;

      .title {
        grid-column: 1;
        font-size: 24rem;
      }

      .recomendationsButton,
      .caseButton {
        padding: unset;
        margin: unset;
        border: unset;
        background-color: unset;
        justify-self: right;
        text-transform: uppercase;

        line-height: 36rem;
        cursor: pointer;

        &.recomendationsButton {
          grid-column: 3;
          margin-right: 40rem;
        }

        &.caseButton {
          grid-column: 4;
          margin-right: 20rem;
        }
      }

      .epxandAnketaButton {
        grid-column: 5;
        height: 30rem !important;
        background: url(../../assets/images/icon_expand.svg) center 5rem no-repeat;
        background-size: 16rem 16rem;
        background-color: var(--button-blue-normal);
        cursor: pointer;

        &.expanded {
          transform: rotate(180deg);
        }
      }
    }

    .anketaItemBody {
      display: grid;
      grid-template-columns: max-content auto max-content max-content;
      column-gap: 20rem;

      > a:nth-child(1) {
        grid-column: 1;
      }

      > a:nth-child(2),
      > button:nth-child(2) {
        grid-column: 3;
      }

      > a:nth-child(3) {
        grid-column: 4;
      }

      .templateQuestionsTable {
        grid-column: span 4;
      }
    }
  }
}
