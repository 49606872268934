.passwordRecoverForm,
.newPasswordForm {
  form {
    .hint {
      font-size: 14rem;
      line-height: 16rem;
      color: rgba($color: #000000, $alpha: 0.5);
      margin-top: 6rem;
    }

    .inputError {
      &.email,
      &.reenter_password {
        margin-bottom: 8rem;
      }
      &.submit {
        justify-self: center;
        text-align: center;
        color: var(--link-normal);
      }
    }
  }
}
