.сhangeTribeModal {
  width: 600rem !important;
  padding-bottom: 60rem;

  .title {
    margin-bottom: 60rem;
  }

  .subTitle {
    margin-bottom: 25rem;
    font-size: 18rem;
    line-height: 30rem;
    color: rgba($color: #000000, $alpha: 1);
  }

  .reactSelect {
    width: 100%;
  }

  button {
    margin: 50rem auto 0;
  }
}
