.recomendationResult {
    .comment {
        padding-top: 10rem;
        font-size: 18rem;
    }

    .answers {
        .answer {
            padding-top: 10rem;
            padding-left: 40rem;
            padding-right: 40rem;
            padding-bottom: 20rem;
            margin-bottom: 10rem;
            font-size: 16rem;
            border-bottom: 1px solid rgba(0, 0, 0, 0.1);
        }

        .answerTitle {
            position: relative;
            font-size: 19rem;
            font-weight: 700;
            line-height: 24rem;
            margin-bottom: 15rem;
            padding-top: 10rem;
        }

        .answerText {
            position: relative;
            font-size: 19rem;
            font-weight: 700;
            line-height: 24rem;
            margin-bottom: 15rem;
            padding-top: 15rem;
        }
        .questionText {
            position: relative;
            font-size: 22rem;
            font-weight: 700;
            line-height: 24rem;
            margin-bottom: 15rem;
            padding-top: 15rem;
        }

        .indicatorTitle {
            position: relative;
            font-size: 17rem;
            font-weight: 700;
            line-height: 15rem;
            margin-bottom: 5rem;
            padding-top: 10rem;
        }

        .indicatorPercent {
            position: relative;
            font-size: 17rem;
            font-weight: 700;
            line-height: 15rem;
            margin-bottom: 15rem;
            padding-top: 0rem;
        }

        .answerYourText {
            position: relative;
            font-size: 17rem;
            font-weight: 700;
            line-height: 24rem;
            margin-bottom: 10rem;
            padding-top: 20rem;
        }

        .expertComment {
            position: relative;
            font-size: 17rem;
            font-weight: 700;
            line-height: 24rem;
            margin-bottom: 10rem;
            padding-top: 10rem;
        }
    }
}
