.competentionsTable {
  display: grid;
  row-gap: 14rem;
  padding: 100rem 40rem 30rem;

  .subTitle {
    position: relative;
    justify-self: flex-end;
    margin-bottom: 70rem;
    padding-right: 17.5rem;

    font-size: 10rem;
    line-height: 14rem;
    color: rgba($color: #000000, $alpha: 0.5);

    &::after {
      content: '';
      position: absolute;
      left: -40rem;
      top: 7rem;
      width: 30rem;
      height: 2rem;
      background-color: #000;
    }
  }

  .competentions {
    position: relative;
    display: grid;
    row-gap: 20rem;

    .scoringBorder {
      position: absolute;
      top: -50rem;
      width: 4rem;
      height: calc(100% + 80rem);
      background-color: rgba($color: #000000, $alpha: 0.2);

      span {
        position: absolute;
        width: 150rem;
        left: 13rem;
        top: -2rem;
        font-size: 12rem;
        line-height: 14rem;
        color: rgba($color: #000000, $alpha: 0.5);
      }

      &:nth-child(1) {
        left: 360rem;
      }

      &:nth-child(2) {
        left: calc(360rem + (1260rem - 360rem) / 100 * 33 - 4rem);
      }

      &:nth-child(3) {
        left: calc(360rem + (1260rem - 360rem) / 100 * 66 - 4rem);
      }

      &:nth-child(4) {
        left: calc(360rem + (1260rem - 360rem) / 100 * 100);
      }
    }

    .competention {
      display: grid;
      grid-template-columns: 360rem 900rem;

      .key {
        grid-column: 1;
        align-self: center;
        font-size: 18rem;
        line-height: 24rem;
        padding-right: 20rem;
      }

      .valuesContainer {
        .streamOne,
        .streamTwo,
        .streamThree {
          position: relative;
          grid-column: 2;
          align-self: center;
          width: 100%;
          height: 30rem;
          background-color: #f2f2f2;

          .value {
            height: 30rem;
          }

          .valueRec {
            position: absolute;
            top: 0;
            width: 4rem;
            height: 30rem;
            background-color: #000;
          }
        }

        .streamTwo,
        .streamThree {
          margin-bottom: 20rem;
        }
      }
    }
  }

  &.twoStreams {
    .competentions {
      row-gap: 40rem;
    }
  }
}
