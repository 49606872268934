.adminTemplateCopy {
  padding: 40rem 30rem 50rem;

  .buttonsContainer {
    display: flex;
    justify-content: flex-end;

    .addCopiedAnketaButton,
    .addNewAnketaButton {
      margin-bottom: 45rem;
      margin-left: 20rem;

      > img {
        width: 15rem;
        height: 15rem;
      }
    }
  }

  .subTitle {
    width: 100%;
    margin-top: 45rem;
    margin-bottom: 60rem;
    padding-bottom: 25rem;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    font-size: 24rem;
    line-height: 36rem;
    font-weight: 600;
    color: rgba(0, 0, 0, 1);
  }

  .selectsContainer {
    display: flex;
    justify-content: space-between;
    margin-bottom: 50rem;

    .reactSelect,
    .inputSelect {
      width: 600rem;
    }
  }

  .findAnketasButton {
    margin: 0 auto;
  }

  .anketasList {
    .subTitle {
      margin-bottom: 0;
    }
    .anketa {
      padding: 25rem 0;
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);
      font-size: 24rem;
      line-height: 36rem;
      font-weight: 600;
      cursor: pointer;

      &:hover {
        color: var(--button-blue-hover);
      }

      &.active {
        color: var(--button-blue-pressed);
      }

      &:last-child {
        border-bottom: none;
      }
    }

    .copyAnketaButton {
      margin: 70rem auto;
    }
  }
}
