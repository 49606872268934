.anketaQuestionRateHelp {
  padding: 30rem 30rem 60rem;
  margin-top: -5rem;

  .helpSubTitle {
    font-size: 14rem;
    line-height: 16rem;
    color: rgba(0, 0, 0, 0.5);
    margin-bottom: 20rem;
  }
}
