.memberAnketaHeader,
.expertAnketaHeader {
  display: grid;
  grid-template-columns: 540rem auto;
  width: 100%;
  padding-top: 60rem;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);

  .title {
    position: relative;
    grid-column: 1;
    display: flex;
    align-items: flex-end;
    height: 110rem;
    padding: 0 30rem 35rem;
    border-right: 1px solid rgba(0, 0, 0, 0.1);
  }

  &.memberAnketaHeader--withTimer {
    grid-template-columns: 200rem 340rem auto;

    .timer {
      grid-column: 2;
      display: flex;
      flex-direction: column;
      justify-content: center;
      height: 110rem;
      padding: 0 25rem 5rem 40rem;

      background-color: #252424;

      .timerTitle {
        margin-bottom: 10rem;

        font-size: 10rem;
        line-height: 12rem;
        color: rgba(255, 255, 255, 0.5);
      }

      .timerTimeLeft {
        margin-bottom: 15rem;

        font-size: 16rem;
        line-height: 19rem;
        color: rgba(255, 255, 255, 1);

        b {
          font-weight: 500;
        }
      }

      .timerStatusBar {
        width: 100%;
        height: 10rem;
        background-color: rgba(255, 255, 255, 0.2);

        .timerStatusBar--Passed {
          height: 10rem;
          background-color: #00aeef;
        }
      }
    }
  }

  .info {
    grid-column: 3;
    display: grid;
    row-gap: 30rem;
    height: 110rem;
    padding: 7rem 30rem 20rem 40rem;

    .keys,
    .values {
      width: 100%;
      display: grid;
      // grid-template-columns: 400rem 270rem 160rem;
      grid-template-columns: 400rem 430rem;
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);

      &.values {
        border-bottom: none;
      }

      .key,
      .value {
        grid-column: span 1;
        padding-bottom: 10rem;
        padding-right: 20rem;

        font-size: 14rem;
        line-height: 16rem;

        &.key {
          color: rgba(0, 0, 0, 0.5);
        }

        &.value {
          color: rgba(0, 0, 0, 1);
        }
      }
    }
  }
}
