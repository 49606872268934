.anketaProgression {
  display: grid;
  grid-auto-flow: row;
  grid-template-columns: repeat(23, 30rem);
  column-gap: 30rem;
  row-gap: 30rem;
  width: 100%;
  padding: 40rem 30rem;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  background-color: #fff;

  &.is_expert {
    grid-template-columns: repeat(22, 30rem);
  }

  .progressionPoint {
    position: relative;
    grid-column: span 1;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30rem;
    height: 30rem;
    background-color: var(--button-blue-normal);
    border-radius: 50%;
    font-size: 14rem;
    font-weight: 600;
    line-height: 14rem;
    color: rgba(255, 255, 255, 1);
    text-decoration: none;
    user-select: none;

    &:before {
      content: '';
      position: absolute;
      width: 10rem;
      height: 1rem;
      background-color: rgba(242, 242, 242, 1);
      left: -20rem;
      top: 15rem;
    }

    &:nth-of-type(1):before,
    &:nth-of-type(24):before,
    &:nth-of-type(47):before {
      display: none;
    }

    &.active {
      background-color: rgba(0, 0, 0, 1);
    }

    &.answered {
      background-color: rgba(229, 225, 225, 1);
    }

    &.rated {
      &:after {
        content: '';
        position: absolute;
        left: -6rem;
        top: -6rem;
        width: 40rem;
        height: 40rem;
        border-radius: 50%;
        border: 1rem solid rgba(0, 174, 239, 0.4);
      }
    }

    &.hoverPossible {
      cursor: pointer;

      &:hover {
        background-color: var(--button-blue-hover);
        color: #fff;
      }

      &.active {
        &:hover {
          cursor: default;
          background-color: var(--button-black-normal);
        }
      }
    }
  }

  a {
    grid-column: span 1;
    width: 130rem;
    font-size: 14rem;
    line-height: 30rem;
    color: var(--link-normal);
    text-decoration: none;

    &:hover {
      color: var(--link-hover);
    }
  }
}
