.button {
  justify-self: center;
  display: flex;
  justify-content: center;
  align-items: center;
  color: rgba(255, 255, 255, 1);
  border: none;
  text-decoration: none;
  cursor: pointer;

  &.blue {
    background-color: var(--button-blue-normal);

    &:hover {
      background-color: var(--button-blue-hover);
    }

    &:active {
      background-color: var(--button-blue-pressed);
    }

    &:disabled {
      background-color: var(--button-blue-disabled);
    }
  }

  &.black {
    background-color: var(--button-black-normal);

    &:hover {
      background-color: var(--button-black-hover);
    }

    &:active {
      background-color: var(--button-black-pressed);
    }

    &:disabled {
      background-color: var(--button-black-disabled);
    }
  }

  &.gray {
    background-color: rgba(249, 249, 249, 1);
    color: rgba(0, 0, 0, 1);

    &:hover {
      background-color: var(--button-blue-hover);
      color: rgba(255, 255, 255, 1);
    }

    &:active {
      background-color: var(--button-blue-pressed);
      color: rgba(255, 255, 255, 1);
    }

    &:disabled {
      background-color: var(--button-blue-disabled);
      color: rgba(255, 255, 255, 1);
    }
  }

  &.white {
    background-color: #fff;
    color: var(--button-blue-normal);

    &:hover {
      background-color: #fff;
      color: var(--button-blue-hover);
    }

    &:active {
      background-color: #fff;
      color: var(--button-blue-active);
    }

    &:disabled {
      background-color: #fff;
      color: var(--button-blue-disabled);
    }
  }

  &.white--blackText {
    background-color: #fff;
    color: #000;

    &:hover {
      background-color: var(--button-blue-hover);
      color: #fff;
    }

    &:active {
      background-color: var(--button-blue-active);
      color: #fff;
    }

    &:disabled {
      background-color: var(--button-blue-disabled);
      color: #fff;
    }
  }

  &.red {
    background-color: #ef193a;
    color: #fff;

    &:hover {
      background-color: lighten($color: #ef193a, $amount: 10%);
    }

    &:disabled {
      background-color: lighten($color: #ef193a, $amount: 30%);
      color: #fff;
    }
  }

  &.large {
    height: 60rem;
    font-size: 14rem;
    font-weight: 600;
    line-height: 24rem;
    text-transform: uppercase;
  }

  &.medium {
    height: 50rem;
    font-size: 14rem;
    font-weight: 600;
    line-height: 16rem;
    text-transform: uppercase;
  }

  &.small {
    height: 40rem;
    font-size: 14rem;
    font-weight: 600;
    line-height: 16rem;
  }

  > img {
    margin-right: 12.5rem;
  }

  &:disabled {
    cursor: not-allowed;
  }
}
