.ReportCreationInitModal {
  width: 700rem !important;
  padding: 35rem 65rem 50rem !important;
  padding-bottom: 60rem;

  .title {
    margin-bottom: 60rem;
    text-align: center;
  }

  .subTitle {
    margin-bottom: 25rem;
    font-size: 18rem;
    line-height: 30rem;
    color: rgba($color: #000000, $alpha: 1);
    text-align: center;
  }

  button {
    margin: 50rem auto 0;
  }
}
