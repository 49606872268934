.anketaQuestion {
  display: grid;
  grid-template-columns: auto 135rem;
  width: 100%;
  max-width: 1440rem;
  padding: 35rem 30rem 30rem;
  background-color: #fff;

  .title {
    grid-column: 1;
    margin-bottom: 30rem;
  }

  .showCaseDescriptionButton {
    align-self: flex-start;
    grid-column: 2;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 12.5rem;
    margin-bottom: 30rem;
    border: 0;
    background: none;
    height: 20rem;

    font-size: 14rem;
    line-height: 16rem;
    text-transform: uppercase;
    color: var(--button-blue-normal);
    cursor: pointer;

    &:hover {
      color: var(--button-blue-hover);
    }
  }

  .containerWYSIWYG {
    grid-column: span 2;
  }

  video {
    grid-column: 1;
    margin: 30rem 0;
    width: 855rem;
    height: 480rem;
  }

  img {
    grid-column: 1;
    margin: 30rem 0;
    max-width: 100%;
    width: auto;
    height: auto;
  }
}
