.competentionsSpider {
  display: grid;
  grid-template-columns: auto max-content;
  position: relative;
  padding-top: 100rem;

  .title {
    grid-column: 1;
    grid-row: 1;
    padding: 0 40rem;
    line-height: 47.5rem;
    align-self: flex-start;

    .middle {
      font-size: 30rem;
    }

    small {
      font-size: 18rem;
      font-weight: 400;
      line-height: 20rem;
      color: rgba($color: #000000, $alpha: 0.5);

      b {
        color: rgba($color: #000000, $alpha: 1);
        font-weight: 600;
      }
    }
  }

  .memberCount {
    grid-column: 1;
    grid-row: 2;
    align-self: flex-end;
    margin: 15rem 0 125rem;
    padding: 0 40rem;

    font-size: 18rem;
    font-weight: 400;
    line-height: 40rem;
    color: rgba($color: #000000, $alpha: 0.5);

    span {
      color: rgba($color: #000000, $alpha: 1);
      font-weight: 600;
    }
  }

  .points {
    position: relative;
    z-index: 200;

    grid-column: 2;
    display: grid;
    grid-template-columns: max-content max-content;
    justify-content: space-between;
    align-content: center;
    align-self: flex-end;
    row-gap: 5rem;
    width: 430rem;
    height: 95rem;
    padding: 0 30rem;

    .pointsHeader {
      grid-column: span 1;

      font-size: 10rem;
      line-height: 12rem;
      color: rgba($color: #ffffff, $alpha: 1);

      &:nth-child(1) {
        justify-self: flex-start;
      }

      &:nth-child(2) {
        justify-self: flex-end;
      }
    }

    .pointsValue {
      grid-column: span 1;
      grid-row: 2;
      font-size: 12rem;
      line-height: 28rem;
      color: rgba($color: #ffffff, $alpha: 1);

      .value {
        font-size: 24rem;
      }

      &:nth-child(4) {
        justify-self: flex-end;

        .value {
          font-weight: 600;
        }
      }
    }
  }

  .svgContainer {
    grid-column: span 2;
    position: relative;
    width: 100%;
    height: 800rem;
    margin: 120rem auto 90rem;

    .svg {
      width: 800rem;
      height: 800rem;
      margin: 0 auto;

      svg {
        position: relative;
        overflow: visible;
      }
    }

    .svgText {
      .radarText {
        position: absolute;
        display: flex;
        z-index: 1000;
        width: auto;
        height: 45rem;

        font-size: 14rem;
        line-height: normal;
        color: rgba($color: #000000, $alpha: 1);
      }

      &.twoStreams {
        .radarText {
          display: block !important;
        }
      }

      &.items27 {
        .radarText {
          &:nth-of-type(1) {
            align-items: flex-end;
            justify-content: center;
            text-align: center;
            top: -35rem;
            width: 400rem;
            left: calc(50% - 200rem);
            padding: 0;
          }
          &:nth-of-type(2) {
            align-items: flex-end;
            top: 10rem;
            left: calc(50% + 80rem);
            text-align: left;
            padding-right: 10rem;
          }
          &:nth-of-type(3) {
            align-items: flex-end;
            top: 50rem;
            left: calc(50% + 165rem);
            text-align: left;
            padding-right: 10rem;
          }
          &:nth-of-type(4) {
            align-items: flex-end;
            top: 100rem;
            left: calc(50% + 230rem);
            text-align: left;
            padding-right: 10rem;
          }
          &:nth-of-type(5) {
            align-items: flex-end;
            top: 165rem;
            left: calc(50% + 290rem);
            text-align: left;
            padding-right: 10rem;
          }
          &:nth-of-type(6) {
            align-items: flex-end;
            top: 240rem;
            left: calc(50% + 330rem);
            text-align: left;
            padding-right: 10rem;
          }
          &:nth-of-type(7) {
            align-items: flex-start;
            top: 345rem;
            left: calc(50% + 350rem);
            text-align: left;
            padding-right: 10rem;
          }
          &:nth-of-type(8) {
            align-items: flex-start;
            top: 430rem;
            left: calc(50% + 345rem);
            text-align: left;
            padding-right: 10rem;
          }
          &:nth-of-type(9) {
            align-items: flex-start;
            top: 515rem;
            left: calc(50% + 320rem);
            text-align: left;
            padding-right: 10rem;
          }
          &:nth-of-type(10) {
            align-items: flex-start;
            top: 590rem;
            left: calc(50% + 285rem);
            text-align: left;
            padding-right: 10rem;
          }
          &:nth-of-type(11) {
            align-items: flex-start;
            top: 650rem;
            left: calc(50% + 235rem);
            text-align: left;
            padding-right: 10rem;
          }
          &:nth-of-type(12) {
            align-items: flex-start;
            top: 705rem;
            width: 400rem;
            left: calc(50% + 155rem);
            text-align: left;
            padding: 0;
          }
          &:nth-of-type(13) {
            align-items: flex-start;
            top: 740rem;
            left: calc(50% + 80rem);
            text-align: left;
            padding: 0;
          }
          &:nth-of-type(14) {
            align-items: flex-start;
            top: 780rem;
            right: calc(50% - 90rem);
            text-align: right;
            padding-left: 10rem;
          }
          &:nth-of-type(15) {
            align-items: flex-start;
            top: 745rem;
            right: calc(50% + 80rem);
            text-align: right;
            padding-left: 10rem;
          }
          &:nth-of-type(16) {
            align-items: flex-start;
            top: 700rem;
            right: calc(50% + 170rem);
            text-align: right;
            padding-left: 10rem;
          }
          &:nth-of-type(17) {
            align-items: flex-start;
            top: 650rem;
            right: calc(50% + 240rem);
            text-align: right;
            padding-left: 10rem;
          }
          &:nth-of-type(18) {
            align-items: flex-end;
            top: 560rem;
            right: calc(50% + 280rem);
            text-align: right;
            padding-left: 10rem;
          }
          &:nth-of-type(19) {
            align-items: flex-end;
            top: 480rem;
            right: calc(50% + 325rem);
            text-align: right;
            padding-left: 10rem;
          }
          &:nth-of-type(20) {
            align-items: flex-end;
            top: 400rem;
            right: calc(50% + 340rem);
            text-align: right;
            padding-left: 10rem;
          }
          &:nth-of-type(21) {
            align-items: flex-end;
            top: 320rem;
            right: calc(50% + 345rem);
            text-align: right;
            padding-left: 10rem;
          }
          &:nth-of-type(22) {
            align-items: flex-end;
            top: 240rem;
            right: calc(50% + 320rem);
            text-align: right;
            padding-left: 10rem;
          }
          &:nth-of-type(23) {
            align-items: flex-end;
            top: 170rem;
            right: calc(50% + 280rem);
            text-align: right;
            padding-left: 10rem;
          }
          &:nth-of-type(24) {
            align-items: flex-end;
            top: 110rem;
            right: calc(50% + 230rem);
            text-align: right;
            padding-left: 10rem;
          }
          &:nth-of-type(25) {
            align-items: flex-end;
            top: 60rem;
            right: calc(50% + 165rem);
            text-align: right;
            padding-left: 10rem;
          }
          &:nth-of-type(26) {
            align-items: flex-end;
            top: 1rem;
            right: calc(50% + 105rem);
            text-align: right;
            padding-left: 10rem;
          }
          &:nth-of-type(27) {
            align-items: flex-end;
            top: 1rem;
            right: calc(50% + 105rem);
            text-align: right;
            padding-left: 10rem;
          }
        }
      }

      &.items26 {
        .radarText {
          &:nth-of-type(1) {
            align-items: flex-end;
            justify-content: center;
            text-align: center;
            top: -35rem;
            width: 400rem;
            left: calc(50% - 200rem);
            padding: 0;
          }
          &:nth-of-type(2) {
            align-items: flex-end;
            top: 10rem;
            left: calc(50% + 80rem);
            text-align: left;
            padding-right: 10rem;
          }
          &:nth-of-type(3) {
            align-items: flex-end;
            top: 50rem;
            left: calc(50% + 165rem);
            text-align: left;
            padding-right: 10rem;
          }
          &:nth-of-type(4) {
            align-items: flex-end;
            top: 100rem;
            left: calc(50% + 230rem);
            text-align: left;
            padding-right: 10rem;
          }
          &:nth-of-type(5) {
            align-items: flex-end;
            top: 165rem;
            left: calc(50% + 290rem);
            text-align: left;
            padding-right: 10rem;
          }
          &:nth-of-type(6) {
            align-items: flex-end;
            top: 240rem;
            left: calc(50% + 330rem);
            text-align: left;
            padding-right: 10rem;
          }
          &:nth-of-type(7) {
            align-items: flex-start;
            top: 345rem;
            left: calc(50% + 350rem);
            text-align: left;
            padding-right: 10rem;
          }
          &:nth-of-type(8) {
            align-items: flex-start;
            top: 430rem;
            left: calc(50% + 345rem);
            text-align: left;
            padding-right: 10rem;
          }
          &:nth-of-type(9) {
            align-items: flex-start;
            top: 515rem;
            left: calc(50% + 320rem);
            text-align: left;
            padding-right: 10rem;
          }
          &:nth-of-type(10) {
            align-items: flex-start;
            top: 590rem;
            left: calc(50% + 285rem);
            text-align: left;
            padding-right: 10rem;
          }
          &:nth-of-type(11) {
            align-items: flex-start;
            top: 650rem;
            left: calc(50% + 235rem);
            text-align: left;
            padding-right: 10rem;
          }
          &:nth-of-type(12) {
            align-items: flex-start;
            top: 705rem;
            width: 400rem;
            left: calc(50% + 155rem);
            text-align: left;
            padding: 0;
          }
          &:nth-of-type(13) {
            align-items: flex-start;
            top: 740rem;
            left: calc(50% + 80rem);
            text-align: left;
            padding: 0;
          }
          &:nth-of-type(14) {
            align-items: flex-start;
            top: 780rem;
            right: calc(50% - 90rem);
            text-align: right;
            padding-left: 10rem;
          }
          &:nth-of-type(15) {
            align-items: flex-start;
            top: 745rem;
            right: calc(50% + 80rem);
            text-align: right;
            padding-left: 10rem;
          }
          &:nth-of-type(16) {
            align-items: flex-start;
            top: 700rem;
            right: calc(50% + 170rem);
            text-align: right;
            padding-left: 10rem;
          }
          &:nth-of-type(17) {
            align-items: flex-start;
            top: 650rem;
            right: calc(50% + 240rem);
            text-align: right;
            padding-left: 10rem;
          }
          &:nth-of-type(18) {
            align-items: flex-end;
            top: 560rem;
            right: calc(50% + 280rem);
            text-align: right;
            padding-left: 10rem;
          }
          &:nth-of-type(19) {
            align-items: flex-end;
            top: 480rem;
            right: calc(50% + 325rem);
            text-align: right;
            padding-left: 10rem;
          }
          &:nth-of-type(20) {
            align-items: flex-end;
            top: 400rem;
            right: calc(50% + 340rem);
            text-align: right;
            padding-left: 10rem;
          }
          &:nth-of-type(21) {
            align-items: flex-end;
            top: 320rem;
            right: calc(50% + 345rem);
            text-align: right;
            padding-left: 10rem;
          }
          &:nth-of-type(22) {
            align-items: flex-end;
            top: 240rem;
            right: calc(50% + 320rem);
            text-align: right;
            padding-left: 10rem;
          }
          &:nth-of-type(23) {
            align-items: flex-end;
            top: 170rem;
            right: calc(50% + 280rem);
            text-align: right;
            padding-left: 10rem;
          }
          &:nth-of-type(24) {
            align-items: flex-end;
            top: 110rem;
            right: calc(50% + 230rem);
            text-align: right;
            padding-left: 10rem;
          }
          &:nth-of-type(25) {
            align-items: flex-end;
            top: 60rem;
            right: calc(50% + 165rem);
            text-align: right;
            padding-left: 10rem;
          }
          &:nth-of-type(26) {
            align-items: flex-end;
            top: 1rem;
            right: calc(50% + 105rem);
            text-align: right;
            padding-left: 10rem;
          }
        }
      }

      &.items25 {
        .radarText {
          &:nth-of-type(1) {
            align-items: flex-end;
            justify-content: center;
            text-align: center;
            top: -35rem;
            width: 400rem;
            left: calc(50% - 200rem);
            padding: 0;
          }
          &:nth-of-type(2) {
            align-items: flex-end;
            top: 1rem;
            left: calc(50% + 90rem);
            text-align: left;
            padding-right: 10rem;
          }
          &:nth-of-type(3) {
            align-items: flex-end;
            top: 35rem;
            left: calc(50% + 185rem);
            text-align: left;
            padding-right: 10rem;
          }
          &:nth-of-type(4) {
            align-items: flex-end;
            top: 90rem;
            left: calc(50% + 260rem);
            text-align: left;
            padding-right: 10rem;
          }
          &:nth-of-type(5) {
            align-items: flex-end;
            top: 155rem;
            left: calc(50% + 315rem);
            text-align: left;
            padding-right: 10rem;
          }
          &:nth-of-type(6) {
            align-items: flex-end;
            top: 250rem;
            left: calc(50% + 355rem);
            text-align: left;
            padding-right: 10rem;
          }
          &:nth-of-type(7) {
            align-items: flex-start;
            top: 355rem;
            left: calc(50% + 370rem);
            text-align: left;
            padding-right: 10rem;
          }
          &:nth-of-type(8) {
            align-items: flex-start;
            top: 440rem;
            left: calc(50% + 365rem);
            text-align: left;
            padding-right: 10rem;
          }
          &:nth-of-type(9) {
            align-items: flex-start;
            top: 535rem;
            left: calc(50% + 340rem);
            text-align: left;
            padding-right: 10rem;
          }
          &:nth-of-type(10) {
            align-items: flex-start;
            top: 620rem;
            left: calc(50% + 305rem);
            text-align: left;
            padding-right: 10rem;
          }
          &:nth-of-type(11) {
            align-items: flex-start;
            top: 700rem;
            left: calc(50% + 235rem);
            text-align: left;
            padding-right: 10rem;
          }
          &:nth-of-type(12) {
            align-items: flex-start;
            top: 745rem;
            width: 400rem;
            left: calc(50% + 155rem);
            text-align: left;
            padding: 0;
          }
          &:nth-of-type(13) {
            align-items: flex-start;
            top: 790rem;
            left: calc(50% + 60rem);
            text-align: left;
            padding: 0;
          }
          &:nth-of-type(14) {
            align-items: flex-start;
            top: 790rem;
            right: calc(50% + 50rem);
            text-align: right;
            padding-left: 10rem;
          }
          &:nth-of-type(15) {
            align-items: flex-start;
            top: 745rem;
            right: calc(50% + 150rem);
            text-align: right;
            padding-left: 10rem;
          }
          &:nth-of-type(16) {
            align-items: flex-start;
            top: 700rem;
            right: calc(50% + 230rem);
            text-align: right;
            padding-left: 10rem;
          }
          &:nth-of-type(17) {
            align-items: flex-start;
            top: 630rem;
            right: calc(50% + 300rem);
            text-align: right;
            padding-left: 10rem;
          }
          &:nth-of-type(18) {
            align-items: flex-end;
            top: 535rem;
            right: calc(50% + 350rem);
            text-align: right;
            padding-left: 10rem;
          }
          &:nth-of-type(19) {
            align-items: flex-end;
            top: 440rem;
            right: calc(50% + 365rem);
            text-align: right;
            padding-left: 10rem;
          }
          &:nth-of-type(20) {
            align-items: flex-end;
            top: 340rem;
            right: calc(50% + 380rem);
            text-align: right;
            padding-left: 10rem;
          }
          &:nth-of-type(21) {
            align-items: flex-end;
            top: 250rem;
            right: calc(50% + 345rem);
            text-align: right;
            padding-left: 10rem;
          }
          &:nth-of-type(22) {
            align-items: flex-end;
            top: 165rem;
            right: calc(50% + 305rem);
            text-align: right;
            padding-left: 10rem;
          }
          &:nth-of-type(23) {
            align-items: flex-end;
            top: 90rem;
            right: calc(50% + 260rem);
            text-align: right;
            padding-left: 10rem;
          }
          &:nth-of-type(24) {
            align-items: flex-end;
            top: 35rem;
            right: calc(50% + 185rem);
            text-align: right;
            padding-left: 10rem;
          }
          &:nth-of-type(25) {
            align-items: flex-end;
            top: 1rem;
            right: calc(50% + 105rem);
            text-align: right;
            padding-left: 10rem;
          }
        }
      }

      &.items24 {
        .radarText {
          &:nth-of-type(1) {
            align-items: flex-end;
            justify-content: center;
            text-align: center;
            top: -45rem;
            width: 400rem;
            left: calc(50% - 200rem);
            padding: 0;
          }
          &:nth-of-type(2) {
            align-items: flex-end;
            top: -15rem;
            left: calc(50% + 90rem);
            text-align: left;
            padding-right: 10rem;
          }
          &:nth-of-type(3) {
            align-items: flex-end;
            top: 30rem;
            left: calc(50% + 185rem);
            text-align: left;
            padding-right: 10rem;
          }
          &:nth-of-type(4) {
            align-items: flex-end;
            top: 90rem;
            left: calc(50% + 260rem);
            text-align: left;
            padding-right: 10rem;
          }
          &:nth-of-type(5) {
            align-items: flex-end;
            top: 175rem;
            left: calc(50% + 325rem);
            text-align: left;
            padding-right: 10rem;
          }
          &:nth-of-type(6) {
            align-items: flex-end;
            top: 270rem;
            left: calc(50% + 360rem);
            text-align: left;
            padding-right: 10rem;
          }
          &:nth-of-type(7) {
            align-items: flex-start;
            top: 375rem;
            left: calc(50% + 370rem);
            text-align: left;
            padding-right: 10rem;
          }
          &:nth-of-type(8) {
            align-items: flex-start;
            top: 480rem;
            left: calc(50% + 365rem);
            text-align: left;
            padding-right: 10rem;
          }
          &:nth-of-type(9) {
            align-items: flex-start;
            top: 570rem;
            left: calc(50% + 330rem);
            text-align: left;
            padding-right: 10rem;
          }
          &:nth-of-type(10) {
            align-items: flex-start;
            top: 655rem;
            left: calc(50% + 280rem);
            text-align: left;
            padding-right: 10rem;
          }
          &:nth-of-type(11) {
            align-items: flex-start;
            top: 715rem;
            left: calc(50% + 200rem);
            text-align: left;
            padding-right: 10rem;
          }
          &:nth-of-type(12) {
            align-items: flex-start;
            justify-content: center;
            text-align: center;
            top: 755rem;
            width: 400rem;
            right: calc(50% - 475rem);
            padding: 0;
          }
          &:nth-of-type(13) {
            align-items: flex-start;
            top: 810rem;
            right: calc(50% + -270rem);
            text-align: center;
            padding-left: 10rem;
          }
          &:nth-of-type(14) {
            align-items: flex-start;
            top: 755rem;
            right: calc(50% + 100rem);
            text-align: right;
            padding-left: 10rem;
          }
          &:nth-of-type(15) {
            align-items: flex-start;
            top: 715rem;
            right: calc(50% + 200rem);
            text-align: right;
            padding-left: 10rem;
          }
          &:nth-of-type(16) {
            align-items: flex-start;
            top: 650rem;
            right: calc(50% + 270rem);
            text-align: right;
            padding-left: 10rem;
          }
          &:nth-of-type(17) {
            align-items: flex-start;
            top: 575rem;
            right: calc(50% + 330rem);
            text-align: right;
            padding-left: 10rem;
          }
          &:nth-of-type(18) {
            align-items: flex-end;
            top: 465rem;
            right: calc(50% + 360rem);
            text-align: right;
            padding-left: 10rem;
          }
          &:nth-of-type(19) {
            align-items: flex-end;
            top: 370rem;
            right: calc(50% + 375rem);
            text-align: right;
            padding-left: 10rem;
          }
          &:nth-of-type(20) {
            align-items: flex-end;
            top: 280rem;
            right: calc(50% + 360rem);
            text-align: right;
            padding-left: 10rem;
          }
          &:nth-of-type(21) {
            align-items: flex-end;
            top: 175rem;
            right: calc(50% + 315rem);
            text-align: right;
            padding-left: 10rem;
          }
          &:nth-of-type(22) {
            align-items: flex-end;
            top: 90rem;
            right: calc(50% + 255rem);
            text-align: right;
            padding-left: 10rem;
          }
          &:nth-of-type(23) {
            align-items: flex-end;
            top: 30rem;
            right: calc(50% + 170rem);
            text-align: right;
            padding-left: 10rem;
          }
          &:nth-of-type(24) {
            align-items: flex-end;
            top: -15rem;
            right: calc(50% + 105rem);
            text-align: right;
            padding-left: 10rem;
          }
        }
      }

      &.items23 {
        .radarText {
          &:nth-of-type(1) {
            align-items: flex-end;
            justify-content: center;
            text-align: center;
            top: -15rem;
            width: 400rem;
            left: calc(50% - 200rem);
            padding: 0;
          }
          &:nth-of-type(2) {
            align-items: flex-end;
            top: 15rem;
            left: calc(50% + 110rem);
            text-align: left;
            padding-right: 10rem;
          }
          &:nth-of-type(3) {
            align-items: flex-end;
            top: 55rem;
            left: calc(50% + 200rem);
            text-align: left;
            padding-right: 10rem;
          }
          &:nth-of-type(4) {
            align-items: flex-end;
            top: 110rem;
            left: calc(50% + 265rem);
            text-align: left;
            padding-right: 10rem;
          }
          &:nth-of-type(5) {
            align-items: flex-end;
            top: 195rem;
            left: calc(50% + 325rem);
            text-align: left;
            padding-right: 10rem;
          }
          &:nth-of-type(6) {
            align-items: flex-end;
            top: 290rem;
            left: calc(50% + 360rem);
            text-align: left;
            padding-right: 10rem;
          }
          &:nth-of-type(7) {
            align-items: flex-start;
            top: 420rem;
            left: calc(50% + 365rem);
            text-align: left;
            padding-right: 10rem;
          }
          &:nth-of-type(8) {
            align-items: flex-start;
            top: 520rem;
            left: calc(50% + 345rem);
            text-align: left;
            padding-right: 10rem;
          }
          &:nth-of-type(9) {
            align-items: flex-start;
            top: 605rem;
            left: calc(50% + 300rem);
            text-align: left;
            padding-right: 10rem;
          }
          &:nth-of-type(10) {
            align-items: flex-start;
            top: 675rem;
            left: calc(50% + 240rem);
            text-align: left;
            padding-right: 10rem;
          }
          &:nth-of-type(11) {
            align-items: flex-start;
            top: 725rem;
            left: calc(50% + 150rem);
            text-align: left;
            padding-right: 10rem;
          }
          &:nth-of-type(12) {
            align-items: flex-start;
            justify-content: center;
            text-align: center;
            top: 765rem;
            width: 280rem;
            right: calc(50% - 315rem);
            padding: 0;
          }
          &:nth-of-type(13) {
            align-items: flex-start;
            top: 765rem;
            right: calc(50% + 20rem);
            text-align: right;
            padding-left: 10rem;
          }
          &:nth-of-type(14) {
            align-items: flex-start;
            top: 725rem;
            right: calc(50% + 160rem);
            text-align: right;
            padding-left: 10rem;
          }
          &:nth-of-type(15) {
            align-items: flex-start;
            top: 675rem;
            right: calc(50% + 250rem);
            text-align: right;
            padding-left: 10rem;
          }
          &:nth-of-type(16) {
            align-items: flex-start;
            top: 605rem;
            right: calc(50% + 305rem);
            text-align: right;
            padding-left: 10rem;
          }
          &:nth-of-type(17) {
            align-items: flex-start;
            top: 520rem;
            right: calc(50% + 355rem);
            text-align: right;
            padding-left: 10rem;
          }
          &:nth-of-type(18) {
            align-items: flex-end;
            top: 395rem;
            right: calc(50% + 365rem);
            text-align: right;
            padding-left: 10rem;
          }
          &:nth-of-type(19) {
            align-items: flex-end;
            top: 290rem;
            right: calc(50% + 355rem);
            text-align: right;
            padding-left: 10rem;
          }
          &:nth-of-type(20) {
            align-items: flex-end;
            top: 195rem;
            right: calc(50% + 320rem);
            text-align: right;
            padding-left: 10rem;
          }
          &:nth-of-type(21) {
            align-items: flex-end;
            top: 110rem;
            right: calc(50% + 265rem);
            text-align: right;
            padding-left: 10rem;
          }
          &:nth-of-type(22) {
            align-items: flex-end;
            top: 55rem;
            right: calc(50% + 200rem);
            text-align: right;
            padding-left: 10rem;
          }
          &:nth-of-type(23) {
            align-items: flex-end;
            top: 15rem;
            right: calc(50% + 115rem);
            text-align: right;
            padding-left: 10rem;
          }
        }
      }

      &.items22 {
        .radarText {
          &:nth-of-type(1) {
            align-items: flex-end;
            justify-content: center;
            text-align: center;
            top: -25rem;
            width: 400rem;
            left: calc(50% - 200rem);
            padding: 0;
          }
          &:nth-of-type(2) {
            align-items: flex-end;
            top: 15rem;
            left: calc(50% + 110rem);
            text-align: left;
            padding-right: 10rem;
          }
          &:nth-of-type(3) {
            align-items: flex-end;
            top: 65rem;
            left: calc(50% + 210rem);
            text-align: left;
            padding-right: 10rem;
          }
          &:nth-of-type(4) {
            align-items: flex-end;
            top: 125rem;
            left: calc(50% + 280rem);
            text-align: left;
            padding-right: 10rem;
          }
          &:nth-of-type(5) {
            align-items: flex-end;
            top: 210rem;
            left: calc(50% + 340rem);
            text-align: left;
            padding-right: 10rem;
          }
          &:nth-of-type(6) {
            align-items: flex-end;
            top: 300rem;
            left: calc(50% + 360rem);
            text-align: left;
            padding-right: 10rem;
          }
          &:nth-of-type(7) {
            align-items: flex-start;
            top: 445rem;
            left: calc(50% + 360rem);
            text-align: left;
            padding-right: 10rem;
          }
          &:nth-of-type(8) {
            align-items: flex-start;
            top: 540rem;
            left: calc(50% + 340rem);
            text-align: left;
            padding-right: 10rem;
          }
          &:nth-of-type(9) {
            align-items: flex-start;
            top: 625rem;
            left: calc(50% + 280rem);
            text-align: left;
            padding-right: 10rem;
          }
          &:nth-of-type(10) {
            align-items: flex-start;
            top: 690rem;
            left: calc(50% + 210rem);
            text-align: left;
            padding-right: 10rem;
          }
          &:nth-of-type(11) {
            align-items: flex-start;
            top: 740rem;
            left: calc(50% + 110rem);
            text-align: left;
            padding-right: 10rem;
          }
          &:nth-of-type(12) {
            align-items: flex-start;
            justify-content: center;
            text-align: center;
            top: 780rem;
            width: 400rem;
            right: calc(50% - 200rem);
            padding: 0;
          }
          &:nth-of-type(13) {
            align-items: flex-start;
            top: 740rem;
            right: calc(50% + 110rem);
            text-align: right;
            padding-left: 10rem;
          }
          &:nth-of-type(14) {
            align-items: flex-start;
            top: 690rem;
            right: calc(50% + 210rem);
            text-align: right;
            padding-left: 10rem;
          }
          &:nth-of-type(15) {
            align-items: flex-start;
            top: 625rem;
            right: calc(50% + 280rem);
            text-align: right;
            padding-left: 10rem;
          }
          &:nth-of-type(16) {
            align-items: flex-start;
            top: 540rem;
            right: calc(50% + 340rem);
            text-align: right;
            padding-left: 10rem;
          }
          &:nth-of-type(17) {
            align-items: flex-start;
            top: 445rem;
            right: calc(50% + 360rem);
            text-align: right;
            padding-left: 10rem;
          }
          &:nth-of-type(18) {
            align-items: flex-end;
            top: 300rem;
            right: calc(50% + 360rem);
            text-align: right;
            padding-left: 10rem;
          }
          &:nth-of-type(19) {
            align-items: flex-end;
            top: 210rem;
            right: calc(50% + 340rem);
            text-align: right;
            padding-left: 10rem;
          }
          &:nth-of-type(20) {
            align-items: flex-end;
            top: 125rem;
            right: calc(50% + 280rem);
            text-align: right;
            padding-left: 10rem;
          }
          &:nth-of-type(21) {
            align-items: flex-end;
            top: 65rem;
            right: calc(50% + 210rem);
            text-align: right;
            padding-left: 10rem;
          }
          &:nth-of-type(22) {
            align-items: flex-end;
            top: 15rem;
            right: calc(50% + 110rem);
            text-align: right;
            padding-left: 10rem;
          }
        }
      }

      &.items21 {
        .radarText {
          &:nth-of-type(1) {
            align-items: flex-end;
            justify-content: center;
            text-align: center;
            bottom: 810rem;
            width: 400rem;
            left: calc(50% - 200rem);
            padding: 0;
          }
          &:nth-of-type(2) {
            align-items: flex-end;
            top: 15rem;
            left: calc(50% + 110rem);
            text-align: left;
            padding-right: 10rem;
          }
          &:nth-of-type(3) {
            align-items: flex-end;
            top: 65rem;
            left: calc(50% + 210rem);
            text-align: left;
            padding-right: 10rem;
          }
          &:nth-of-type(4) {
            align-items: flex-end;
            top: 135rem;
            left: calc(50% + 280rem);
            text-align: left;
            padding-right: 10rem;
          }
          &:nth-of-type(5) {
            align-items: flex-end;
            top: 240rem;
            left: calc(50% + 340rem);
            text-align: left;
            padding-right: 10rem;
          }
          &:nth-of-type(6) {
            align-items: flex-end;
            top: 340rem;
            left: calc(50% + 360rem);
            text-align: left;
            padding-right: 10rem;
          }
          &:nth-of-type(7) {
            align-items: flex-start;
            top: 470rem;
            left: calc(50% + 360rem);
            text-align: left;
            padding-right: 10rem;
          }
          &:nth-of-type(8) {
            align-items: flex-start;
            top: 560rem;
            left: calc(50% + 325rem);
            text-align: left;
            padding-right: 10rem;
          }
          &:nth-of-type(9) {
            align-items: flex-start;
            top: 655rem;
            left: calc(50% + 260rem);
            text-align: left;
            padding-right: 10rem;
          }
          &:nth-of-type(10) {
            align-items: flex-start;
            top: 725rem;
            left: calc(50% + 160rem);
            text-align: left;
            padding-right: 10rem;
          }
          &:nth-of-type(11) {
            align-items: flex-start;
            top: 780rem;
            left: calc(50% + 40rem);
            text-align: left;
            padding-right: 10rem;
          }
          &:nth-of-type(12) {
            align-items: flex-start;
            justify-content: center;
            text-align: center;
            top: 780rem;
            width: 400rem;
            right: calc(50% - 30rem);
            padding: 0;
          }
          &:nth-of-type(13) {
            align-items: flex-start;
            top: 720rem;
            right: calc(50% + 170rem);
            text-align: right;
            padding-left: 10rem;
          }
          &:nth-of-type(14) {
            align-items: flex-start;
            top: 660rem;
            right: calc(50% + 260rem);
            text-align: right;
            padding-left: 10rem;
          }
          &:nth-of-type(15) {
            align-items: flex-start;
            top: 580rem;
            right: calc(50% + 320rem);
            text-align: right;
            padding-left: 10rem;
          }
          &:nth-of-type(16) {
            align-items: flex-start;
            top: 470rem;
            right: calc(50% + 360rem);
            text-align: right;
            padding-left: 10rem;
          }
          &:nth-of-type(17) {
            align-items: flex-start;
            top: 360rem;
            right: calc(50% + 370rem);
            text-align: right;
            padding-left: 10rem;
          }
          &:nth-of-type(18) {
            align-items: flex-end;
            top: 240rem;
            right: calc(50% + 350rem);
            text-align: right;
            padding-left: 10rem;
          }
          &:nth-of-type(19) {
            align-items: flex-end;
            top: 120rem;
            right: calc(50% + 300rem);
            text-align: right;
            padding-left: 10rem;
          }
          &:nth-of-type(20) {
            align-items: flex-end;
            top: 65rem;
            right: calc(50% + 230rem);
            text-align: right;
            padding-left: 10rem;
          }
          &:nth-of-type(21) {
            align-items: flex-end;
            top: 15rem;
            right: calc(50% + 130rem);
            text-align: right;
            padding-left: 10rem;
          }
        }
      }

      &.items20 {
        .radarText {
          &:nth-of-type(1) {
            align-items: flex-end;
            justify-content: center;
            text-align: center;
            top: -15rem;
            width: 400rem;
            left: calc(50% - 200rem);
            padding: 0;
          }
          &:nth-of-type(2) {
            align-items: flex-end;
            top: 20rem;
            left: calc(50% + 115rem);
            text-align: left;
            padding-right: 10rem;
          }
          &:nth-of-type(3) {
            align-items: flex-end;
            top: 70rem;
            left: calc(50% + 215rem);
            text-align: left;
            padding-right: 10rem;
          }
          &:nth-of-type(4) {
            align-items: flex-end;
            top: 150rem;
            left: calc(50% + 295rem);
            text-align: left;
            padding-right: 10rem;
          }
          &:nth-of-type(5) {
            align-items: flex-end;
            top: 250rem;
            left: calc(50% + 345rem);
            text-align: left;
            padding-right: 10rem;
          }
          &:nth-of-type(6) {
            align-items: flex-end;
            top: 360rem;
            left: calc(50% + 360rem);
            text-align: left;
            padding-right: 10rem;
          }
          &:nth-of-type(7) {
            align-items: flex-start;
            top: 500rem;
            left: calc(50% + 345rem);
            text-align: left;
            padding-right: 10rem;
          }
          &:nth-of-type(8) {
            align-items: flex-start;
            top: 605rem;
            left: calc(50% + 295rem);
            text-align: left;
            padding-right: 10rem;
          }
          &:nth-of-type(9) {
            align-items: flex-start;
            top: 685rem;
            left: calc(50% + 215rem);
            text-align: left;
            padding-right: 10rem;
          }
          &:nth-of-type(10) {
            align-items: flex-start;
            top: 735rem;
            left: calc(50% + 115rem);
            text-align: left;
            padding-right: 10rem;
          }
          &:nth-of-type(11) {
            align-items: flex-start;
            justify-content: center;
            text-align: center;
            top: 775rem;
            width: 400rem;
            right: calc(50% - 200rem);
            padding: 0;
          }
          &:nth-of-type(12) {
            align-items: flex-start;
            top: 735rem;
            right: calc(50% + 115rem);
            text-align: right;
            padding-left: 10rem;
          }
          &:nth-of-type(13) {
            align-items: flex-start;
            top: 685rem;
            right: calc(50% + 215rem);
            text-align: right;
            padding-left: 10rem;
          }
          &:nth-of-type(14) {
            align-items: flex-start;
            top: 605rem;
            right: calc(50% + 295rem);
            text-align: right;
            padding-left: 10rem;
          }
          &:nth-of-type(15) {
            align-items: flex-start;
            top: 500rem;
            right: calc(50% + 345rem);
            text-align: right;
            padding-left: 10rem;
          }
          &:nth-of-type(16) {
            align-items: flex-end;
            top: 360rem;
            right: calc(50% + 360rem);
            text-align: right;
            padding-left: 10rem;
          }
          &:nth-of-type(17) {
            align-items: flex-end;
            top: 250rem;
            right: calc(50% + 345rem);
            text-align: right;
            padding-left: 10rem;
          }
          &:nth-of-type(18) {
            align-items: flex-end;
            top: 150rem;
            right: calc(50% + 295rem);
            text-align: right;
            padding-left: 10rem;
          }
          &:nth-of-type(19) {
            align-items: flex-end;
            top: 70rem;
            right: calc(50% + 215rem);
            text-align: right;
            padding-left: 10rem;
          }
          &:nth-of-type(20) {
            align-items: flex-end;
            top: 20rem;
            right: calc(50% + 115rem);
            text-align: right;
            padding-left: 10rem;
          }
        }
      }

      &.items19 {
        .radarText {
          &:nth-of-type(1) {
            align-items: flex-end;
            justify-content: center;
            text-align: center;
            top: 1rem;
            width: 400rem;
            left: calc(50% - 200rem);
            padding: 0;
          }
          &:nth-of-type(2) {
            align-items: flex-end;
            top: 20rem;
            left: calc(50% + 115rem);
            text-align: left;
            padding-right: 10rem;
          }
          &:nth-of-type(3) {
            align-items: flex-end;
            top: 75rem;
            left: calc(50% + 220rem);
            text-align: left;
            padding-right: 10rem;
          }
          &:nth-of-type(4) {
            align-items: flex-end;
            top: 160rem;
            left: calc(50% + 300rem);
            text-align: left;
            padding-right: 10rem;
          }
          &:nth-of-type(5) {
            align-items: flex-end;
            top: 265rem;
            left: calc(50% + 345rem);
            text-align: left;
            padding-right: 10rem;
          }
          &:nth-of-type(6) {
            align-items: flex-start;
            top: 410rem;
            left: calc(50% + 360rem);
            text-align: left;
            padding-right: 10rem;
          }
          &:nth-of-type(7) {
            align-items: flex-start;
            top: 520rem;
            left: calc(50% + 330rem);
            text-align: left;
            padding-right: 10rem;
          }
          &:nth-of-type(8) {
            align-items: flex-start;
            top: 625rem;
            left: calc(50% + 270rem);
            text-align: left;
            padding-right: 10rem;
          }
          &:nth-of-type(9) {
            align-items: flex-start;
            top: 700rem;
            left: calc(50% + 180rem);
            text-align: left;
            padding-right: 10rem;
          }
          &:nth-of-type(10) {
            align-items: flex-start;
            top: 750rem;
            left: calc(50% + 50rem);
            text-align: left;
            padding-right: 10rem;
          }
          &:nth-of-type(11) {
            align-items: flex-start;
            top: 750rem;
            right: calc(50% + 50rem);
            text-align: right;
            padding-left: 10rem;
          }
          &:nth-of-type(12) {
            align-items: flex-start;
            top: 700rem;
            right: calc(50% + 180rem);
            text-align: right;
            padding-left: 10rem;
          }
          &:nth-of-type(13) {
            align-items: flex-start;
            top: 625rem;
            right: calc(50% + 270rem);
            text-align: right;
            padding-left: 10rem;
          }
          &:nth-of-type(14) {
            align-items: flex-start;
            top: 520rem;
            right: calc(50% + 330rem);
            text-align: right;
            padding-left: 10rem;
          }
          &:nth-of-type(15) {
            align-items: flex-start;
            top: 410rem;
            right: calc(50% + 360rem);
            text-align: right;
            padding-left: 10rem;
          }
          &:nth-of-type(16) {
            align-items: flex-end;
            top: 265rem;
            right: calc(50% + 345rem);
            text-align: right;
            padding-left: 10rem;
          }
          &:nth-of-type(17) {
            align-items: flex-end;
            top: 160rem;
            right: calc(50% + 300rem);
            text-align: right;
            padding-left: 10rem;
          }
          &:nth-of-type(18) {
            align-items: flex-end;
            top: 75rem;
            right: calc(50% + 220rem);
            text-align: right;
            padding-left: 10rem;
          }
          &:nth-of-type(19) {
            align-items: flex-end;
            top: 20rem;
            right: calc(50% + 115rem);
            text-align: right;
            padding-left: 10rem;
          }
        }
      }

      &.items18 {
        .radarText {
          &:nth-of-type(1) {
            align-items: flex-end;
            justify-content: center;
            text-align: center;
            top: 5rem;
            width: 400rem;
            left: calc(50% - 200rem);
            padding: 0;
          }
          &:nth-of-type(2) {
            align-items: flex-end;
            top: 20rem;
            left: calc(50% + 125rem);
            text-align: left;
            padding-right: 10rem;
          }
          &:nth-of-type(3) {
            align-items: flex-end;
            top: 80rem;
            left: calc(50% + 235rem);
            text-align: left;
            padding-right: 10rem;
          }
          &:nth-of-type(4) {
            align-items: flex-end;
            top: 170rem;
            left: calc(50% + 315rem);
            text-align: left;
            padding-right: 10rem;
          }
          &:nth-of-type(5) {
            align-items: flex-end;
            top: 285rem;
            left: calc(50% + 360rem);
            text-align: left;
            padding-right: 10rem;
          }
          &:nth-of-type(6) {
            align-items: flex-start;
            top: 450rem;
            left: calc(50% + 360rem);
            text-align: left;
            padding-right: 10rem;
          }
          &:nth-of-type(7) {
            align-items: flex-start;
            top: 580rem;
            left: calc(50% + 315rem);
            text-align: left;
            padding-right: 10rem;
          }
          &:nth-of-type(8) {
            align-items: flex-start;
            top: 670rem;
            left: calc(50% + 235rem);
            text-align: left;
            padding-right: 10rem;
          }
          &:nth-of-type(9) {
            align-items: flex-start;
            top: 740rem;
            left: calc(50% + 125rem);
            text-align: left;
            padding-right: 10rem;
          }
          &:nth-of-type(10) {
            align-items: flex-start;
            justify-content: center;
            text-align: center;
            top: 775rem;
            width: 400rem;
            right: calc(50% - 200rem);
            padding: 0;
          }
          &:nth-of-type(11) {
            align-items: flex-start;
            top: 735rem;
            right: calc(50% + 125rem);
            text-align: right;
            padding-left: 10rem;
          }
          &:nth-of-type(12) {
            align-items: flex-start;
            top: 670rem;
            right: calc(50% + 235rem);
            text-align: right;
            padding-left: 10rem;
          }
          &:nth-of-type(13) {
            align-items: flex-start;
            top: 580rem;
            right: calc(50% + 315rem);
            text-align: right;
            padding-left: 10rem;
          }
          &:nth-of-type(14) {
            align-items: flex-start;
            top: 450rem;
            right: calc(50% + 360rem);
            text-align: right;
            padding-left: 10rem;
          }
          &:nth-of-type(15) {
            align-items: flex-end;
            top: 285rem;
            right: calc(50% + 360rem);
            text-align: right;
            padding-left: 10rem;
          }
          &:nth-of-type(16) {
            align-items: flex-end;
            top: 170rem;
            right: calc(50% + 315rem);
            text-align: right;
            padding-left: 10rem;
          }
          &:nth-of-type(17) {
            align-items: flex-end;
            top: 80rem;
            right: calc(50% + 235rem);
            text-align: right;
            padding-left: 10rem;
          }
          &:nth-of-type(18) {
            align-items: flex-end;
            top: 20rem;
            right: calc(50% + 125rem);
            text-align: right;
            padding-left: 10rem;
          }
        }
      }

      &.items17 {
        .radarText {
          display: flex;
          width: auto;
          height: 45rem;

          &:nth-of-type(1) {
            align-items: flex-end;
            justify-content: center;
            text-align: center;
            top: 5rem;
            width: 400rem;
            left: calc(50% - 200rem);
            padding: 0;
          }
          &:nth-of-type(2) {
            align-items: flex-end;
            top: 25rem;
            left: calc(50% + 127.5rem);
            text-align: left;
            padding-right: 10rem;
          }
          &:nth-of-type(3) {
            align-items: flex-end;
            top: 95rem;
            left: calc(50% + 235rem);
            text-align: left;
            padding-right: 10rem;
          }
          &:nth-of-type(4) {
            align-items: flex-end;
            top: 200rem;
            left: calc(50% + 315rem);
            text-align: left;
            padding-right: 10rem;
          }
          &:nth-of-type(5) {
            align-items: flex-end;
            top: 325rem;
            left: calc(50% + 352.5rem);
            text-align: left;
            padding-right: 10rem;
          }
          &:nth-of-type(6) {
            align-items: flex-start;
            top: 490rem;
            left: calc(50% + 345rem);
            text-align: left;
            padding-right: 10rem;
          }
          &:nth-of-type(7) {
            align-items: flex-start;
            top: 605rem;
            left: calc(50% + 285rem);
            text-align: left;
            padding-right: 10rem;
          }
          &:nth-of-type(8) {
            align-items: flex-start;
            top: 695rem;
            left: calc(50% + 185rem);
            text-align: left;
            padding-right: 10rem;
          }
          &:nth-of-type(9) {
            align-items: flex-start;
            top: 755rem;
            left: calc(50% + 65rem);
            text-align: left;
            padding-right: 10rem;
          }
          &:nth-of-type(10) {
            align-items: flex-start;
            top: 755rem;
            left: unset;
            right: calc(50% + 65rem);
            text-align: right;
            padding-left: 10rem;
          }
          &:nth-of-type(11) {
            align-items: flex-start;
            top: 695rem;
            left: unset;
            right: calc(50% + 185rem);
            text-align: right;
            padding-left: 10rem;
          }
          &:nth-of-type(12) {
            align-items: flex-start;
            top: 605rem;
            left: unset;
            right: calc(50% + 285rem);
            text-align: right;
            padding-left: 10rem;
          }
          &:nth-of-type(13) {
            align-items: flex-start;
            top: 490rem;
            left: unset;
            right: calc(50% + 345rem);
            text-align: right;
            padding-left: 10rem;
          }
          &:nth-of-type(14) {
            align-items: flex-end;
            top: 325rem;
            left: unset;
            right: calc(50% + 352.5rem);
            text-align: right;
            padding-left: 10rem;
          }
          &:nth-of-type(15) {
            align-items: flex-end;
            top: 200rem;
            left: unset;
            right: calc(50% + 315rem);
            text-align: right;
            padding-left: 10rem;
          }
          &:nth-of-type(16) {
            align-items: flex-end;
            top: 95rem;
            left: unset;
            right: calc(50% + 235rem);
            text-align: right;
            padding-left: 10rem;
          }
          &:nth-of-type(17) {
            align-items: flex-end;
            top: 25rem;
            left: unset;
            right: calc(50% + 127.5rem);
            text-align: right;
            padding-left: 10rem;
          }
        }
      }

      &.items16 {
        .radarText {
          display: flex;
          width: auto;
          height: 45rem;

          &:nth-of-type(1) {
            align-items: flex-end;
            justify-content: center;
            text-align: center;
            top: 0rem;
            width: 400rem;
            left: calc(50% - 200rem);
            padding: 0;
          }
          &:nth-of-type(2) {
            align-items: flex-end;
            top: 30rem;
            left: calc(50% + 135rem);
            text-align: left;
            padding-right: 10rem;
          }
          &:nth-of-type(3) {
            align-items: flex-end;
            top: 105rem;
            left: calc(50% + 250rem);
            text-align: left;
            padding-right: 10rem;
          }
          &:nth-of-type(4) {
            align-items: flex-end;
            top: 220rem;
            left: calc(50% + 325rem);
            text-align: left;
            padding-right: 10rem;
          }
          &:nth-of-type(5) {
            align-items: flex-end;
            top: 360rem;
            left: calc(50% + 355rem);
            text-align: left;
            padding-right: 10rem;
          }
          &:nth-of-type(6) {
            align-items: flex-start;
            top: 525rem;
            left: calc(50% + 325rem);
            text-align: left;
            padding-right: 10rem;
          }
          &:nth-of-type(7) {
            align-items: flex-start;
            top: 640rem;
            left: calc(50% + 255rem);
            text-align: left;
            padding-right: 10rem;
          }
          &:nth-of-type(8) {
            align-items: flex-start;
            top: 715rem;
            left: calc(50% + 135rem);
            text-align: left;
            padding-right: 10rem;
          }
          &:nth-of-type(9) {
            align-items: flex-start;
            justify-content: center;
            text-align: center;
            top: 765rem;
            width: 400rem;
            right: calc(50% - 200rem);
            padding: 0;
          }
          &:nth-of-type(10) {
            align-items: flex-start;
            top: 715rem;
            right: calc(50% + 135rem);
            text-align: right;
            padding-left: 10rem;
          }
          &:nth-of-type(11) {
            align-items: flex-start;
            top: 640rem;
            right: calc(50% + 255rem);
            text-align: right;
            padding-left: 10rem;
          }
          &:nth-of-type(12) {
            align-items: flex-start;
            top: 525rem;
            right: calc(50% + 325rem);
            text-align: right;
            padding-left: 10rem;
          }
          &:nth-of-type(13) {
            align-items: flex-end;
            top: 360rem;
            right: calc(50% + 355rem);
            text-align: right;
            padding-left: 10rem;
          }
          &:nth-of-type(14) {
            align-items: flex-end;
            top: 220rem;
            right: calc(50% + 325rem);
            text-align: right;
            padding-left: 10rem;
          }
          &:nth-of-type(15) {
            align-items: flex-end;
            top: 105rem;
            right: calc(50% + 250rem);
            text-align: right;
            padding-left: 10rem;
          }
          &:nth-of-type(16) {
            align-items: flex-end;
            top: 30rem;
            right: calc(50% + 135rem);
            text-align: right;
            padding-left: 10rem;
          }
        }
      }

      &.items15 {
        .radarText {
          display: flex;
          width: auto;
          height: 45rem;

          &:nth-of-type(1) {
            align-items: flex-end;
            justify-content: center;
            text-align: center;
            top: 5rem;
            width: 400rem;
            left: calc(50% - 200rem);
            padding: 0;
          }
          &:nth-of-type(2) {
            align-items: flex-end;
            top: 25rem;
            left: calc(50% + 145rem);
            text-align: left;
            padding-right: 10rem;
          }
          &:nth-of-type(3) {
            align-items: flex-end;
            top: 110rem;
            left: calc(50% + 265rem);
            text-align: left;
            padding-right: 10rem;
          }
          &:nth-of-type(4) {
            align-items: flex-end;
            top: 240rem;
            left: calc(50% + 345rem);
            text-align: left;
            padding-right: 10rem;
          }
          &:nth-of-type(5) {
            align-items: flex-start;
            top: 425rem;
            left: calc(50% + 365rem);
            text-align: left;
            padding-right: 10rem;
          }
          &:nth-of-type(6) {
            align-items: flex-start;
            top: 580rem;
            left: calc(50% + 315rem);
            text-align: left;
            padding-right: 10rem;
          }
          &:nth-of-type(7) {
            align-items: flex-start;
            top: 690rem;
            left: calc(50% + 215rem);
            text-align: left;
            padding-right: 10rem;
          }
          &:nth-of-type(8) {
            align-items: flex-start;
            top: 755rem;
            left: calc(50% + 65rem);
            text-align: left;
            padding-right: 10rem;
          }
          &:nth-of-type(9) {
            align-items: flex-start;
            top: 755rem;
            right: calc(50% + 65rem);
            text-align: right;
            padding-left: 10rem;
          }
          &:nth-of-type(10) {
            align-items: flex-start;
            top: 690rem;
            right: calc(50% + 215rem);
            text-align: right;
            padding-left: 10rem;
          }
          &:nth-of-type(11) {
            align-items: flex-start;
            top: 580rem;
            right: calc(50% + 315rem);
            text-align: right;
            padding-left: 10rem;
          }
          &:nth-of-type(12) {
            align-items: flex-start;
            top: 425rem;
            right: calc(50% + 365rem);
            text-align: right;
            padding-left: 10rem;
          }
          &:nth-of-type(13) {
            align-items: flex-end;
            top: 240rem;
            right: calc(50% + 345rem);
            text-align: right;
            padding-left: 10rem;
          }
          &:nth-of-type(14) {
            align-items: flex-end;
            top: 110rem;
            right: calc(50% + 265rem);
            text-align: right;
            padding-left: 10rem;
          }
          &:nth-of-type(15) {
            align-items: flex-end;
            top: 25rem;
            right: calc(50% + 145rem);
            text-align: right;
            padding-left: 10rem;
          }
        }
      }

      &.items14 {
        .radarText {
          display: flex;
          width: auto;
          height: 45rem;

          &:nth-of-type(1) {
            align-items: flex-end;
            justify-content: center;
            text-align: center;
            top: 5rem;
            width: 400rem;
            left: calc(50% - 200rem);
            padding: 0;
          }
          &:nth-of-type(2) {
            align-items: flex-end;
            top: 30rem;
            left: calc(50% + 160rem);
            text-align: left;
            padding-right: 10rem;
          }
          &:nth-of-type(3) {
            align-items: flex-end;
            top: 130rem;
            left: calc(50% + 280rem);
            text-align: left;
            padding-right: 10rem;
          }
          &:nth-of-type(4) {
            align-items: flex-end;
            top: 280rem;
            left: calc(50% + 355rem);
            text-align: left;
            padding-right: 10rem;
          }
          &:nth-of-type(5) {
            align-items: flex-start;
            top: 480rem;
            left: calc(50% + 355rem);
            text-align: left;
            padding-right: 10rem;
          }
          &:nth-of-type(6) {
            align-items: flex-start;
            top: 620rem;
            left: calc(50% + 285rem);
            text-align: left;
            padding-right: 10rem;
          }
          &:nth-of-type(7) {
            align-items: flex-start;
            top: 720rem;
            left: calc(50% + 160rem);
            text-align: left;
            padding-right: 10rem;
          }
          &:nth-of-type(8) {
            align-items: flex-start;
            justify-content: center;
            text-align: center;
            top: 755rem;
            width: 400rem;
            right: calc(50% - 200rem);
            padding-left: 0;
          }
          &:nth-of-type(9) {
            align-items: flex-start;
            top: 720rem;
            right: calc(50% + 160rem);
            text-align: right;
            padding-left: 10rem;
          }
          &:nth-of-type(10) {
            align-items: flex-start;
            top: 620rem;
            right: calc(50% + 285rem);
            text-align: right;
            padding-left: 10rem;
          }
          &:nth-of-type(11) {
            align-items: flex-start;
            top: 480rem;
            right: calc(50% + 355rem);
            text-align: right;
            padding-left: 10rem;
          }
          &:nth-of-type(12) {
            align-items: flex-end;
            top: 280rem;
            right: calc(50% + 355rem);
            text-align: right;
            padding-left: 10rem;
          }
          &:nth-of-type(13) {
            align-items: flex-end;
            top: 130rem;
            right: calc(50% + 280rem);
            text-align: right;
            padding-left: 10rem;
          }
          &:nth-of-type(14) {
            align-items: flex-end;
            top: 30rem;
            right: calc(50% + 160rem);
            text-align: right;
            padding-left: 10rem;
          }
        }
      }

      &.items13 {
        .radarText {
          display: flex;
          width: auto;
          height: 45rem;

          &:nth-of-type(1) {
            align-items: flex-end;
            justify-content: center;
            text-align: center;
            top: 5rem;
            width: 400rem;
            left: calc(50% - 200rem);
            padding: 0;
          }
          &:nth-of-type(2) {
            align-items: flex-end;
            top: 35rem;
            left: calc(50% + 175rem);
            text-align: left;
            padding-right: 10rem;
          }
          &:nth-of-type(3) {
            align-items: flex-end;
            top: 145rem;
            left: calc(50% + 300rem);
            text-align: left;
            padding-right: 10rem;
          }
          &:nth-of-type(4) {
            align-items: flex-end;
            top: 310rem;
            left: calc(50% + 360rem);
            text-align: left;
            padding-right: 10rem;
          }
          &:nth-of-type(5) {
            align-items: flex-start;
            top: 520rem;
            left: calc(50% + 340rem);
            text-align: left;
            padding-right: 10rem;
          }
          &:nth-of-type(6) {
            align-items: flex-start;
            top: 670rem;
            left: calc(50% + 240rem);
            text-align: left;
            padding-right: 10rem;
          }
          &:nth-of-type(7) {
            align-items: flex-start;
            top: 755rem;
            left: calc(50% + 80rem);
            text-align: left;
            padding-right: 10rem;
          }
          &:nth-of-type(8) {
            align-items: flex-start;
            top: 755rem;
            right: calc(50% + 80rem);
            text-align: right;
            padding-left: 10rem;
          }
          &:nth-of-type(9) {
            align-items: flex-start;
            top: 670rem;
            right: calc(50% + 240rem);
            text-align: right;
            padding-left: 10rem;
          }
          &:nth-of-type(10) {
            align-items: flex-start;
            top: 520rem;
            right: calc(50% + 340rem);
            text-align: right;
            padding-left: 10rem;
          }
          &:nth-of-type(11) {
            align-items: flex-end;
            top: 310rem;
            right: calc(50% + 360rem);
            text-align: right;
            padding-left: 10rem;
          }
          &:nth-of-type(12) {
            align-items: flex-end;
            top: 145rem;
            right: calc(50% + 300rem);
            text-align: right;
            padding-left: 10rem;
          }
          &:nth-of-type(13) {
            align-items: flex-end;
            top: 35rem;
            right: calc(50% + 175rem);
            text-align: right;
            padding-left: 10rem;
          }
        }
      }

      &.items12 {
        .radarText {
          display: flex;
          width: auto;
          height: 45rem;

          &:nth-of-type(1) {
            align-items: flex-end;
            justify-content: center;
            text-align: center;
            top: -10rem;
            width: 400rem;
            left: calc(50% - 200rem);
            padding: 0;
          }
          &:nth-of-type(2) {
            align-items: flex-end;
            top: 40rem;
            left: calc(50% + 180rem);
            text-align: left;
            padding-right: 10rem;
          }
          &:nth-of-type(3) {
            align-items: flex-end;
            top: 165rem;
            left: calc(50% + 315rem);
            text-align: left;
            padding-right: 10rem;
          }
          &:nth-of-type(4) {
            align-items: flex-end;
            top: 360rem;
            left: calc(50% + 370rem);
            text-align: left;
            padding-right: 10rem;
          }
          &:nth-of-type(5) {
            align-items: flex-start;
            top: 580rem;
            left: calc(50% + 315rem);
            text-align: left;
            padding-right: 10rem;
          }
          &:nth-of-type(6) {
            align-items: flex-start;
            top: 715rem;
            left: calc(50% + 180rem);
            text-align: left;
            padding-right: 10rem;
          }
          &:nth-of-type(7) {
            align-items: flex-start;
            justify-content: center;
            text-align: center;
            top: 765rem;
            width: 400rem;
            right: calc(50% - 200rem);
            padding-left: 0;
          }
          &:nth-of-type(8) {
            align-items: flex-start;
            top: 715rem;
            right: calc(50% + 180rem);
            text-align: right;
            padding-left: 10rem;
          }
          &:nth-of-type(9) {
            align-items: flex-start;
            top: 580rem;
            right: calc(50% + 315rem);
            text-align: right;
            padding-left: 10rem;
          }
          &:nth-of-type(10) {
            align-items: flex-end;
            top: 360rem;
            right: calc(50% + 370rem);
            text-align: right;
            padding-left: 10rem;
          }
          &:nth-of-type(11) {
            align-items: flex-end;
            top: 165rem;
            right: calc(50% + 315rem);
            text-align: right;
            padding-left: 10rem;
          }
          &:nth-of-type(12) {
            align-items: flex-end;
            top: 40rem;
            right: calc(50% + 180rem);
            text-align: right;
            padding-left: 10rem;
          }
        }
      }

      &.items11 {
        .radarText {
          display: flex;
          width: auto;
          height: 45rem;

          &:nth-of-type(1) {
            align-items: flex-end;
            justify-content: center;
            text-align: center;
            top: 1rem;
            width: 400rem;
            left: calc(50% - 200rem);
            padding: 0;
          }
          &:nth-of-type(2) {
            align-items: flex-end;
            top: 50rem;
            left: calc(50% + 190rem);
            text-align: left;
            padding-right: 10rem;
          }
          &:nth-of-type(3) {
            align-items: flex-end;
            top: 205rem;
            left: calc(50% + 330rem);
            text-align: left;
            padding-right: 10rem;
          }
          &:nth-of-type(4) {
            align-items: flex-start;
            top: 450rem;
            left: calc(50% + 360rem);
            text-align: left;
            padding-right: 10rem;
          }
          &:nth-of-type(5) {
            align-items: flex-start;
            top: 635rem;
            left: calc(50% + 275rem);
            text-align: left;
            padding-right: 10rem;
          }
          &:nth-of-type(6) {
            align-items: flex-start;
            top: 745rem;
            left: calc(50% + 100rem);
            text-align: left;
            padding-right: 10rem;
          }
          &:nth-of-type(7) {
            align-items: flex-start;
            top: 745rem;
            right: calc(50% + 100rem);
            text-align: right;
            padding-left: 10rem;
          }
          &:nth-of-type(8) {
            align-items: flex-start;
            top: 635rem;
            right: calc(50% + 275rem);
            text-align: right;
            padding-left: 10rem;
          }
          &:nth-of-type(9) {
            align-items: flex-start;
            top: 450rem;
            right: calc(50% + 360rem);
            text-align: right;
            padding-left: 10rem;
          }
          &:nth-of-type(10) {
            align-items: flex-end;
            top: 205rem;
            right: calc(50% + 330rem);
            text-align: right;
            padding-left: 10rem;
          }
          &:nth-of-type(11) {
            align-items: flex-end;
            top: 50rem;
            right: calc(50% + 190rem);
            text-align: right;
            padding-left: 10rem;
          }
        }
      }

      &.items10 {
        .radarText {
          display: flex;
          width: auto;
          height: 45rem;

          &:nth-of-type(1) {
            align-items: flex-end;
            justify-content: center;
            text-align: center;
            top: 1rem;
            width: 400rem;
            left: calc(50% - 200rem);
            padding: 0;
          }
          &:nth-of-type(2) {
            align-items: flex-end;
            top: 60rem;
            left: calc(50% + 215rem);
            text-align: left;
            padding-right: 10rem;
          }
          &:nth-of-type(3) {
            align-items: flex-end;
            top: 240rem;
            left: calc(50% + 345rem);
            text-align: left;
            padding-right: 10rem;
          }
          &:nth-of-type(4) {
            align-items: flex-start;
            top: 510rem;
            left: calc(50% + 345rem);
            text-align: left;
            padding-right: 10rem;
          }
          &:nth-of-type(5) {
            align-items: flex-start;
            top: 690rem;
            left: calc(50% + 215rem);
            text-align: left;
            padding-right: 10rem;
          }
          &:nth-of-type(6) {
            align-items: flex-start;
            justify-content: center;
            text-align: center;
            top: 765rem;
            width: 400rem;
            right: calc(50% - 200rem);
            padding-left: 0;
          }
          &:nth-of-type(7) {
            align-items: flex-start;
            top: 690rem;
            right: calc(50% + 215rem);
            text-align: right;
            padding-left: 10rem;
          }
          &:nth-of-type(8) {
            align-items: flex-start;
            top: 510rem;
            right: calc(50% + 345rem);
            text-align: right;
            padding-left: 10rem;
          }
          &:nth-of-type(9) {
            align-items: flex-end;
            top: 240rem;
            right: calc(50% + 345rem);
            text-align: right;
            padding-left: 10rem;
          }
          &:nth-of-type(10) {
            align-items: flex-end;
            top: 60rem;
            right: calc(50% + 215rem);
            text-align: right;
            padding-left: 10rem;
          }
        }
      }

      &.items9 {
        .radarText {
          display: flex;
          width: auto;
          height: 45rem;

          &:nth-of-type(1) {
            align-items: flex-end;
            justify-content: center;
            text-align: center;
            top: 1rem;
            width: 400rem;
            left: calc(50% - 200rem);
            padding: 0;
          }
          &:nth-of-type(2) {
            align-items: flex-end;
            top: 75rem;
            left: calc(50% + 230rem);
            text-align: left;
            padding-right: 10rem;
          }
          &:nth-of-type(3) {
            align-items: flex-end;
            top: 290rem;
            left: calc(50% + 355rem);
            text-align: left;
            padding-right: 10rem;
          }
          &:nth-of-type(4) {
            align-items: flex-start;
            top: 580rem;
            left: calc(50% + 310rem);
            text-align: left;
            padding-right: 10rem;
          }
          &:nth-of-type(5) {
            align-items: flex-start;
            top: 735rem;
            left: calc(50% + 120rem);
            text-align: left;
            padding-right: 10rem;
          }
          &:nth-of-type(6) {
            align-items: flex-start;
            top: 735rem;
            right: calc(50% + 120rem);
            text-align: right;
            padding-left: 10rem;
          }
          &:nth-of-type(7) {
            align-items: flex-start;
            top: 580rem;
            right: calc(50% + 310rem);
            text-align: right;
            padding-left: 10rem;
          }
          &:nth-of-type(8) {
            align-items: flex-end;
            top: 290rem;
            right: calc(50% + 355rem);
            text-align: right;
            padding-left: 10rem;
          }
          &:nth-of-type(9) {
            align-items: flex-end;
            top: 75rem;
            right: calc(50% + 230rem);
            text-align: right;
            padding-left: 10rem;
          }
        }
      }

      &.items8 {
        .radarText {
          display: flex;
          width: auto;
          height: 45rem;

          &:nth-of-type(1) {
            align-items: flex-end;
            justify-content: center;
            text-align: center;
            top: 0;
            width: 400rem;
            left: calc(50% - 200rem);
            padding: 0;
          }
          &:nth-of-type(2) {
            align-items: flex-end;
            top: 110rem;
            left: calc(50% + 250rem);
            text-align: left;
            padding: 0;
          }
          &:nth-of-type(3) {
            align-items: flex-end;
            top: 360rem;
            left: calc(50% + 355rem);
            text-align: left;
            padding-right: 10rem;
          }
          &:nth-of-type(4) {
            align-items: flex-start;
            text-align: left;
            top: 640rem;
            left: calc(50% + 250rem);
            padding: 0;
          }
          &:nth-of-type(5) {
            align-items: flex-start;
            justify-content: center;
            text-align: center;
            top: 750rem;
            width: 400rem;
            right: calc(50% - 200rem);
            padding: 0;
          }
          &:nth-of-type(6) {
            align-items: flex-start;
            text-align: right;
            top: 640rem;
            right: calc(50% + 250rem);
            padding: 0;
          }
          &:nth-of-type(7) {
            align-items: flex-end;
            text-align: right;
            top: 110rem;
            right: calc(50% + 250rem);
            padding: 0;
          }
          &:nth-of-type(8) {
            align-items: flex-end;
            top: 360rem;
            right: calc(50% + 355rem);
            text-align: right;
            padding-left: 10rem;
          }
        }
      }

      &.items7 {
        .radarText {
          display: flex;
          width: auto;
          height: 45rem;

          &:nth-of-type(1) {
            align-items: flex-end;
            justify-content: center;
            text-align: center;
            top: 1rem;
            width: 400rem;
            left: calc(50% - 200rem);
            padding: 0;
          }
          &:nth-of-type(2) {
            align-items: flex-end;
            top: 125rem;
            left: calc(50% + 290rem);
            text-align: left;
            padding-right: 10rem;
          }
          &:nth-of-type(3) {
            align-items: flex-start;
            top: 485rem;
            left: calc(50% + 355rem);
            text-align: left;
            padding-right: 10rem;
          }
          &:nth-of-type(4) {
            align-items: flex-start;
            top: 725rem;
            left: calc(50% + 155rem);
            text-align: left;
            padding-right: 10rem;
          }
          &:nth-of-type(5) {
            align-items: flex-start;
            top: 725rem;
            right: calc(50% + 155rem);
            text-align: right;
            padding-left: 10rem;
          }
          &:nth-of-type(6) {
            align-items: flex-start;
            top: 485rem;
            right: calc(50% + 355rem);
            text-align: right;
            padding-left: 10rem;
          }
          &:nth-of-type(7) {
            align-items: flex-end;
            top: 125rem;
            right: calc(50% + 290rem);
            text-align: right;
            padding-left: 10rem;
          }
        }
      }

      &.items6 {
        .radarText {
          display: flex;
          width: auto;
          height: 45rem;

          &:nth-of-type(1) {
            align-items: flex-end;
            justify-content: center;
            text-align: center;
            top: 0;
            width: 400rem;
            left: calc(50% - 200rem);
            padding: 0;
          }
          &:nth-of-type(2) {
            align-items: flex-end;
            text-align: left;
            top: 175rem;
            left: calc(50% + 310rem);
            padding-right: 10rem;
          }
          &:nth-of-type(3) {
            align-items: flex-start;
            text-align: left;
            top: 570rem;
            left: calc(50% + 310rem);
            padding-right: 10rem;
          }
          &:nth-of-type(4) {
            align-items: flex-start;
            justify-content: center;
            text-align: center;
            top: 755rem;
            width: 400rem;
            right: calc(50% - 200rem);
            padding: 0;
          }
          &:nth-of-type(5) {
            align-items: flex-start;
            text-align: right;
            top: 570rem;
            right: calc(50% + 310rem);
            padding-left: 10rem;
          }
          &:nth-of-type(6) {
            align-items: flex-end;
            text-align: right;
            top: 175rem;
            right: calc(50% + 310rem);
            padding-left: 10rem;
          }
        }
      }

      &.items5 {
        .radarText {
          display: flex;
          width: auto;
          height: 45rem;

          &:nth-of-type(1) {
            align-items: flex-end;
            justify-content: center;
            text-align: center;
            top: 1rem;
            width: 400rem;
            left: calc(50% - 200rem);
            padding: 0;
          }
          &:nth-of-type(2) {
            align-items: flex-end;
            top: 245rem;
            left: calc(50% + 345rem);
            text-align: left;
            padding-right: 10rem;
          }
          &:nth-of-type(3) {
            align-items: flex-start;
            top: 695rem;
            left: calc(50% + 210rem);
            text-align: left;
            padding-right: 10rem;
          }
          &:nth-of-type(4) {
            align-items: flex-start;
            top: 695rem;
            right: calc(50% + 210rem);
            text-align: right;
            padding-left: 10rem;
          }
          &:nth-of-type(5) {
            align-items: flex-end;
            top: 245rem;
            right: calc(50% + 345rem);
            text-align: right;
            padding-left: 10rem;
          }
        }
      }

      &.items4 {
        .radarText {
          display: flex;
          width: auto;
          height: 45rem;

          &:nth-of-type(1) {
            align-items: flex-end;
            justify-content: center;
            text-align: center;
            top: 0;
            width: 400rem;
            left: calc(50% - 200rem);
            padding: 0;
          }

          &:nth-of-type(2) {
            align-items: flex-end;
            top: 365rem;
            left: calc(50% + 355rem);
            text-align: left;
            padding-right: 10rem;
          }

          &:nth-of-type(3) {
            align-items: flex-start;
            justify-content: center;
            text-align: center;
            top: 755rem;
            width: 400rem;
            right: calc(50% - 200rem);
            padding: 0;
          }

          &:nth-of-type(4) {
            align-items: flex-end;
            top: 365rem;
            right: calc(50% + 355rem);
            text-align: right;
            padding-left: 10rem;
          }
        }
      }

      &.items3 {
        .radarText {
          display: flex;
          width: auto;
          height: 45rem;

          &:nth-of-type(1) {
            align-items: flex-end;
            justify-content: center;
            text-align: center;
            top: 1rem;
            width: 400rem;
            left: calc(50% - 200rem);
            padding: 0;
          }
          &:nth-of-type(2) {
            align-items: flex-start;
            top: 580rem;
            left: calc(50% + 320rem);
            text-align: left;
            padding-right: 10rem;
          }
          &:nth-of-type(3) {
            align-items: flex-start;
            top: 580rem;
            right: calc(50% + 320rem);
            text-align: right;
            padding-left: 10rem;
          }
        }
      }
    }
  }

  .radarExplanation {
    grid-column: span 2;
    display: grid;
    grid-template-columns: max-content max-content auto;
    column-gap: 30rem;
    padding-left: 40rem;

    .pointer {
      display: flex;
      align-items: center;
      grid-row: 1;

      .pointerCube {
        width: 26rem;
        height: 26rem;
        margin-right: 10rem;
        border: 3px solid rgba(111, 111, 111, 0.6);
      }

      .pointerText {
        font-size: 14rem;
        line-height: 20rem;
        color: rgba($color: #000000, $alpha: 1);
      }

      &:first-of-type {
        .pointerCube {
          border: 3px solid rgba(111, 111, 111, 0.6);
          background-color: rgba(111, 111, 111, 0.2);
        }
      }
    }
  }
}

@media print {
  .competentionsSpider {
    page-break-before: always;
    page-break-after: always;
    padding-top: 180rem;

    .title {
      padding-top: 90rem;
      margin-bottom: 0;
    }

    .svgContainer {
      .svg {
        svg {
          // margin-top: -180rem;
        }
      }
    }

    .radarExplanation {
      margin-top: 290rem;
    }
  }
}
