.recomendationsForIndicators {
  padding: 100rem 40rem 0;

  .title {
    margin-bottom: 45rem;
    line-height: 44rem;
    // color: var(--link-normal);
    color: black;
  }

  .generalRecomendation {
    margin-bottom: 60rem;
  }

  .indicatorContainer {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 50rem;

    .left,
    .right {
      grid-column: span 1;
    }

    .indicatorRecomendation {
      margin-bottom: 45rem;
      padding-left: 50rem;

      &.books {
        .indicatorRecomendationTitle {
          &:before {
            content: '';
            position: absolute;
            width: 40rem;
            height: 40rem;
            left: -50rem;
            top: -4rem;
            background: url(../../../assets/images/icon_books.svg) center no-repeat;
            background-size: contain;
          }
        }
      }

      &.articles {
        .indicatorRecomendationTitle {
          &:before {
            content: '';
            position: absolute;
            width: 40rem;
            height: 40rem;
            left: -50rem;
            top: -4rem;
            background: url(../../../assets/images/icon_articles.svg) center no-repeat;
            background-size: contain;
          }
        }
      }

      &.videos {
        .indicatorRecomendationTitle {
          &:before {
            content: '';
            position: absolute;
            width: 40rem;
            height: 40rem;
            left: -50rem;
            top: -8rem;
            background: url(../../../assets/images/icon_videos.svg) center no-repeat;
            background-size: contain;
          }
        }
      }

      &.podcasts {
        .indicatorRecomendationTitle {
          &:before {
            content: '';
            position: absolute;
            width: 40rem;
            height: 40rem;
            left: -50rem;
            top: -10rem;
            background: url(../../../assets/images/icon_podcasts.svg) center no-repeat;
            background-size: contain;
          }
        }
      }

      &.courses {
        .indicatorRecomendationTitle {
          &:before {
            content: '';
            position: absolute;
            width: 40rem;
            height: 40rem;
            left: -50rem;
            top: -9rem;
            background: url(../../../assets/images/icon_courses.svg) center no-repeat;
            background-size: contain;
          }
        }
      }

      .indicatorRecomendationTitle {
        position: relative;
        font-size: 20rem;
        font-weight: 700;
        line-height: 24rem;
        margin-bottom: 15rem;
      }
    }
  }
}

@media print {
  .recomendationsForIndicators {
    page-break-before: always;
    page-break-after: always;

    .indicatorContainer {
      .indicatorRecomendation {
        page-break-inside: avoid;
      }
    }
  }
}
