.anketaZoomVideoModal {
  display: grid;

  .title {
    margin-bottom: 50rem;
    padding-left: 0 !important;

    &::after {
      display: none;
    }
  }

  .titleRow,
  .row {
    display: grid;
    grid-auto-columns: max-content;
    padding-bottom: 9rem;
    border-bottom: 1px solid #dedede;
    margin-bottom: 30rem;

    &.row {
      border-bottom: none;
      margin-bottom: 60rem;
      padding-bottom: 0;

      * {
        color: rgba(0, 0, 0, 1);
      }
    }

    * {
      grid-column: span 1;
      grid-row: 1;
      font-size: 14rem;
      line-height: 16rem;
      color: rgba(0, 0, 0, 0.5);
    }

    .expert {
      width: 200rem;
    }

    .name {
      width: 250rem;
    }

    .tribe {
      width: 140rem;
    }

    .role {
      width: 185rem;
    }

    .case {
      width: 120rem;
    }
  }

  video {
    justify-self: center;
    max-width: 755rem;
    max-height: 300rem;
  }
}
