.clipboardIcon {
  width: 14rem;
  height: 16rem;
  cursor: pointer;
}

.copiedToClipboardText {
  font-size: 10rem;
  line-height: 16rem;
  color: var(--link-normal);
}
