.adminClientForm {
  background-color: #fff;

  form {
    .title {
      position: relative;
      padding-left: 30rem;
      padding-bottom: 25rem;
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);

      margin-top: 95rem;
      margin-bottom: 30rem;

      &:first-child {
        margin-top: 100rem;
        margin-bottom: 60rem;

        &:after {
          content: '';
          position: absolute;
          left: 0;
          bottom: -6rem;
          width: 450rem;
          height: 6rem;
          background-color: var(--button-blue-normal);
        }
      }
    }

    hr {
      grid-column: span 2;
      width: 100%;
      background: #000000;
      opacity: 0.2;
    }

    .inputFieldsWrapper {
      display: grid;
      grid-template-columns: 600rem 600rem;
      column-gap: 130rem;
      row-gap: 30rem;
      align-content: flex-start;
      padding: 0 30rem;

      .input {
        grid-column: span 1;
        width: auto;
      }

      .checkbox {
        grid-column: span 2;
        align-self: flex-end;

        .checkboxVisibleLabel {
          max-width: 300rem;
          grid-template-columns: 16rem max-content;

          .checkboxText {
            display: grid;

            .segmentName {
              font-size: 16rem;
              line-height: 18rem;
              font-weight: 600;
            }

            .segmentExplanation {
              font-size: 14rem;
              line-height: 16rem;
              font-weight: 400;
            }
          }
        }
      }

      .fileFieldContainer {
        grid-column: span 1;
        display: grid;
        grid-template-columns: auto 230rem;
        column-gap: 15rem;
        align-items: center;

        .fileFieldInfo {
          overflow-x: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;

          font-size: 14rem;
          line-height: 18rem;
          color: rgba($color: #000000, $alpha: 0.5);
        }

        input[type='file'] {
          display: none;
        }

        button {
          label {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            height: 100%;
            cursor: pointer;
          }
        }
      }
    }

    .checkboxFieldsWrapper {
      display: grid;
      grid-template-columns: 200rem 200rem 200rem;
      column-gap: 250rem;

      padding: 0 30rem;

      .checkbox {
        grid-column: span 1;
        align-self: flex-start;

        .checkboxVisibleLabel {
          grid-template-columns: 16rem max-content;

          .checkboxText {
            display: grid;

            .segmentName {
              font-size: 16rem;
              line-height: 18rem;
              font-weight: 600;
            }

            .segmentExplanation {
              font-size: 14rem;
              line-height: 16rem;
              font-weight: 400;
            }
          }
        }
      }
    }

    .radiobuttonsFieldsWrapper,
    .reportsFieldsWrapper,
    .emailsFieldsWrapper {
      display: grid;
      grid-template-columns: 570rem 570rem;
      column-gap: 100rem;
      row-gap: 40rem;

      padding: 0 30rem;

    .radiobutton,
    .checkbox {
      grid-column: span 1;
      align-self: flex-start;

      .radiobuttonVisibleLabel,
      .checkboxVisibleLabel {
        grid-template-columns: 16rem max-content;

        .radiobuttonText,
        .checkboxText {
          display: grid;

          .key {
            font-size: 16rem;
            line-height: 18rem;
            font-weight: 600;
          }

          .value {
            margin-top: 10rem;

            font-size: 14rem;
            line-height: 16rem;
            font-weight: 400;
            color: rgba($color: #000000, $alpha: 0.5);
          }
        }
      }
    }

      &.emailsFieldsWrapper {
        .textareaWYSIWYG {
          grid-column: span 2;
          margin-left: 26rem;
        }
      }
    }

    .rolesInputsFieldWrapper {
      display: grid;
      grid-template-columns: 450rem 450rem 450rem;
      margin-top: 30rem;

      .input {
        grid-column: span 1;
        width: auto;
        padding-left: 57.5rem;
      }
    }

    .tribesFieldsWrapper,
    .streamsFieldsWrapper {
      display: grid;
      grid-template-columns: 700rem 50rem 50rem;
      column-gap: 30rem;
      row-gap: 30rem;
      align-content: flex-start;
      justify-content: flex-start;
      padding: 0 30rem;

      .input {
        grid-column: 1;
      }

      > button {
        margin-top: 10rem;
        grid-column: span 1;

        &.whiteButton {
          border: 1px solid var(--button-blue-normal);
        }

        img {
          margin: 0;
        }
      }

      &.streamsFieldsWrapper {
        grid-template-columns: 250rem 150rem 200rem 200rem 250rem 50rem 50rem;

        .input {
          &:nth-of-type(5n-4) {
            grid-column: 1;
          }
          &:nth-of-type(5n-3) {
            grid-column: 2;
          }
          &:nth-of-type(5n-2) {
            grid-column: 3;
          }
          &:nth-of-type(5n-1) {
            grid-column: 4;
          }
          &:nth-of-type(5n) {
            grid-column: 5;
          }
          // &:nth-child(3) {
          //   grid-column: 3;
          // }
        }

        > button {
          grid-column: span 1;
        }
      }
    }

    > button {
      margin: 45rem 0 30rem;
    }

    > .inputError {
      margin: 0 auto 60rem;
    }
  }
}
