.memberAnketaSubquestionsProgression,
.expertAnketaSubquestionsProgression {
  padding: relative;
  margin-top: -40rem;
  padding: 25rem 0 0;
  background-color: #fff;

  .memberAnketaSubquestionsProgressionTitle,
  .expertAnketaSubquestionsProgressionTitle {
    margin-bottom: 10rem;
    padding-left: 30rem;

    font-size: 18rem;
    line-height: 36rem;
    font-weight: 600;
  }

  .progressionPoints {
    display: grid;
    grid-auto-flow: row;
    grid-template-columns: repeat(23, 30rem);
    column-gap: 26rem;
    row-gap: 26rem;
    width: 100%;
    padding: 13rem 30rem;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    background-color: #00aeef;

    .progressionPoint {
      position: relative;
      grid-column: span 1;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 24rem;
      height: 24rem;
      background-color: var(--button-blue-normal);
      border-radius: 50%;

      font-size: 14rem;
      font-weight: 600;
      line-height: 14rem;
      color: rgba(255, 255, 255, 1);
      text-decoration: none;
      user-select: none;

      &:after {
        content: '';
        position: absolute;
        width: 20rem;
        height: 1rem;
        background-color: rgba($color: #fff, $alpha: 0.4);
        right: -25rem;
        top: 12rem;
      }

      &:last-child:after {
        display: none;
      }

      &.active {
        background-color: #fff;
        color: #00aeef;

        &:after {
          right: -28rem;
        }
      }

      &.answered {
        color: rgba($color: #fff, $alpha: 0.4);
      }
    }
  }
}
