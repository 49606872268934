.competentionsIndicators {
  padding-top: 100rem;

  .competention {
    position: relative;
    display: grid;
    grid-template-columns: auto 375rem;
    column-gap: 45rem;
    padding-top: 150rem;

    &:nth-child(1) {
      padding-top: 0;
    }

    .subTitle {
      grid-column: 1;
      align-self: flex-start;
      padding-left: 40rem;

      font-size: 30rem;
      line-height: 36rem;
    }

    .subTitleEmoji {
      grid-column: 2;
      align-self: flex-end;
      display: grid;
      row-gap: 5rem;
      padding-right: 40rem;

      .emoji {
        position: relative;
        padding-left: 30rem;

        &:nth-of-type(1) {
          .emojiIcon {
            content: '';
            position: absolute;
            left: 0;
            top: 0rem;
            width: 24rem;
            height: 24rem;
            background: url(../../../assets/images/icon_pdfIndicatorBad.svg) center no-repeat;
            background-size: 11rem 16rem;
            background-color: #252424;
          }
        }

        &:nth-of-type(2) {
          .emojiIcon {
            content: '';
            position: absolute;
            left: 0;
            top: 0rem;
            width: 24rem;
            height: 24rem;
            background: url(../../../assets/images/icon_pdfIndicatorGood.svg) center no-repeat;
            background-size: 16rem 14rem;
          }
        }

        .text {
          font-size: 10rem;
          line-height: 24rem;
          color: rgba($color: #000000, $alpha: 0.5);
        }
      }
    }

    .scoring {
      grid-column: span 2;
      align-self: flex-end;
      text-align: left;
      padding-left: 1185rem;
      margin: 35rem 0 10rem;

      font-size: 12rem;
      line-height: 14rem;
      color: rgba($color: #000000, $alpha: 1);
    }

    .indicator {
      width: 100%;
      grid-column: span 2;
      display: grid;
      grid-template-columns: auto 250rem;
      column-gap: 45rem;
      min-height: 60rem;
      border-bottom: 1px solid rgba($color: #000000, $alpha: 0.1);

      &:nth-child(4) {
        border-top: 1px solid rgba($color: #000000, $alpha: 0.1);
      }

      .key {
        grid-column: 1;
        align-self: center;
        padding: 15rem 0 15rem 40rem;

        font-size: 18rem;
        line-height: 24rem;
        color: rgba($color: #000000, $alpha: 1);

        p {
          line-height: 24rem;
        }
      }

      .value {
        grid-column: 2;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-right: 40rem;

        .valuePoints,
        .valuePercentage {
          grid-column: span 1;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          height: 100%;

          font-size: 18rem;
          line-height: normal;
          color: rgba($color: #000000, $alpha: 1);

          &.valuePoints {
            width: calc(140rem / 3);
          }

          &.valuePercentage {
            width: 65rem;
            justify-content: center;
          }

          &.firstStream,
          &.secondStream {
            color: rgba($color: #000000, $alpha: 0.5);
          }
        }

        .valueEmoji {
          grid-column: 4;
          width: 59rem;
          height: 100%;

          &.bad {
            background: url(../../../assets/images/icon_pdfIndicatorBad.svg) center no-repeat;
            background-size: 25rem 36rem;
            background-color: #00aeef;
          }

          &.good {
            background: url(../../../assets/images/icon_pdfIndicatorGood.svg) center no-repeat;
            background-size: 35rem 31rem;
            background-color: #1364b4;
          }
        }

        .valueCircle {
          .circleContainer {
            width: 43rem;
            height: 43rem;
          }
        }
      }
    }
  }

  &.oneStream.percents {
    .scoring {
      padding-left: 1237.5rem;
    }

    .competention {
      .indicator {
        grid-template-columns: auto 200rem;
      }
    }
  }

  &.twoStreams.percents {
    .scoring {
      padding-left: 1192.5rem;
    }
  }

  &.threeStreams.percents {
    .scoring {
      padding-left: 1110rem;
    }

    .competention {
      .indicator {
        grid-template-columns: auto 330rem;
      }
    }
  }
}
