.newAnketa {
  form {
    display: grid;
    grid-template-columns: 700rem 330rem auto;
    column-gap: 30rem;
    align-items: flex-end;

    > input {
      grid-column: 1;
      grid-row: 1;
    }

    > button {
      grid-column: 2;
      grid-row: 1;
    }

    > .inputError {
      grid-column: 1;
      grid-row: 2;
    }

    .inputSelect:nth-child(4) {
      position: relative;
      z-index: 60;
      margin-bottom: 30rem;
    }

    .inputSelect:nth-child(5) {
      position: relative;
      z-index: 50;
    }

    .roleSegmentsModal {
      width: 730rem;

      .subTitle {
        margin-bottom: 40rem;
        font-weight: 400;
      }

      > button {
        margin: 0 auto;
      }
    }
  }
}
