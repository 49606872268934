.adminClientReport {
  padding: 0 30rem;
  margin-bottom: 115rem;

  .yearsReports {
    margin-top: 15rem;
    display: flex;
    column-gap: 15rem;
  }
}
