.anketaProgression {
  display: grid;
  grid-auto-flow: row;
  grid-template-columns: repeat(23, 30rem);
  column-gap: 30rem;
  row-gap: 30rem;
  width: 100%;
  padding: 40rem 30rem;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  background-color: #fff;

  .progressionPoint {
    position: relative;
    grid-column: span 1;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30rem;
    height: 30rem;
    background-color: var(--button-blue-normal);
    border-radius: 50%;

    font-size: 14rem;
    font-weight: 600;
    line-height: 14rem;
    color: rgba(255, 255, 255, 1);
    text-decoration: none;
    user-select: none;

    &:before {
      content: '';
      position: absolute;
      width: 10rem;
      height: 1rem;
      background-color: rgba(242, 242, 242, 1);
      left: -20rem;
      top: 15rem;
    }

    &:nth-of-type(1):before,
    &:nth-of-type(24):before,
    &:nth-of-type(47):before {
      display: none;
    }

    &.active {
      background-color: rgba(0, 0, 0, 1);
    }
  }
}
