.adminMembersTable {
  display: grid;
  width: 100%;
  padding: 30rem 0 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);

  table {
    tr {
      position: relative;
      display: grid;
      grid-auto-columns: max-content;
      padding: 0 30rem;
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);

      &.missedCall {
        background-color: rgba(222, 161, 180, 0.1);
      }

      th,
      td {
        grid-column: span 1;
        grid-row: 1;
        display: flex;
        align-items: center;
        height: 75rem;
        text-align: left;

        // * Время
        &:nth-of-type(1) {
          width: 90rem;
          color: rgba(0, 0, 0, 0.7);
        }

        // * Дата
        &:nth-of-type(2) {
          width: 140rem;
        }

        // * Статус
        &:nth-of-type(3) {
          width: 210rem;
        }

        // * Имя участника
        &:nth-of-type(4) {
          width: 210rem;
        }

        // * Трайб
        &:nth-of-type(5) {
          width: 190rem;
          padding-right: 30rem;

          font-weight: 400;
          color: rgba(0, 0, 0, 0.7);
        }

        // * Роль
        &:nth-of-type(6) {
          width: 190rem;
          padding-right: 30rem;

          font-weight: 400;
          color: rgba(0, 0, 0, 0.7);
        }

        // * Сегмент
        &:nth-of-type(7) {
          width: 120rem;
          font-weight: 400;
          color: rgba(0, 0, 0, 0.7);
        }

        // * Способ связи
        &:nth-of-type(8) {
          width: 190rem;
          font-weight: 400;
          color: rgba(0, 0, 0, 0.7);
        }

        // * Кнопки
        &:nth-of-type(9) {
          width: 30rem;
          font-size: 0;
        }

        .hide {
          visibility: hidden;
        }
      }

      th {
        position: relative;
        font-size: 12rem;
        line-height: 14rem;
        font-weight: 600;
        text-transform: uppercase;
        user-select: none;

        .sortUpIcon,
        .sortDownIcon {
          width: 10rem;
          height: 4rem;
          margin-left: 10rem;
          margin-bottom: 2rem;

          &.sortDownIcon {
            transform: rotate(180deg);
          }
        }

        .searchIcon {
          width: 20rem;
          height: 20rem;
          padding: 4rem;
          margin-left: 8rem;
          cursor: pointer;
        }

        .resetFilterIcon {
          width: 20rem;
          height: 20rem;
          margin-bottom: 3rem;
          margin-left: 4rem;
          cursor: pointer;
        }

        .searchPopup {
          position: absolute;
          z-index: 20;
          top: 74rem;
          display: none;
          height: 130rem;
          align-items: flex-end;
          padding: 0 30rem 60rem;
          border: 1px solid #dedede;
          box-shadow: 1px 3px 7px rgba(0, 0, 0, 0.2);
          background-color: rgba(255, 255, 255, 1);

          &.visible {
            display: flex;
          }

          &.nameSearch {
            width: 470rem;
            left: -30rem;
          }

          .close {
            position: absolute;
            right: 10rem;
            top: 10rem;
            width: 10rem;
            height: 10rem;
            padding: 0;
            border: 0;
            background: url(../../../assets/images/icon_closeGray.svg) center no-repeat;
            background-color: rgba(0, 0, 0, 0);
            background-size: contain;
            cursor: pointer;
          }

          > input[type='text'] {
            width: 100%;
            height: 39rem;
            padding-bottom: 8rem;
            border: none;
            border-bottom: 1rem solid var(--input-border-normal);
            background: rgba(255, 255, 255, 0);

            font-size: 24rem !important;
            font-weight: 400 !important;
            line-height: 28rem !important;
            color: rgba(0, 0, 0, 1);
            text-transform: none !important;

            &::placeholder {
              padding-top: 12rem;

              font-size: 14rem !important;
              line-height: 16rem !important;
              color: rgba(0, 0, 0, 0.5);
            }

            &:-webkit-autofill,
            &:-webkit-autofill:hover,
            &:-webkit-autofill:focus,
            &:-webkit-autofill:active {
              -webkit-box-shadow: 0 0 0 30rem white inset !important;
            }
          }
        }
      }

      td {
        position: relative;
        height: 75rem;
        font-size: 14rem;
        line-height: 16rem;
        color: rgba(0, 0, 0, 1);
        overflow-x: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;

        &:nth-of-type(3) {
          text-transform: lowercase;

          .noInterview {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 120rem;
            height: 30rem;
            background-color: rgba(239, 25, 58, 0.1);
          }
        }

        &:nth-of-type(8) {
          .zoom,
          .skype {
            display: flex;
            align-items: center;
            width: 190rem;
            height: 100%;
            padding-left: 40rem;
            padding-right: 10rem;

            span {
              min-width: 0;
              overflow-x: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
              line-height: 25rem;
            }

            &::before {
              content: '';
              position: absolute;
              left: 0;
              top: calc(50% - 12rem);
              width: 24rem;
              height: 24rem;
              background: url(../../../assets/images/icon_zoom.svg) center no-repeat;
              background-size: contain;
            }

            &.skype {
              &::before {
                background: url(../../../assets/images/icon_skype.svg) center no-repeat;
                background-size: contain;
              }
            }
          }
        }

        &:nth-of-type(9) {
          overflow: visible;

          .memberActionsContainer {
            position: relative;
            display: flex;
            align-items: center;
            font-size: 0;

            .memberActionsToggleButton {
              padding: 0;
              margin: 0;
              border: 0;
              width: 24rem;
              height: 24rem;
              background: url(../../../assets/images/icon_expertActions.svg) center no-repeat;
              background-size: 24rem 24rem;
              cursor: pointer;

              &.active {
                background: url(../../../assets/images/icon_expertActionsActive.svg) center no-repeat;
                background-size: 24rem 24rem;
              }

              &.disabled {
                background: url(../../../assets/images/icon_expertActionsDisabled.svg) center no-repeat;
                background-size: 24rem 24rem;
              }
            }

            .memberActions {
              position: absolute;
              z-index: 60;
              top: calc(100% + 5rem);
              right: 0;
              display: none;
              flex-direction: column;
              justify-content: flex-start;
              align-items: center;
              width: 290rem;
              height: auto;
              padding: 7rem 10rem;
              background: #ffffff;
              border: 1px solid #dedede;
              box-shadow: 1px 3px 7px rgba(0, 0, 0, 0.2);

              &.visible {
                display: flex;
              }

              button {
                display: flex;
                justify-content: flex-start;
                align-items: center;
                width: 100%;
                height: 39rem;
                padding: 0;
                padding-left: 10rem;
                margin: 0;
                border: 0;
                border-bottom: 1px solid rgba(0, 0, 0, 0.1);
                background-color: rgba(255, 255, 255, 0);
                cursor: pointer;

                &:last-of-type {
                  border-bottom: none;
                }
              }
            }
          }
        }
      }

      .memberRowBlocker {
        position: absolute;
        z-index: 50;
        left: 30rem;
        top: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        width: calc(140rem + 140rem + 210rem);
        padding-right: 90rem;
        height: 72rem;
        background-color: #fff;
        font-size: 18rem;
        line-height: 21rem;
        font-weight: 600;
        text-transform: uppercase;
      }
    }
  }
}
