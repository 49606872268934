.expertTelegramModal {
  padding-bottom: 35rem !important;
  width: 900rem !important;

  .title {
    margin-bottom: 30rem;
    padding: 0;
  }

  p {
    font-size: 18rem;
    line-height: 30rem;
    color: #000000;
    margin: 15rem 0;
  }

  a {
    font-size: 18rem;
  }
}
