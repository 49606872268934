.anketaAnswerRadio {
  form {
    display: grid;
    padding: 0 30rem;
    row-gap: 40rem;

    .radioInputContainer {
      display: grid;
      row-gap: 40rem;

      > label {
        display: grid;
        grid-template-columns: max-content auto;
        column-gap: 9rem;

        input[type='radio'] {
          width: 16rem;
          height: 16rem;
          margin: unset;
          margin-top: 1.5rem;
        }

        span {
          font-size: 18rem;
          line-height: 21rem;
          font-weight: 400;
        }
      }
    }

    > button {
      margin-top: 20rem;
      margin-bottom: 45rem;
      justify-self: flex-end;
    }
  }
}
