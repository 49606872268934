.compentesion {
  display: grid;
  grid-template-columns: 1300rem 50rem;
  column-gap: 30rem;

  > form {
    grid-column: 1;
    display: grid;
    row-gap: 60rem;
    width: 1300rem;
    padding: 20rem 25rem 35rem;
    border: 1px solid rgba($color: #000000, $alpha: 0.1);

    .competensionFieldsWrapper {
      display: grid;
      grid-template-columns: 900rem 265rem;
      column-gap: 80rem;
      row-gap: 20rem;
      align-items: flex-start;

      .subTitle {
        grid-column: span 2;
      }

      input[type='text'],
      .formikReactSelect {
        grid-column: span 1;
      }

      .quillEditor {
        grid-column: 1;
        margin-top: 0;
      }

      .inputError:last-child {
        grid-column: 1;
      }
    }

    .indicatorsFieldsWrapper {
      display: grid;
      grid-template-columns: max-content max-content;
      row-gap: 45rem;
      column-gap: 45rem;
      align-items: flex-start;

      // .indicator {
      //   grid-
      // }

      .subTitle {
        grid-column: span 2;
        margin-bottom: -20rem;
      }

      .indicator {
        position: relative;
        grid-column: span 1;
        display: grid;
        grid-template-columns: 500rem 50rem;
        column-gap: 15rem;
        width: 565rem;

        form,
        .indicatorWrapper {
          display: grid;
          row-gap: 12rem;

          .inputError.success {
            margin-top: 0;
          }
        }

        .addRemoveButtons {
          grid-column: span 1;
          align-content: flex-start;
          padding-top: 48rem;

          .whiteButton {
            border: 1px solid var(--button-blue-normal);
          }

          img {
            margin: 0;

            &.iconRemove {
              width: 25rem;
              height: 25rem;
            }

            &.iconAdd {
              width: 15rem;
              height: 15rem;
            }
          }

          > button:nth-of-type(2) {
            position: absolute;
            top: 48rem;
            right: -80rem;
          }
        }
      }
    }

    .reccomendationsFieldsWrapper {
      display: grid;
      row-gap: 42rem;

      .subTitle {
        margin-bottom: -20rem;
      }
    }

    button {
      justify-self: flex-start;
    }
  }

  .addRemoveButtons {
    grid-column: 2;
    display: grid;
    row-gap: 30rem;
    align-content: center;

    .addComptensionButton,
    .removeComptensionButton {
      width: 50rem;
      height: 50rem;

      &.addComptensionButton {
        background: url(../../../../assets/images/icon_plus.svg) center no-repeat;
        background-color: var(--button-blue-normal);

        &:hover {
          background-color: var(--button-blue-hover);
        }

        &:active {
          background-color: var(--button-blue-pressed);
        }

        &:disabled {
          background-color: var(--button-blue-disabled);
        }
      }

      &.removeComptensionButton {
        border: 1px solid var(--button-blue-normal);
        background-image: url(../../../../assets/images/icon_closeBlue.svg);
        background-size: 25rem 25rem;
        background-position: center;
        background-repeat: no-repeat;

        &:hover {
          background-color: #fff;
          border-color: var(--button-blue-hover);
        }

        &:active {
          background-color: #fff;
          border-color: var(--button-blue-active);
        }

        &:disabled {
          background-color: #fff;
          border-color: var(--button-blue-disabled);
        }
      }
    }
  }
}
