.assignNewInterviewModal {
  padding: 37.5rem !important;

  .title {
    width: 100%;
    margin-bottom: 35rem;

    font-size: 36rem;
    line-height: 36rem;
    text-align: left;
  }

  .anketasSelectLoader {
    display: flex;
    height: 60rem;
    align-items: center;

    .anketasSelectLoaderText {
      font-size: 24rem;
      line-height: 28rem;
      margin-right: 15rem;
      color: hsl(0, 0%, 20%);
    }
  }

  .existingInterviewAlert {
    margin-bottom: 35rem;
  }

  .radiobuttonsWrapper {
    display: grid;
    grid-template-columns: 150rem 150rem;

    .radiobuttonPlaceholder {
      grid-column: span 2;
      margin-bottom: 10rem;
      font-size: 14rem;
      line-height: 16rem;
      font-weight: 400;
      color: rgba($color: #000000, $alpha: 0.5);
    }

    .radiobutton {
      grid-column: span 1;
      align-self: flex-start;

      .radiobuttonVisibleLabel,
      .checkboxVisibleLabel {
        grid-template-columns: 16rem max-content;

        .radiobuttonText,
        .checkboxText {
          display: grid;
          .key {
            font-size: 16rem;
            line-height: 18rem;
            font-weight: 600;
          }
        }
      }
    }
  }

  .input {
    margin-top: 20rem;
  }

  button {
    margin: 15rem auto 5rem;
  }

  .inputSelect:nth-child(1) {
    position: relative;
    z-index: 50;
  }
  .inputSelect:nth-child(2) {
    position: relative;
    z-index: 40;
  }
  .inputSelect:nth-child(3) {
    position: relative;
    z-index: 30;
  }
}
