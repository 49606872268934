.expertAnketaAnswerSelect {
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  width: 100%;
  background-color: #fff;
  padding: 0 30rem 30rem;
  row-gap: 20rem;

  .answerSubTitle {
    grid-column: span 1;

    font-size: 14rem;
    line-height: 16rem;
    color: rgba(0, 0, 0, 0.5);
  }

  .answerColorsExpanation {
    grid-column: span 1;
    justify-self: flex-end;

    span {
      position: relative;
      padding-left: 20rem;

      font-size: 10rem;
      line-height: 12rem;
      color: rgba($color: #000000, $alpha: 0.5);

      &:nth-child(1) {
        margin-right: 30rem;
      }

      &:nth-child(1)::before,
      &:nth-child(2)::before {
        content: '';
        position: absolute;
        top: 3rem;
        left: 0;
        width: 10rem;
        height: 10rem;
        background-color: rgba($color: #00aeef, $alpha: 0.1);
      }

      &:nth-child(2)::before {
        background-color: rgba($color: #00aeef, $alpha: 1);
      }
    }
  }

  .answerVariants {
    grid-column: span 2;
    display: grid;
    row-gap: 5rem;
    padding-left: 20rem;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    padding-top: 10rem;

    .answerVariant {
      position: relative;
      padding: 15rem 30rem;

      font-size: 18rem;
      line-height: 22rem;
      color: #000;
      background-color: #fff;

      &.userSelected {
        background-color: rgba($color: #00aeef, $alpha: 0.1);
      }

      &.correct {
        &::before {
          content: '';
          position: absolute;
          left: -20rem;
          top: 0;
          width: 5rem;
          height: 100%;
          background-color: #00aeef;
        }
      }
    }
  }
}
