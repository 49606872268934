@import './Normalize.css';
@import './assets/fonts/opensans/opensans.css';
@import './App.css';

:root {
  --button-black-normal: rgba(37, 36, 36, 1);
  --button-black-hover: rgba(37, 36, 36, 0.7);
  --button-black-pressed: rgba(37, 36, 36, 0.9);
  --button-black-disabled: rgba(37, 36, 36, 0.3);

  --button-blue-normal: rgba(0, 174, 239, 1);
  --button-blue-hover: rgba(76, 191, 243, 1);
  --button-blue-pressed: rgba(0, 156, 215, 1);
  --button-blue-disabled: rgba(0, 174, 239, 0.5);

  --link-normal: rgba(0, 174, 239, 1);
  --link-hover: rgba(0, 156, 215, 1);
  --link-pressed: rgba(0, 0, 0, 1);
  --link-disabled: rgba(0, 0, 0, 0.5);
  --link-pale: rgba(255, 255, 255, 0.8);
  --link-error: rgba(239, 0, 74, 1);

  --input-border-normal: rgba(0, 174, 239, 1);
  --input-border-error: rgba(239, 0, 74, 1);
}

html * {
  max-height: 9999999999rem;
  text-size-adjust: none !important;
  -webkit-text-size-adjust: none !important;
  box-sizing: border-box;
  outline: none;
  font-family: 'Open Sans', sans-serif;
  word-wrap: break-word;
  // overflow-x: hidden;
}

html {
  font-size: calc(100vw / 1440);
  overflow-x: hidden;

  &.pdfPage {
    // font-size: calc(100vw / 1240) !important;

    header {
      display: none !important;
    }
  }
}

@media screen and (min-width: 1440px) {
  html {
    font-size: 1px;
  }
}

.app {
  min-width: 100vw;
  min-height: 100vh;
  background-color: #fff;
}

main {
  max-width: 1440px;
  margin: 0 auto;
}

header {
  .userHeader,
  .adminTopHeader,
  .adminBottomHeader {
    .contentContainer {
      max-width: 1440px;
      margin: 0 auto;
    }
  }
}

main.login,
main.registration,
main.notFound404,
main.error500,
main.emailConfirm,
main.passwordRecovery,
main.newPassword {
  background: url(./assets/images/illustration_bg.svg) center no-repeat;
  background-size: cover;
  max-width: unset;
  margin: unset;

  &.emailConfirm {
    width: 100%;
    height: 100vh;

    .modalWrapper {
      background-color: rgba(255, 255, 255, 0);
    }
  }
}

a {
  text-decoration: none;
}

.link {
  font-size: 14rem;
  line-height: 16rem;
  text-decoration: none;
  color: var(--link-normal);

  &.link-normal-style {
    color: var(--link-normal);
  }

  &.link-pale-style {
    color: var(--link-pale);
  }

  &:hover,
  &.link-hover-style {
    color: var(--link-hover);
  }

  &:active,
  &.link-pressed-style {
    color: var(--link-pressed);
  }

  &:disabled,
  &.link-disabled-style {
    color: var(--link-disabled);
  }

  &.link-error-style {
    color: var(--link-error);
  }
}

.formContainer {
  display: flex;
  align-items: center;
  width: 770rem;
  min-height: 100vh;
  background-color: rgba(255, 255, 255, 1);
  padding: 40rem 155rem;
  margin-left: auto;
}

form {
  display: grid;
  width: 100%;

  h1,
  .title {
    font-size: 36rem;
    line-height: 36rem;
    margin: 0 0 60rem 0;
  }

  > input[type='text'],
  > input[type='email'],
  > input[type='tel'],
  > input[type='password'],
  select {
    height: 39rem;
    padding-bottom: 8rem;
    border: none;
    border-bottom: 1rem solid var(--input-border-normal);
    background: rgba(255, 255, 255, 0);
    font-size: 24rem;
    line-height: 28rem;
    color: rgba(0, 0, 0, 1);

    &::placeholder {
      padding-top: 12rem;
      font-size: 14rem;
      line-height: 16rem;
      color: rgba(0, 0, 0, 0.5);
    }

    &.invalid {
      border-color: var(--input-border-error);
    }

    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus,
    &:-webkit-autofill:active {
      -webkit-box-shadow: 0 0 0 30rem white inset !important;
    }

    select {
      border-color: var(--input-border-normal);
      font-size: 14rem;
      line-height: 16rem;

      option {
        &.placeholder {
          font-size: 14rem;
          line-height: 16rem;
        }
      }
    }
  }

  .inputError {
    &.submitError {
      margin-top: 6rem;
      text-align: center;
    }

    &.minusMarginTop {
      margin-top: -19rem;
    }

    &.extraMarginBottom {
      margin-bottom: 16rem;
    }
  }

  button[type='submit'] {
    justify-self: center;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 330rem;
    height: 50rem;
    // margin-top: 46rem;
    background-color: var(--button-blue-normal);
    border: none;
    cursor: pointer;
    font-size: 14rem;
    font-weight: 600;
    line-height: 24rem;
    text-transform: uppercase;
    color: rgba(255, 255, 255, 1);

    &:hover {
      background-color: var(--button-blue-hover);
    }
  }

  .loginButtonLink {
    margin-top: 30rem;
  }

  .policyAgreementText {
    margin-top: 15rem;
    font-size: 14rem;
    line-height: 16rem;
    text-align: center;
  }
}

.login {
  form {
    display: grid;
    grid-template-columns: auto 125rem;

    > * {
      grid-column: span 2;
    }

    .inputError {
      &.password {
        grid-column: 1;
      }
    }

    .forgotPasswordLink {
      grid-column: 2;
      margin-top: 8rem;
      margin-bottom: 10rem;
      text-align: right;

      &:hover {
        color: var(--link-hover);
      }
    }
  }
}

.ReactQueryDevtoolsPanel {
  * {
    font-size: 15rem;
  }
}

button {
  padding: unset;
  margin: unset;
  border: unset;
}

.sortUpIcon,
.sortDownIcon {
  display: inline;
}
