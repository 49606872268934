.daysTabsTable {
  display: flex;
  justify-content: space-between;
  // grid-template-columns: repeat(7, 105rem);
  // column-gap: 90rem;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  // padding-left: 70rem;

  .alice-carousel {
    max-width: 1260rem;
  }

  .slideLeft {
    width: 85rem;
    padding: 10rem 30rem;
    border: 4px solid rgba(255, 255, 255, 1);
    color: rgba(0, 0, 0, 1);
    background: url(../../assets/images/icon_slideLeft.svg) center no-repeat;

    cursor: pointer;
  }
  .slideRight {
    width: 105rem;
    padding: 10rem 30rem;
    border: 4px solid rgba(255, 255, 255, 1);
    color: rgba(0, 0, 0, 1);
    background: url(../../assets/images/icon_slideRight.svg) center no-repeat;

    cursor: pointer;
  }

  .dayTab {
    width: 105rem;
    margin: 0 auto;
    border: 4px solid rgba(255, 255, 255, 1);
    color: rgba(0, 0, 0, 1);
    padding: 10rem 0rem 10rem 30rem;

    &.active,
    &:hover {
      border: 4px solid #00aeef;
      cursor: pointer;
    }

    &.disabled {
      color: rgba(0, 0, 0, 0.5);
    }

    .dayValue {
      font-size: 30rem;
      line-height: 35rem;
    }

    .dayName {
      font-size: 14rem;
      line-height: 16rem;
      padding-left: 2rem;
      text-transform: lowercase;
      color: rgba(0, 0, 0, 0.5);
    }
  }
}
