.anketaQuestionRateHelpModal {
  overflow: scroll;

  .title {
    margin-bottom: 60rem;
  }

  .questionDescriptionContainer {
    overflow-wrap: break-word;
    overflow-x: hidden;

    > * {
      &:last-child {
        margin-bottom: 0;

        li:last-child {
          margin-bottom: 0;
        }
      }
    }

    b {
      font-size: 24px;
      font-weight: 600;
      line-height: 38px;
    }

    p {
      font-size: 18rem;
      line-height: 30rem;
      color: #000000;
      margin: 15rem 0;

      &:first-of-type {
        margin-top: 0;
      }
    }

    ul {
      padding-left: 30rem;

      li {
        position: relative;
        margin: 15rem 0;
        font-size: 18rem;
        line-height: 30rem;
        list-style: none;
        color: rgba(0, 0, 0, 1);

        &:before {
          content: '';
          position: absolute;
          left: -30rem;
          top: 13rem;
          width: 8rem;
          height: 8rem;
          background-color: var(--button-blue-normal);
        }
      }
    }

    ol {
      li {
        margin: 15rem 0;
        list-style: none;
        font-size: 18rem;
        line-height: 30rem;
        color: rgba(0, 0, 0, 1);
      }
    }
  }
}
