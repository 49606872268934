.adminExpertsTable {
  display: grid;
  width: 100%;
  padding: 30rem 0 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);

  table {
    tr {
      position: relative;
      display: grid;
      grid-auto-columns: max-content;
      padding: 0 30rem;
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);

      th,
      td {
        grid-column: span 1;
        grid-row: 1;
        display: flex;
        align-items: center;
        height: 75rem;
        text-align: left;

        // * Имя эксперта
        &:nth-of-type(1) {
          width: 260rem;
        }

        // * Клиентов
        &:nth-of-type(2) {
          width: 70rem;
          justify-content: flex-end;

          font-weight: 400;
          color: rgba(0, 0, 0, 0.7);
        }

        // * Назначено интервью
        &:nth-of-type(3) {
          width: 180rem;
          justify-content: flex-end;

          font-weight: 400;
          color: rgba(0, 0, 0, 0.7);
        }

        // * Cвободно слотов
        &:nth-of-type(4) {
          width: 180rem;
          justify-content: flex-end;
        }

        // * Всего было интервью
        &:nth-of-type(5) {
          width: 180rem;
          justify-content: flex-end;
          font-weight: 400;
          color: rgba(0, 0, 0, 0.7);
        }

        // * Общая загруженность
        &:nth-of-type(6) {
          width: 310rem;
          padding-left: 40rem;
        }

        // * Расписание
        &:nth-of-type(7) {
          width: 165rem;
        }

        // * Кнопки
        &:nth-of-type(8) {
          width: 30rem;
        }
      }

      th {
        position: relative;
        font-size: 12rem;
        line-height: 14rem;
        font-weight: 600;
        text-transform: uppercase;
        user-select: none;

        &:nth-of-type(7) {
          font-size: 0;
        }

        &:nth-of-type(8) {
          font-size: 0;
        }

        .sortUpIcon,
        .sortDownIcon {
          width: 10rem;
          height: 4rem;
          margin-left: 10rem;
          margin-bottom: 2rem;

          &.sortDownIcon {
            transform: rotate(180deg);
          }
        }

        .searchIcon {
          width: 20rem;
          height: 20rem;
          padding: 4rem;
          margin-left: 8rem;
          cursor: pointer;
        }

        .resetFilterIcon {
          width: 20rem;
          height: 20rem;
          margin-bottom: 3rem;
          margin-left: 4rem;
          cursor: pointer;
        }

        .searchPopup {
          position: absolute;
          z-index: 20;
          top: 74rem;
          display: none;
          height: 130rem;
          align-items: flex-end;
          padding: 0 30rem 60rem;
          border: 1px solid #dedede;
          box-shadow: 1px 3px 7px rgba(0, 0, 0, 0.2);
          background-color: rgba(255, 255, 255, 1);

          &.visible {
            display: flex;
          }

          &.expertSearch {
            width: 470rem;
            left: 0rem;
          }

          .close {
            position: absolute;
            right: 10rem;
            top: 10rem;
            width: 10rem;
            height: 10rem;
            padding: 0;
            border: 0;
            background: url(../../../assets/images/icon_closeGray.svg) center no-repeat;
            background-color: rgba(0, 0, 0, 0);
            background-size: contain;
            cursor: pointer;
          }

          > input[type='text'] {
            width: 100%;
            height: 39rem;
            padding-bottom: 8rem;
            border: none;
            border-bottom: 1rem solid var(--input-border-normal);
            background: rgba(255, 255, 255, 0);

            font-size: 24rem !important;
            font-weight: 400 !important;
            line-height: 28rem !important;
            color: rgba(0, 0, 0, 1);
            text-transform: none !important;

            &::placeholder {
              padding-top: 12rem;

              font-size: 14rem !important;
              line-height: 16rem !important;
              color: rgba(0, 0, 0, 0.5);
            }

            &:-webkit-autofill,
            &:-webkit-autofill:hover,
            &:-webkit-autofill:focus,
            &:-webkit-autofill:active {
              -webkit-box-shadow: 0 0 0 30rem white inset !important;
            }
          }
        }
      }

      td {
        height: 75rem;
        font-size: 14rem;
        line-height: 16rem;
        color: rgba(0, 0, 0, 1);
        overflow-x: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;

        &:nth-of-type(4) {
          padding-right: 20rem;
        }

        &:nth-of-type(6) {
          .workloadContainer {
            position: relative;
            width: 200rem;
            height: 30rem;
            margin-right: 10rem;

            .workloadValue,
            .workloadText {
              position: absolute;
              left: 0;
              bottom: 0;
              display: flex;
              justify-content: flex-start;
              align-items: center;
              height: 100%;
            }

            .workloadText {
              padding-left: 17rem;
              font-size: 12rem;
              font-weight: 600;
              line-height: 14rem;
              color: #fff;
            }

            &.bigLoad {
              background-color: rgba(0, 174, 239, 0.1);

              .workloadValue {
                background-color: rgba(0, 174, 239, 1);
              }
            }

            &.mediumLoad {
              background-color: rgba(255, 175, 19, 0.1);

              .workloadValue {
                background-color: rgba(255, 175, 19, 1);
              }
            }

            &.smallLoad {
              background-color: rgba(239, 0, 74, 0.1);

              .workloadValue {
                background-color: rgba(239, 0, 74, 1);
              }
            }

            &.smallLoadFont {
              .workloadText {
                color: #000;
              }
            }
          }

          .workloadLeft {
            font-weight: 700;
          }
        }

        &:nth-of-type(7) {
          .link {
            padding: 0;
            margin: 0;
            border: 0;
            background: rgba($color: #fff, $alpha: 0);
            cursor: pointer;

            &.isPaddingLeft {
              padding-left: 30rem;
            }
          }
        }

        &:nth-of-type(8) {
          overflow: visible;
          .expertActionsContainer {
            position: relative;
            display: flex;
            align-items: center;
            font-size: 0;

            .expertActionsToggleButton {
              padding: 0;
              margin: 0;
              border: 0;
              width: 24rem;
              height: 24rem;
              background: url(../../../assets/images/icon_expertActions.svg) center no-repeat;
              background-size: 24rem 24rem;
              cursor: pointer;

              &.active {
                width: 24rem;
                height: 24rem;
                background: url(../../../assets/images/icon_expertActionsActive.svg) center no-repeat;
                background-size: 24rem 24rem;
              }
            }

            .expertActions {
              position: absolute;
              z-index: 60;
              top: calc(100% + 5rem);
              right: 0;
              display: none;
              flex-direction: column;
              justify-content: flex-start;
              align-items: center;
              width: 290rem;
              height: 172rem;
              padding: 7rem 10rem;
              background: #ffffff;
              border: 1px solid #dedede;
              box-shadow: 1px 3px 7px rgba(0, 0, 0, 0.2);

              &.visible {
                display: flex;
              }

              button {
                display: flex;
                justify-content: flex-start;
                align-items: center;
                width: 100%;
                height: 39rem;
                padding: 0;
                padding-left: 10rem;
                margin: 0;
                border: 0;
                border-bottom: 1px solid rgba(0, 0, 0, 0.1);
                background-color: rgba(255, 255, 255, 0);
                cursor: pointer;

                &:last-of-type {
                  border-bottom: none;
                }
              }
            }
          }
        }
      }

      .expertRowBlocker {
        position: absolute;
        z-index: 50;
        left: 265rem;
        top: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        width: calc(70rem + 180rem + 180rem + 180rem + 310rem);
        height: 72rem;
        padding-right: 45rem;
        background-color: #fff;
        font-size: 16rem;
        line-height: 18rem;
        font-weight: 600;
        text-transform: uppercase;
      }

      .usersRowBlocker {
        position: absolute;
        z-index: 50;
        left: 900rem;
        top: 0;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        width: 310rem;
        height: 72rem;
        padding-left: 40rem;
        background-color: #fff;
        font-size: 16rem;
        line-height: 18rem;
        font-weight: 600;
        text-transform: uppercase;
      }
    }
  }
}
