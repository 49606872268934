.adminClientMetric {
  grid-column: span 2;
  display: grid;
  grid-template-columns: 300rem auto;
  padding: 95rem 60rem 40rem 30rem;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);

  &:nth-of-type(2),
  &:nth-of-type(3) {
    padding-top: 40rem;
  }

  &:nth-of-type(2n) {
    border-right: 1px solid rgba(0, 0, 0, 0.1);
  }

  &:nth-last-child(-n + 2) {
    border-bottom: none;
  }

  .metricDescription {
    grid-column: 1;
    align-self: flex-start;
    display: grid;
    row-gap: 10rem;

    .clientName {
      font-size: 18rem;
      font-weight: 600;
      line-height: 22rem;
      margin-bottom: 30rem;
    }

    .clientLogoContainer {
      display: flex;
      align-items: center;
      width: 130rem;
      height: 70rem;
      margin-bottom: 60rem;

      .clientLogo {
        max-width: 130rem;
        max-height: 70rem;
        width: auto;
        height: auto;
      }
    }

    .descriptionTitle {
      margin-bottom: 5rem;

      font-size: 14rem;
      line-height: 16rem;
      color: rgba(0, 0, 0, 1);
    }

    .descriptionDataRow {
      display: grid;
      grid-template-columns: 16rem max-content max-content;
      column-gap: 10rem;
      max-width: 300rem;

      .rowBullet {
        width: 16rem;
        height: 16rem;
        border-radius: 50%;

        &.colorFive {
          background-color: rgba(37, 36, 36, 1);
        }
        &.colorFour {
          background-color: rgba(7, 94, 127, 1);
        }
        &.colorThree {
          background-color: rgba(0, 133, 183, 1);
        }
        &.colorTwo {
          background-color: rgba(0, 174, 239, 1);
        }
        &.colorOne {
          background-color: rgba(242, 242, 242, 1);
        }
      }

      .rowDesription {
        font-size: 14rem;
        line-height: 16rem;
        color: rgba(0, 0, 0, 0.5);
      }

      .rowValue {
        font-size: 14rem;
        line-height: 16rem;
      }
    }
  }

  .metricCirclesAndStatus {
    grid-column: 2;
    position: relative;
    width: 300rem;
    height: 360rem;

    .circleContainer {
      &:nth-of-type(1) {
        width: 300rem;
        height: 300rem;
        position: absolute;
        left: 0;
        top: 60rem;
      }

      &:nth-of-type(2) {
        width: 250rem;
        height: 250rem;
        position: absolute;
        left: 25rem;
        top: 85rem;
      }

      &:nth-of-type(3) {
        width: 200rem;
        height: 200rem;
        position: absolute;
        left: 50rem;
        top: 110rem;
      }

      &:nth-of-type(4) {
        width: 150rem;
        height: 150rem;
        position: absolute;
        left: 75rem;
        top: 135rem;
      }
    }

    .circleText {
      position: absolute;
      left: 110rem;
      top: 170rem;
      width: 80rem;
      height: 80rem;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .value {
        font-size: 24rem;
        line-height: 28rem;
        color: rgba(0, 0, 0, 1);
        text-align: center;
      }
      .text {
        font-size: 14rem;
        line-height: 16rem;
        color: rgba(0, 0, 0, 0.5);
      }
    }
  }

  .expertsWorkload {
    grid-column: span 2;
    display: grid;
    grid-template-columns: 460rem auto;
    margin-top: 60rem;
    padding-bottom: 40px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);

    .workloadTitle {
      grid-column: span 2;
      margin-bottom: 20rem;

      font-size: 14rem;
      font-weight: 600;
      line-height: 16rem;
    }

    .workloadContainer {
      grid-column: 1;
      position: relative;
      width: 460rem;
      height: 40rem;
      margin-right: 10rem;

      .workloadValue,
      .workloadText {
        position: absolute;
        left: 0;
        bottom: 0;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        height: 100%;
      }

      .workloadText {
        padding-left: 17rem;
        font-size: 12rem;
        font-weight: 600;
        line-height: 14rem;
        color: #fff;
      }

      &.bigLoad {
        background-color: rgba(0, 174, 239, 0.1);

        .workloadValue {
          background-color: rgba(0, 174, 239, 1);
        }
      }

      &.mediumLoad {
        background-color: rgba(255, 175, 19, 0.1);

        .workloadValue {
          background-color: rgba(255, 175, 19, 1);
        }
      }

      &.smallLoad {
        background-color: rgba(239, 0, 74, 0.1);

        .workloadValue {
          background-color: rgba(239, 0, 74, 1);
        }
      }

      &.smallLoadFont {
        .workloadText {
          color: #000;
        }
      }
    }

    .workloadSlotsLeft {
      grid-column: 2;
      display: grid;
      justify-content: flex-end;

      .value {
        font-size: 16rem;
        line-height: 19rem;
        font-weight: 600;
        text-align: center;
      }

      .key {
        font-size: 12rem;
        line-height: 14rem;
        color: rgba($color: #000000, $alpha: 0.5);
        text-align: center;
      }
    }
  }

  .deadline {
    grid-column: span 2;
    display: grid;
    grid-template-columns: 1fr 1fr;
    row-gap: 30rem;

    border-bottom: 0;
    padding-top: 40rem;
    padding-bottom: 0;

    .workloadTitle {
      grid-column: span 2;

      font-size: 14rem;
      font-weight: 600;
      line-height: 16rem;
    }

    .workloadLeft {
      font-size: 18rem;
      font-weight: 600;
      line-height: 22rem;
      text-align: center;
    }
  }
}
