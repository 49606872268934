.isLoading {
  visibility: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;

  img {
    width: 64rem;
    height: 64rem;
  }
}

.adminMemberReportHTML,
.adminTribeReportHTML,
.adminTribeReportPDF,
.adminClientReportHTML,
.adminClientReportPDF {
  width: 1440rem;
  max-width: 1440rem;
  margin: 0 auto;

  table {
    width: 100%;
    border-spacing: 0;

    td {
      padding: 0;
    }
  }

  thead {
    display: none;
  }

  .header {
    .topHeader {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      height: 110rem;
      padding: 0 40rem;

      img {
        width: auto;
        max-height: 100rem;
        max-width: 270rem;

        &:first-child {
          max-height: 45rem;
        }
      }
    }

    .bottomHeader {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 1440rem;
      height: 70rem;
      margin: 0 auto;
      padding: 0 40rem;
      background: #252424;

      .bottomHeaderSection {
        .key {
          font-size: 14rem;
          line-height: 16rem;
          color: rgba($color: #ffffff, $alpha: 0.5);
        }
        .value {
          margin-left: 10rem;
          font-size: 16rem;
          line-height: 19rem;
          color: rgba($color: #ffffff, $alpha: 1);
        }
      }
    }
  }
}

@page {
  position: relative;
  size: A4;
  margin: 0;
}

@media print {
  body {
    margin: 0;
  }

  thead {
    display: table-header-group !important;
    border: none;
    padding: 0;
    margin: 0;

    .page-header-space {
      height: 180rem;
    }
  }

  // .header {
  // position: fixed;
  // top: 0rem;
  // left: 10rem;
  // height: 180rem;
  // }

  .topHeader {
    position: fixed;
    top: 0rem;
    // left: 10rem;
    left: 0;
    height: 110rem;
  }

  .bottomHeader {
    position: fixed;
    top: 110rem;
    // left: 10rem;
    left: 0;
    height: 70rem;
  }

  .points {
    position: relative;

    // ! try do delete this and you will embrace what madness is
    .pointsHeader {
      position: absolute !important;
      top: 22.5rem !important;
      color: #fff !important;

      &:nth-child(1) {
        left: 30rem !important;
      }

      &:nth-child(2) {
        right: 30rem !important;
      }
    }

    .pointsValue {
      position: absolute !important;
      top: 40rem !important;
      color: #fff !important;

      &:nth-child(2) {
        left: 30rem !important;
      }

      &:nth-child(3) {
        left: 30rem !important;
      }

      &:nth-child(4) {
        right: 30rem !important;
      }
    }
  }

  .adminMemberReportHTML {
    .competentionsTable {
      .competention {
        page-break-inside: avoid;
      }
    }

    .competentionsIndicators {
      page-break-before: always;
      page-break-after: always;
      padding-top: 180rem;

      .competention {
        page-break-inside: avoid;
        padding-top: 90rem;
        padding-bottom: 0rem;
        grid-template-columns: auto 400rem;

        &:nth-child(1) {
          padding-top: 90rem;
        }
      }
    }

    .recomendationsGeneral {
      page-break-before: always;
      page-break-after: always;
      padding-top: 180rem;
    }

    .recomendationsForIndicators {
      padding-top: 180rem;

      .title {
        padding-top: 90rem;
        color: black !important;
      }
    }

    .recomendationsDetail {
      page-break-before: always;
      page-break-after: always;
      padding-top: 180rem;

      &.noTopPageBreak {
        page-break-before: avoid;
      }

      .title {
        padding-top: 90rem;
      }

      .recomendation {
        page-break-inside: avoid;
        padding-top: 90rem;
        padding-bottom: 40rem;
      }
    }
  }
}
