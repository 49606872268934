.templateStage3Form {
  .title {
    margin-bottom: 50rem;
  }
  .subTitle {
    margin-bottom: 30rem;
  }

  .submitButton {
    margin: 30rem 0;
  }
}
