.adminClientMetricsSimplified {
  display: grid;
  grid-template-columns: 250rem 1190rem;
  padding: 0;
  background-color: #fff;

  .title {
    position: relative;
    grid-column: 1;
    padding-left: 30rem;
    padding-bottom: 25rem;

    &:after {
      content: '';
      position: absolute;
      left: 0;
      bottom: -6rem;
      width: 100%;
      height: 6rem;
      background-color: var(--button-blue-normal);
    }
  }

  .subTitleLink {
    grid-column: span 1;
    justify-self: flex-end;
    padding-right: 30rem;
    padding-top: 10rem;

    font-size: 20rem;
    line-height: 36rem;
    color: var(--link-normal);
    text-decoration: none;
    text-align: right;

    &:hover {
      color: var(--link-hover);
    }
  }

  .metrics {
    grid-column: span 2;
    display: grid;
    grid-template-columns: 1fr 1fr;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    padding: 0rem 30rem 70rem 30rem;

    .subTitleLink {
      grid-column: span 2;
      padding: 15rem 0 30rem;
    }

    .metric {
      grid-column: span 1;
      display: grid;
      grid-template-columns: 300rem auto;

      .subTitle {
        grid-column: span 2;
        max-width: 300rem;
        padding-right: 30rem;

        font-size: 18rem;
        font-weight: 600;
        line-height: 32rem;
        color: rgba(0, 0, 0, 1);

        &.withMargin {
          margin-top: 80rem;
        }
      }

      .metricDescription {
        grid-column: 1;
        align-self: flex-end;
        display: grid;
        row-gap: 10rem;

        .descriptionTitle {
          margin-bottom: 5rem;

          font-size: 14rem;
          line-height: 16rem;
          color: rgba(0, 0, 0, 1);
        }

        .descriptionDataRow {
          display: grid;
          grid-template-columns: 16rem max-content max-content;
          column-gap: 10rem;
          max-width: 300rem;

          .rowBullet {
            width: 16rem;
            height: 16rem;
            border-radius: 50%;

            &.colorFive {
              background-color: rgba(37, 36, 36, 1);
            }
            &.colorFour {
              background-color: rgba(7, 94, 127, 1);
            }
            &.colorThree {
              background-color: rgba(0, 133, 183, 1);
            }
            &.colorTwo {
              background-color: rgba(0, 174, 239, 1);
            }
            &.colorOne {
              background-color: rgba(242, 242, 242, 1);
            }
          }

          .rowDesription {
            font-size: 14rem;
            line-height: 16rem;
            color: rgba(0, 0, 0, 0.5);
          }

          .rowValue {
            font-size: 14rem;
            line-height: 16rem;
          }
        }
      }

      .metricCircles {
        grid-column: 2;
        position: relative;
        width: 300rem;
        height: 300rem;

        .circleContainer {
          &:nth-of-type(1) {
            width: 300rem;
            height: 300rem;
            position: absolute;
            left: 0;
            top: 0;
          }

          &:nth-of-type(2) {
            width: 250rem;
            height: 250rem;
            position: absolute;
            left: 25rem;
            top: 25rem;
          }

          &:nth-of-type(3) {
            width: 200rem;
            height: 200rem;
            position: absolute;
            left: 50rem;
            top: 50rem;
          }

          &:nth-of-type(4) {
            width: 150rem;
            height: 150rem;
            position: absolute;
            left: 75rem;
            top: 75rem;
          }
        }

        .circleText {
          position: absolute;
          left: 110rem;
          top: 110rem;
          width: 80rem;
          height: 80rem;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;

          .value {
            font-size: 24rem;
            line-height: 28rem;
            color: rgba(0, 0, 0, 1);
            text-align: center;
          }
          .text {
            font-size: 14rem;
            line-height: 16rem;
            color: rgba(0, 0, 0, 0.5);
          }
        }
      }
    }
  }
}
