.pageLoader {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1000;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  background-color: #fff;

  img {
    width: 64rem;
    height: 64rem;
  }
}
