.input {
  position: relative;
  width: 100%;
  padding-top: 20rem;

  &.partlyVisible {
    padding-top: 0;
  }

  .placeholder {
    position: absolute;
    z-index: 5;
    top: 31rem;
    width: 100%;

    font-size: 14rem;
    line-height: 16rem;
    color: rgba(0, 0, 0, 0.5);
  }

  input[type='text'],
  input[type='password'] {
    position: relative;
    z-index: 10;
    width: 100%;
    height: 39rem;
    padding-right: 0;
    padding-bottom: 8rem;
    padding-left: 0;
    border: none;
    border-bottom: 1rem solid var(--input-border-normal);
    background: rgba(255, 255, 255, 0);

    font-size: 24rem;
    line-height: 28rem;
    color: rgba(0, 0, 0, 1);

    &.invalid {
      border-color: var(--input-border-error);
    }

    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus,
    &:-webkit-autofill:active {
      -webkit-box-shadow: 0 0 0 30rem white inset !important;
    }

    &::placeholder {
      font-size: 14rem;
      line-height: 16rem;
      color: rgba(0, 0, 0, 0.5);
    }

    &:disabled {
      cursor: not-allowed;
      opacity: 0.5;
    }
  }

  .inputError {
    margin-bottom: 0 !important;
  }

  &.small {
    .placeholder {
      top: 22rem;
    }

    input[type='text'],
    input[type='password'] {
      height: 29rem;
      font-size: 16rem;
      line-height: 18rem;
      padding-bottom: 8px;
    }
  }
}
