.changeSkypeModal {
  width: 800rem !important;

  .title {
    margin-bottom: 70rem;
  }

  input[type='text'] {
    width: 600rem;
  }

  button {
    margin-top: 80rem;
  }

  .inputError {
    &.success {
      text-align: center;
    }
  }
}
