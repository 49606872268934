.changeExpertModal {
  display: grid;
  width: 930rem !important;
  height: 525rem !important;
  grid-template-columns: 300rem 300rem auto;
  column-gap: 120rem;
  justify-content: flex-start;

  .title {
    width: 100%;
    grid-row: 1;
    grid-column: span 3;
    margin-bottom: 85rem;

    font-size: 36rem;
    text-align: left;
    line-height: normal;
    padding-bottom: 0;
  }

  .reactSelect {
    &:nth-child(3) {
      grid-row: 2;
      grid-column: span 2;
      width: 720rem;
      margin-bottom: 60rem;
    }

    &:nth-child(4) {
      grid-row: 3;
      grid-column: 1;
      width: 300rem;
      margin-bottom: 55rem;
    }

    &:nth-child(5) {
      grid-row: 3;
      grid-column: 2;
      width: 300rem;
      margin-bottom: 55rem;
    }
  }

  button {
    grid-row: 4;
    grid-column: span 3;
    justify-self: center;
    margin-right: 110rem;
  }

  .inputError {
    grid-row: 5;
    grid-column: span 3;
    justify-self: center;
    text-align: center;
    margin-right: 110rem;
  }
}
